import { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import UserService from "../../../services/UserService";
import UserItemCard from "./UserItemCard";
import { IUserResponse } from "../../../types/User";

const Users = () => {
  const [customerList, setCustomerList] = useState<IUserResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const adminRequest: any = "?types=ADMIN&page=0&size=1000";
      const warehouseManagerRequest: any = "?types=WAREHOUSE_MANAGER&page=0&size=1000";
      const [adminResponse, warehouseManagerResponse] = await Promise.all([
        UserService.getAllUser(adminRequest),
        UserService.getAllUser(warehouseManagerRequest),
      ]);

      const adminData = adminResponse.data.data || [];
      const warehouseData = warehouseManagerResponse.data.data || [];
      setCustomerList([...adminData, ...warehouseData]);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Card loading={loading} className="bg-cloud border-radius-md">
          <Row gutter={[24, 24]}>
            {customerList.map((user) => (
              <Col span={24} key={user.id}>
                <UserItemCard user={user} />
              </Col>
            ))}
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Users;
