import http from "../http-common";
import {
  IBoxSizesRequest,
  IPaginatedBoxSizesResponse,
  IBoxSizeResponse,
} from "../types/BoxSize";

const getBoxSizes = (filterCriteria: IBoxSizesRequest) => {
  return http.get<IPaginatedBoxSizesResponse>(
    `/api/v2/box-sizes${filterCriteria}`
  );
};

const getBoxSizesById = (id: number) => {
  return http.get<IBoxSizeResponse>(`/api/v2/box-sizes/${id}`);
};

const BoxSizeService = {
  getBoxSizes,
  getBoxSizesById,
};

export default BoxSizeService;
