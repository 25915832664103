import http from "../http-common";
import { INotesCreateRequest, INotesResponse, INotesUpdateRequest } from "../types/Notes";

const createFbaOrderNote = (id: number, requestBody: INotesCreateRequest) => {
  return http.post<INotesResponse>(`/api/v2/fba-orders/${id}/notes`, requestBody);
};

const updateFbaOrderNote = (id: number, noteId: number, requestBody: INotesUpdateRequest) => {
  return http.put<INotesResponse>(`/api/v2/fba-orders/${id}/notes/${noteId}`, requestBody);
};

const deleteFbaOrderNote = (id: number, noteId: number) => {
  return http.delete<void>(`/api/v2/fba-orders/${id}/notes/${noteId}`);
};

const FbaOrdersNotesService = {
  createFbaOrderNote,
  updateFbaOrderNote,
  deleteFbaOrderNote,
};

export default FbaOrdersNotesService;
