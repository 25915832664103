import { IBoxResponse } from "./Box";
import { IUserResponse } from "./User";

interface UserBoxResponse {
  id?: number;
  user: IUserResponse;
  box: IBoxResponse;
  status: "ACTIVE" | "RELEASED";
  assignedDate: string;
  releasedDate: string;
  lastChargedDate: string;
  fillRate: number;
}

interface UserBoxesRequest {
  status?: "ACTIVE" | "RELEASED";
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

interface UserBoxesCheckResponse {
  needsNewBoxes: boolean;
  requiredBoxCount: number;
  itemsNeedingNewBox: number;
  totalCost: number;
  itemsPlaceableInExistingBoxes: number;
}

interface UserBoxesCheckStorageResponse {
  used: {
    count: number;
    totalFillRate: number;
  };
  newBox: {
    count: number;
    totalFillRate: number;
  };
}

interface UserBoxesCheckRequest {
  asin: string;
  quantity: number;
}

interface UserBoxesPurchaseRequest {
  quantity: number;
}

interface UserBoxesCheckStorageRequest {
  asin: string;
  quantity: number;
}

export class IUserBoxResponse implements UserBoxResponse {
  id?: number;
  user: IUserResponse;
  box: IBoxResponse;
  status: "ACTIVE" | "RELEASED";
  assignedDate: string;
  releasedDate: string;
  lastChargedDate: string;
  fillRate: number;
  constructor(obj: UserBoxResponse) {
    this.id = obj.id;
    this.user = obj.user;
    this.box = obj.box;
    this.status = obj.status;
    this.assignedDate = obj.assignedDate;
    this.releasedDate = obj.releasedDate;
    this.lastChargedDate = obj.lastChargedDate;
    this.fillRate = obj.fillRate;
  }
}

export class IUserBoxesRequest implements UserBoxesRequest {
  status?: "ACTIVE" | "RELEASED";
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
  constructor(obj: UserBoxesRequest) {
    this.status = obj.status;
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search;
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

export class IUserBoxesCheckResponse implements UserBoxesCheckResponse {
  needsNewBoxes: boolean;
  requiredBoxCount: number;
  itemsNeedingNewBox: number;
  totalCost: number;
  itemsPlaceableInExistingBoxes: number;
  constructor(obj: UserBoxesCheckResponse) {
    this.needsNewBoxes = obj.needsNewBoxes;
    this.requiredBoxCount = obj.requiredBoxCount;
    this.itemsNeedingNewBox = obj.itemsNeedingNewBox;
    this.totalCost = obj.totalCost;
    this.itemsPlaceableInExistingBoxes = obj.itemsPlaceableInExistingBoxes;
  }
}

export class IUserBoxesCheckStorageResponse
  implements UserBoxesCheckStorageResponse
{
  used: {
    count: number;
    totalFillRate: number;
  };
  newBox: {
    count: number;
    totalFillRate: number;
  };

  constructor(obj: UserBoxesCheckStorageResponse) {
    this.used = {
      count: obj.used.count,
      totalFillRate: obj.used.totalFillRate,
    };
    this.newBox = {
      count: obj.newBox.count,
      totalFillRate: obj.newBox.totalFillRate,
    };
  }
}

export class IUserBoxesCheckRequest implements UserBoxesCheckRequest {
  asin: string;
  quantity: number;
  constructor(obj: UserBoxesCheckRequest) {
    this.asin = obj.asin;
    this.quantity = obj.quantity;
  }
}

export class IUserBoxesPurchaseRequest implements UserBoxesPurchaseRequest {
  quantity: number;
  constructor(obj: UserBoxesPurchaseRequest) {
    this.quantity = obj.quantity;
  }
}

export class IUserBoxesCheckStorageRequest
  implements UserBoxesCheckStorageRequest
{
  asin: string;
  quantity: number;
  constructor(obj: UserBoxesCheckStorageRequest) {
    this.asin = obj.asin;
    this.quantity = obj.quantity;
  }
}

interface PaginatedUserBoxesResponse {
  data: IUserBoxResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedUserBoxesResponse implements PaginatedUserBoxesResponse {
  data: IUserBoxResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedUserBoxesResponse) {
    this.data = obj.data.map((userBox) => new IUserBoxResponse(userBox));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
