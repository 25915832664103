import { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import RoleService from "../../../services/RoleService";
import { IRoleResponseData } from "../../../types/Role";
import RoleItemCard from "./RoleItemCard";

const Roles = () => {
  const [roles, setRoles] = useState<IRoleResponseData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line
  }, []);

  const getRoles = () => {
    setLoading(true);
    RoleService.getAll()
      .then((response: any) => {
        let data = response.data;
        setRoles(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card className="bg-cloud border-radius-md" loading={loading}>
            <Row gutter={[24, 24]}>
              {roles.map((role: any) => (
                <Col span={24}>
                  <RoleItemCard getRoles={getRoles} role={role} />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Roles;
