import React, { useState } from "react";
import { Button, Card, Col, Divider, Row } from "antd";
import { ReactComponent as WarehouseIcon } from "../../../../assets/V2/OrderDetailV2/warehouse-icon.svg";
import { ReactComponent as HouseCheckIcon } from "../../../../assets/V2/OrderDetailV2/house-check.svg";
import OrderDetailPackageInfo from "./OrderDetailPackageInfo";
import OrderDetailLabels from "./OrderDetailLabels";
import ShipmentCostDetail from "../../Common/ShipmentCostDetail/ShipmentCostDetail";
import FBAShipmentItemCard from "./FBAShipmentItemCard";
import { CopyClipboard } from "../../../Common/CopyClipboard/CopyClipboard";
import { IFbaOrderResponse } from "../../../../types/FbaOrder";
import AddNoteModal from "../../../Common/Note/AddNoteModal";

interface OrderDetailCardProps {
  order: IFbaOrderResponse;
}

const FBAShipmentDetail: React.FC<OrderDetailCardProps> = ({ order }) => {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false);
  const [notes, setNotes] = useState(order?.notes || []);

  const showNoteModal = () => {
    setIsNoteModalVisible(true);
  };

  const handleNoteModalClose = () => {
    setIsNoteModalVisible(false);
  };

  const handleNoteAdded = (response: any) => {
    const newNoteObject = {
      notes: response.notes,
      user: response.user,
      createdAt: new Date().toISOString(),
    };

    const updatedNotes = [...notes, newNoteObject];
    setNotes(updatedNotes);
  };

  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3">Shipment Details</h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="bg-slate-blue border-radius-md">
              <Row>
                <Col span={4}>
                  <WarehouseIcon style={{ height: "48px", width: "48px" }} />
                </Col>
                <Col span={20}>
                  <p className="text font-color-pure-white">
                    <b>Sent to Amazon Warehouse</b>
                  </p>
                  <p className="pretitle font-color-pure-white">
                    {order?.warehouse?.countryCode}
                  </p>
                  <p className="pretitle font-color-pure-white">
                    {order?.warehouse?.address}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={24} className="mt-24">
          <Col span={24}>
            <Card className="bg-cloud border-radius-md">
              <Row>
                <Col span={4}>
                  <HouseCheckIcon className="wh-48" />
                </Col>
                <Col span={20}>
                  <p className="text font-color-onyx">
                    <b>OneAmz Warehouse Address</b>
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={"356 GETTY AVE"} />
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={`BLD-5A #${order?.orderCode}`} />
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={"CLIFTON, NJ 07011-2118"} />
                  </p>
                  <p className="text font-color-onyx m-0">
                    <CopyClipboard text={"United States"} />
                  </p>
                  <p className="text font-color-onyx mb-0">
                    <b>Phone:</b>
                  </p>
                  <p className="text font-color-onyx mt-0">
                    <CopyClipboard text={"917 306 6033"} />
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <OrderDetailLabels order={order} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={24}>
                  <Row justify={"space-between"} align={"middle"}>
                    <h3 className="text font-color-onyx mt-0">
                      <b>Note</b>
                    </h3>
                    <Button
                      className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
                      onClick={showNoteModal}
                    >
                      Add Note
                    </Button>
                    <AddNoteModal
                      orderId={order?.id}
                      visible={isNoteModalVisible}
                      onClose={handleNoteModalClose}
                      onNoteAdded={handleNoteAdded}
                    />
                  </Row>
                  <Row
                    justify={"space-between"}
                    align={"middle"}
                    className="p-16"
                  >
                    <Row style={{ width: "100%", padding: "0px 0" }}>
                      <Col span={12}>
                        <p className="text font-color-onyx m-0">
                          <strong>Note</strong>
                        </p>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <p className="text font-color-onyx m-0">
                          <strong>Added By</strong>
                        </p>
                      </Col>
                    </Row>
                  </Row>
                  <Divider style={{ margin: "0px 0" }} />
                  <Row
                    justify={"space-between"}
                    align={"middle"}
                    className="mt-16"
                  >
                    {notes.map((note, index) => (
                      <Row
                        key={index}
                        style={{ width: "100%", padding: "8px 0" }}
                      >
                        <Col span={12}>
                          <p className="text font-color-onyx m-0">
                            {note.notes}
                          </p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <p className="text font-color-onyx m-0">
                            {note.user?.firstName} {note.user?.lastName}
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">Package Details</h3>
          </Col>
          <Col span={24}>
            {order?.items.map((item: any, index: any) => (
              <FBAShipmentItemCard
                item={item}
                key={index}
                order={order}
                orderItem={item}
              />
            ))}
          </Col>
          <Col span={24}>
            <OrderDetailPackageInfo order={order} />
          </Col>
          <Col span={24}>
            <ShipmentCostDetail order={order} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FBAShipmentDetail;
