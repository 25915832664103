import { Col, Row } from "antd";
import React from "react";

interface ReceiverProps {
  receiver: any;
}

const Receiver: React.FC<ReceiverProps> = ({ receiver }) => {
  return (
    <>
      <Row>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>Name Surname</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerName} </b>
          </h3>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>Country Code</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerCountryCode} </b>
          </h3>
        </Col>
        <Col md={6} sm={24} xs={24}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>Address 1 - 2</b>
          </p>
          <p className="mt-0 mb-0 h-4">
            <b>{receiver?.customerAddress1} </b>
          </p>
          <p className="mt-0 h-4">
            <b>{receiver?.customerAddress2} </b>
          </p>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>State</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerState} </b>
          </h3>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>City</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerCity} </b>
          </h3>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>Postal Code</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerZipCode} </b>
          </h3>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>Phone</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerPhone} </b>
          </h3>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <p className="text-small font-color-slate-blue-06 m-0">
            <b>Email</b>
          </p>
          <h3 className="mt-0">
            <b>{receiver?.customerEmail} </b>
          </h3>
        </Col>
      </Row>
    </>
  );
};

export default Receiver;
