import React from "react";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import enUS from "antd/lib/locale/en_US";
// import trTR from "antd/lib/locale/tr_TR";
import "./assets/style/main.scss";
import "./App.css";

import RegisterPage from "./pages/Register/RegisterPage";
import LoginPage from "./pages/Login/LoginPage";
import EmailVerificationCallback from "./pages/Auth/EmailVerificationCallback";
import EmailVerification from "./pages/Auth/EmailVerification";
import NewPassword from "./pages/Auth/NewPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import VerifyAgain from "./pages/Auth/VerifyAgain";
import VerifyExternalToken from "./pages/Auth/VerifyExternalToken";
import MainLayout from "./components/Common/Layout/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";
import StoragePage from "./pages/Inbound/StoragePage";
import FBAPage from "./pages/Outbound/FBA/FBAPage";
import FBMPage from "./pages/Outbound/FBM/FBMPage";
import InventoryPage from "./pages/Inventory/InventoryPage";
import BoxPage from "./pages/Box/BoxPage";
import WalletPage from "./pages/Wallet/WalletPage";
import EducationPage from "./pages/Education/EducationPage";
import CustomersPage from "./pages/Customers/CustomersPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import SolutionCenterPage from "./pages/SolutionCenter/SolutionCenterPage";
import SettingsPage from "./pages/Settings/SettingsPage";
import AdditionalServices from "./components/Settings/AdditionalServices/AdditonalServices";
import AmazonFBAWarehouses from "./components/Settings/AmazonFBAWarehouses/AmazonFBAWarehouses";
import Boxes from "./components/Settings/Boxes/Boxes";
import WarehouseManagement from "./components/Settings/WarehouseManagement/WarehouseManagement";
import Users from "./components/Settings/Users/Users";
import Roles from "./components/Settings/Roles/Roles";
import OrderDetailPage from "./pages/OrderDetail/OrderDetailPage";
import FBMCreate from "./components/Outbound/FBM/FBMCreate/FBMCreate";
import FBACreate from "./components/Outbound/FBA/FBACreate/FBACreate";
import StorageCreate from "./components/Inbound/Storage/StorageCreate/StorageCreate";

const NotFound = () => <div>404 Page Not Found</div>;

const App: React.FC = () => {
  // const savedLanguage = localStorage.getItem("appLanguage");
  // const initialLocale = savedLanguage === "en" ? enUS : trTR;
  const initialLocale = enUS;
  // eslint-disable-next-line
  const [locale, setLocale] = useState(initialLocale);
  // const currentRole = localStorage.getItem("userRole");

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={locale}>
        <Router>
          <Routes>
            <Route
              key="Main"
              element={<MainLayout><Outlet /></MainLayout>} >
              <Route path="/" element={<Dashboard />} />

              <Route path="/fba" element={<FBAPage />} />
              <Route path="/fba/new" element={<FBACreate />} />
              <Route path={`/fba-order/detail/:id`} element={<OrderDetailPage />} />

              <Route path="/fbm" element={<FBMPage />} />
              <Route path="/fbm/new" element={<FBMCreate />} />
              <Route path={`/fbm-order/detail/:id`} element={<OrderDetailPage />} />

              <Route path="/storage" element={<StoragePage />} />
              <Route path="/storage/new" element={<StorageCreate />} />
              <Route path={`/storage-order/detail/:id`} element={<OrderDetailPage />} />

              <Route path="/inventory" element={<InventoryPage />} />
              <Route path="/box" element={<BoxPage />} />

              {/* {currentRole === "CUSTOMER" && (
                <> */}
                  <Route path="/wallet" element={<WalletPage />} />
                  <Route path="/education" element={<EducationPage />} />
                  <Route path="/amazon-fba-warehouses" element={<AmazonFBAWarehouses />} />
                {/* </>
              )} */}

              {/* {currentRole !== "CUSTOMER" && (                 */}
                 <Route path="/customers" element={<CustomersPage />} />                
              {/* )}    */}

              {/* {currentRole === "ADMIN" && ( */}
                <Route path="/settings" element={<SettingsPage />}>
                  <Route path="additional-services" element={<AdditionalServices />} />
                  <Route path="amazon-fba-warehouses" element={<AmazonFBAWarehouses />} />
                  <Route path="boxes" element={<Boxes />} />
                  <Route path="warehouse-management" element={<WarehouseManagement />} />
                  <Route path="users" element={<Users />} />
                  <Route path="roles" element={<Roles />} />
                </Route>              
              {/* )}           */}
              
              <Route path="/tickets" element={<SolutionCenterPage />} />
              <Route path="/profile" element={<ProfilePage />} />
            </Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/verify-external-token" element={<VerifyExternalToken />} />
            <Route path="/email-verification-callback" element={<EmailVerificationCallback />} />
            <Route path="/verify" element={<EmailVerification />} />
            <Route path="/reset-password" element={<NewPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-email" element={<VerifyAgain />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default App;
