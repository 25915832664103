import http from "../http-common";
import { IFbaOrderItemCreateRequest, IFbaOrderItemUpdateRequest, IFbaOrderItemResponse } from "../types/FbaOrderItem";

const createFbaOrderItem = (id: number, requestBody: IFbaOrderItemCreateRequest) => {
  return http.post<IFbaOrderItemResponse>(`/api/v2/fba-orders/${id}/items`, requestBody);
};


const updateFbaOrderItem = (id: number, itemId: number, requestBody: IFbaOrderItemUpdateRequest) => {
  return http.put<IFbaOrderItemResponse>(`/api/v2/fba-orders/${id}/items/${itemId}`, requestBody);
};

const deleteFbaOrderItem = (id: number, itemId: number) => {
  return http.delete<void>(`/api/v2/fba-orders/${id}/items/${itemId}`);
};

const downloadFnskLabel = (id: number, itemId: number) => {
  return http.get(`/api/v2/fba-orders/${id}/items/${itemId}/fnsk-label/download`, { responseType: "blob" });
};

const uploadFnskLabel = (id: number, itemId: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return http.put<IFbaOrderItemResponse>(
    `/api/v2/fba-orders/${id}/items/${itemId}/fnsk-label/upload`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

const FbaOrderItemService = {
  createFbaOrderItem,
  updateFbaOrderItem,
  deleteFbaOrderItem,
  downloadFnskLabel,
  uploadFnskLabel
};

export default FbaOrderItemService;
