import { Button, Card, Col, Row, Image, Modal, Alert } from "antd";
import { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./FBACreate.scss";
import { openNotification } from "../../../../utils";
import StarterCard from "../../../Common/StarterCard/StarterCard";
import FBACreateStep1 from "./FBACreateStep1";
import FBACreateStep2 from "./FBACreateStep2";
import FBACreateStep3 from "./FBACreateStep3";
import FBACreateStep4 from "./FBACreateStep4";
import FBAResultItemCard from "../../Common/ResultItemCard/ResultItemCard";
import ProgressCard from "./ProgressCard";
import WarehouseInfoCard from "../../../Common/WarehouseInfoCard/WarehouseInfoCard";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning-icon.svg";
import FbaImg from "../../../../assets/Dashboard/fba-quickaccess.png";
import { ReactComponent as InfoIcon } from "../../../../assets/info-icon.svg";
import OneamzAppService from "../../../../services/OneamzAppService";
import AdditionalServiceService from "../../../../services/AdditionalServiceService";
import FbaOrderService from "../../../../services/FbaOrderService";
import { IFbaOrderResponse } from "../../../../types/FbaOrder";
import WarehousesService from "../../../../services/WarehousesService";
import FbaOrderItemService from "../../../../services/FbaOrderItemService";

function FBACreate() {
  const [current, setCurrent] = useState(0);
  const [warehouse, setWarehouse] = useState<any>(null);
  const [country, setCountry] = useState<any>(null);
  const [totalQty, setTotalQty] = useState<any>(0);
  const [totalWeight, setTotalWeight] = useState<any>(0);
  const [totalDeci, setCurrentDeci] = useState<any>(0);
  const [totalFBACost, setTotalFBACost] = useState<any>(0);
  const [totalFulfillmentCost, setTotalFulfillmentCost] = useState<any>(0);
  // eslint-disable-next-line
  const [totalServiceCost, setTotalServiceCost] = useState<any>(0);
  // eslint-disable-next-line
  const [FBAPrepServiceFee, setFBAPrepServiceFee] = useState<any>(0);
  const [totalCustomServiceCost, setTotalCustomServiceCost] = useState<any>(0);
  const [totalFulfillmentCostPerUnit, setTotalFulfillmentCostPerUnit] =
    useState<any>(0);
  const [productList, setProductList] = useState<any>([]);
  const [fbaServices, setFbaServices] = useState<any>([]);
  const [packageCount, setPackageCount] = useState<any>(0);
  const [loadBalance, setLoadBalance] = useState<any>(0);
  const [fbaLabel, setFBALabel] = useState<any>(null);
  const [cargoLabel, setCargoLabel] = useState<any>(null);
  const [warehouseList, setWarehouseList] = useState<any>([]);
  const [orderCreateLoading, setOrderCreateLoading] = useState(false);
  const [shipmentCompany, setShipmentCompany] = useState("AMAZON");
  const [shipmentFee, setShipmentFee] = useState(0);
  // eslint-disable-next-line
  const [currentBalance, setCurrentBalance] = useState(0);
  const [fbaCreated, setFbaCreated] = useState<any>(false);
  const [fbaNo, setFbaNo] = useState<any>(null);
  const [externalUserId, setExternalUserId] = useState<any>(null);
  const [shipmentCode, setShipmentCode] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [labelModal, setLabelModal] = useState(false);
  const [fnskuWarning, setFnskuWarning] = useState(false);
  const [note, setNote] = useState<any>(null);
  const [orderItemServices, setOrderItemServices] = useState<any>([]);
  const [orderServices, setOrderServices] = useState<any>([]);
  const [order, setOrder] = useState<any>();

  const id = localStorage.getItem("id");
  const navigate = useNavigate();
  const externalToken = localStorage.getItem("externalAccessToken");
  const externalRefreshToken = localStorage.getItem("externalRefreshToken");

  const setFBAShipment = async (FBAShipment: any, account: any) => {
    // eslint-disable-next-line
    warehouseList.map((item: any) => {
      if (item.code === FBAShipment?.destinationFulfillmentCenterId) {
        setCountry(item.country);
        setWarehouse(item.value);
      }
    });

    getAmazonFbaLabelByShipmentId(account, FBAShipment.shipmentId);
  };

  const getAmazonFbaLabelByShipmentId = (
    userAccountId: number,
    shipmentId: string
  ) => {
    OneamzAppService.getAmazonFbaLabelByShipmentId(userAccountId, shipmentId)
      .then(async (response: any) => {
        const downloadURL = response.data.payload.downloadURL;
        uploadTemp(downloadURL, shipmentId);
      })
      .catch((e: Error) => {});
  };

  const uploadTemp = (amazonFBALabelPath: string, shipmentId: string) => {
    let data = new FormData();
    data.append("fileUrl", amazonFBALabelPath);

    let config = {
      method: "post",
      url: "https://cdn-oneamz.dact.studio/fba-label/index.php",
      data: data,
      responseType: "blob" as const,
    };

    axios
      .request(config)
      .then(async (response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const fileName = `${shipmentId}.pdf`;
        const pdfFile = new File([pdfBlob], fileName, {
          type: "application/pdf",
        });
        setFBALabel(pdfFile);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const colorsArray = [
    "rgba(255, 0, 0,",
    "rgba(0, 255, 0,",
    "rgba(0, 0, 255,",
    "rgba(255, 255, 0,",
    "rgba(255, 0, 255,",
    "rgba(0, 255, 255,",
    "rgba(128, 0, 0,",
    "rgba(0, 128, 0,",
    "rgba(0, 0, 128,",
    "rgba(128, 128, 0,",
    "rgba(128, 0, 128,",
    "rgba(0, 128, 128,",
    "rgba(255, 128, 0,",
    "rgba(255, 0, 128,",
    "rgba(128, 255, 0,",
    "rgba(0, 255, 128,",
    "rgba(128, 0, 255,",
    "rgba(0, 128, 255,",
    "rgba(255, 128, 128,",
    "rgba(128, 255, 128,",
    "rgba(128, 128, 255,",
    "rgba(192, 192, 192,",
    "rgba(128, 128, 128,",
    "rgba(255, 255, 255,",
  ];

  const getAllWarehouse = () => {
    const request: any = ``;
    WarehousesService.getWarehouses(request)
      .then((response: any) => {
        let data = response.data.data;
        const newArray = data.map((item: any) => {
          return {
            value: item?.id,
            label: `${item?.code} ${item?.address}`,
            country: item?.countryCode,
            code: item?.code,
          };
        });
        setWarehouseList(newArray);
      })
      .catch((e: Error) => {});
  };

  const getOrderServices = () => {
    const request: any = ``;
    AdditionalServiceService.getAdditionalServices(request)
      .then((response: any) => {
        const filteredList = response.data.data.filter(
          (service: any) => service.type === "FBA"
        );
        setOrderServices(filteredList);

        const orderServiceUpdated = filteredList.map((service: any) => ({
          ...service,
          qty: 0,
        }));

        setOrderServices(orderServiceUpdated);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getOrderItemServices = () => {
    const request: any = ``;
    AdditionalServiceService.getAdditionalServices(request)
      .then((response: any) => {
        let items = response.data.data.filter(
          (service: any) => service.type === "FBA"
        );
        items = items.sort((a: any, b: any) => a.id - b.id);
        const orderItemServiceUpdated = items.map((service: any) => ({
          ...service,
          qty: service.id === 1 ? 1 : service.required === false ? 0 : 1,
        }));
        setOrderItemServices(orderItemServiceUpdated);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getOrderItemServices();
    getOrderServices();
    getAllWarehouse();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let services = orderServices.map((service: any) => {
      if (service.required === true) {
        service.qty = packageCount;
        return service;
      }
      return service;
    });
    setOrderServices(services);
    // eslint-disable-next-line
  }, [packageCount]);

  const orderServiceList = () => {
    let fbaServiceList: { [key: string]: any } = {};
    productList.forEach((product: any) => {
      product.services.forEach((service: any) => {
        if (service.qty > 0) {
          if (fbaServiceList.hasOwnProperty(service.id)) {
            fbaServiceList[service.id].qty += service.qty;
          } else {
            fbaServiceList[service.id] = { ...service };
          }
        }
      });
    });

    const aggregatedServiceList = Object.values(fbaServiceList);
    setFbaServices(aggregatedServiceList);
  };

  useEffect(() => {
    orderServiceList();
    // eslint-disable-next-line
  }, [productList]);

  const calcParams = () => {
    let deci = 0;
    let weight = 0;
    let qty = 0;

    let fulfillmentCost: any = 0;
    let serviceCost = 0;
    let customServiceCost = 0;
    let fulfillmentCostPerUnit = 0;

    let FBACost = 0;
    // eslint-disable-next-line
    orderServices.map((orderService: any) => {
      FBACost += orderService?.qty * orderService?.price;
    });

    setTotalFBACost(FBACost);
    // eslint-disable-next-line
    productList.map((orderItem: any) => {
      weight += orderItem.qty * orderItem.weight;
      deci += orderItem.qty * orderItem.deci;
      qty += orderItem.qty;
      // eslint-disable-next-line
      orderItem.services.map((serviceItem: any) => {
        if (serviceItem.required === false) {
          if (serviceItem.qty > 0) {
            customServiceCost += serviceItem.qty * serviceItem.price;
          }
        } else {
          serviceCost += serviceItem.qty * serviceItem.price;
        }
      });
    });

    fulfillmentCost = (
      FBACost +
      shipmentFee +
      serviceCost +
      customServiceCost
    ).toFixed(2);

    const FbaPrepFee = (FBACost + serviceCost + customServiceCost).toFixed(2);

    fulfillmentCostPerUnit = fulfillmentCost / qty;

    setTotalQty(qty);
    setTotalWeight(weight.toFixed(2));
    setCurrentDeci(deci.toFixed(2));
    setTotalFulfillmentCost(fulfillmentCost);
    setTotalCustomServiceCost(customServiceCost.toFixed(2));
    setTotalServiceCost(serviceCost.toFixed(2));
    setFBAPrepServiceFee(FbaPrepFee);
    setTotalFulfillmentCostPerUnit(fulfillmentCostPerUnit.toFixed(2));
  };
  
  useEffect(() => {
    calcParams();
    // eslint-disable-next-line
  }, [productList, shipmentFee, orderServices]);

  const addProduct = (data: any) => {
    if (!productList.some((item: any) => item.asin === data.asin)) {
      let newData = {
        ...data,
        qty: 1,
        color: colorsArray[productList.length - 1],
        purchasePrice: 0,
        fnskuLabel: null,
        trackingCode: null,
        services: JSON.parse(JSON.stringify(orderItemServices)),
      };
      setProductList([...productList, newData]);
      openNotification("success", "Success", "Product added to the list");
    } else {
      openNotification("warning", "Warning", "Product available in the list");
    }
  };

  const onHandleSetQty = (id: any, qty: any) => {
    if (qty === null) {
      return false;
    }
    let products = productList.map((product: any) => {
      if (product?.id === id) {
        product.qty = qty;

        let services = product?.services.map((serviceItem: any) => {
          if (serviceItem?.required === true) {
            serviceItem.qty = qty;
            return serviceItem;
          } else {
            if (serviceItem?.id === 1) {
              if (serviceItem.qty > 0) {
                serviceItem.qty = qty;
                return serviceItem;
              }
            } else {
              if (serviceItem.qty > qty) {
                serviceItem.qty = qty;
                return serviceItem;
              }
            }
          }
          return serviceItem;
        });

        product.services = services;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleSetServiceQty = (productId: any, serviceId: any, qty: any) => {
    let products = productList.map((product: any) => {
      if (product.id === productId) {
        let services = product.services.map((serviceItem: any) => {
          if (serviceItem.required === true) {
            return serviceItem;
          } else {
            if (serviceItem.id === serviceId) {
              serviceItem.qty = qty;
              return serviceItem;
            }
          }
          return serviceItem;
        });

        product.services = services;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandlePurchasePrice = (id: any, purchasePrice: any) => {
    let products = productList.map((product: any) => {
      if (product.id === id) {
        product.purchasePrice = purchasePrice;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleRemoveProduct = (id: any) => {
    const updatedList = productList.filter((item: any) => item.id !== id);
    setProductList(updatedList);
    openNotification("success", "Success", "Product removed from the list");
  };

  const onHandleSetFNSKULabel = (id: any, file: any) => {
    let products = productList.map((product: any) => {
      if (product.id === id) {
        product.fnskuLabel = file;
        return product;
      }
      return product;
    });
    setProductList(products);
  };

  const onHandleSetFBALabel = (file: any) => {
    console.log("FILE FBALabel",file);
    setFBALabel(file);
  };

  const onHandleSetWarehouse = (id: any) => {
    setWarehouse(id);
  };

  const onHandleSetCountry = (countryCode: any) => {
    setCountry(countryCode);
  };

  const onHandleChangeShipmentCompany = (company: any) => {
    setShipmentCompany(company);
  };

  const onHandleChangeShipmentFee = (fee: any) => {
    setShipmentFee(fee);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const steps = [
    {
      title: "",
      content: (
        <FBACreateStep1
          addProduct={addProduct}
          removeProduct={onHandleRemoveProduct}
          setQty={onHandleSetQty}
          setPurchasePrice={onHandlePurchasePrice}
          setWarehouse={onHandleSetWarehouse}
          setOnHandleCountry={onHandleSetCountry}
          productList={productList}
          totalQty={totalQty}
          totalWeight={totalWeight}
          totalDeci={totalDeci}
          warehouseList={warehouseList}
          warehouse={warehouse}
          country={country}
        />
      ),
    },
    {
      title: "",
      content: (
        <FBACreateStep2
          setServiceQty={onHandleSetServiceQty}
          changeShipmentCompany={onHandleChangeShipmentCompany}
          changeShipmentFee={onHandleChangeShipmentFee}
          setPackageCount={setPackageCount}
          setCargoLabel={setCargoLabel}
          cargoLabel={cargoLabel}
          country={country}
          productList={productList}
          fbaServices={fbaServices}
          totalFulfillmentCost={totalFulfillmentCost}
          totalCustomServiceCost={totalCustomServiceCost}
          totalFBACost={totalFBACost}
          totalFulfillmentCostPerUnit={totalFulfillmentCostPerUnit}
          orderServices={orderServices}
        />
      ),
    },
    {
      title: "",
      content: (
        <FBACreateStep3
          setFNSKULabel={onHandleSetFNSKULabel}
          setPackageCount={setPackageCount}
          setFBALabel={onHandleSetFBALabel}
          productList={productList}
          totalWeight={totalWeight}
          fbaLabel={fbaLabel}
          orderServices={orderServices}
        />
      ),
    },
    {
      title: "",
      content: (
        <FBACreateStep4
          setNote={setNote}
          warehouse={warehouse}
          country={country}
          productList={productList}
          shipmentFee={shipmentFee}
          shipmentCompany={shipmentCompany}
          totalFBACost={totalFBACost}
          totalCustomServiceCost={totalCustomServiceCost}
          totalFulfillmentCost={totalFulfillmentCost}
          totalFulfillmentCostPerUnit={totalFulfillmentCostPerUnit}
          warehouseList={warehouseList}
        />
      ),
    },
  ];

  const next = () => {
    if (current === 2) {
      modalRender();
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const getBalance = () => {
    OneamzAppService.getBalance()
      .then((response: any) => {
        let currentBalance: number = response.data.balance;
        setCurrentBalance(currentBalance);
        if (currentBalance < totalFulfillmentCost) {
          setLoadBalance((totalFulfillmentCost - currentBalance).toFixed(2));
          setIsModalOpen(true);
        } else {
          setIsConfirmModal(true);
          // onCreateOrder();
        }
      })
      .catch((e: Error) => {});
  };

  const getFbaOrderDetail = (id: number) => {
    FbaOrderService.getFbaOrderById(id)
      .then((response: any) => {
        const data: IFbaOrderResponse = new IFbaOrderResponse(response.data);
        setOrder(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const onCreateOrder = () => {
    setOrderCreateLoading(true);

    let orderItems: any = [];
    // eslint-disable-next-line
    productList.map((orderItem: any) => {
      let orderItemObj = {
        asin: orderItem.asin,
        quantity: orderItem.qty,
        additionalServices: [],
      };

      if (orderItem.qty > 0) {
        let orderItemServiceListArr: any = [];
        // eslint-disable-next-line
        orderItem.services.map((service: any) => {
          if (service.qty > 0) {
            const orderItemService = {
              id: service.id,
              quantity: service.qty,
            };
            orderItemServiceListArr.push(orderItemService);
          }
        });

        orderItemObj.additionalServices = orderItemServiceListArr;

        orderItems.push(orderItemObj);
      }
    });

    let postObj = {
      userId: Number(id),
      warehouseId: warehouse,
      items: orderItems,
      notes: note,
    };

    FbaOrderService.createFbaOrder(postObj)
      .then((response: any) => {
        const orderId = response.data.id;
        const externalUserId = response.data.user.externalUserId;
        openNotification("success", "Success", "FBA shipment created");
        setFbaNo(orderId);
        setExternalUserId(externalUserId);
        setShipmentCode(`ONESHIP (${externalUserId}-FBA-${orderId})`);
        uploadFnskuLabel(orderId);
        getFbaOrderDetail(orderId);
        setOrderCreateLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setOrderCreateLoading(false);
      });
  };

  const uploadFnskuLabel = async (orderId: any) => {
    try {
      const response = await FbaOrderService.getFbaOrderById(orderId);
      const orderItems = response.data.items;

      const uploadPromises = [];

      for (const orderItem of orderItems) {
        for (const product of productList) {
          if (product?.asin === orderItem?.product?.asin) {
            if (product?.fnskuLabel !== null) {
              console.log("orderId", orderId);
              console.log("orderItem.id", orderItem.id);
              console.log("product.fnskuLabel", product.fnskuLabel);
              const uploadPromise = FbaOrderItemService.uploadFnskLabel(
                orderId,
                orderItem.id,
                product.fnskuLabel
              );
              uploadPromises.push(uploadPromise);
            }
          }
        }
      }

      await Promise.all(uploadPromises);

      // if (cargoLabel !== null) {
      //   FileUploadService.uploadCargoLabel(orderId, fbaLabel)
      //     .then((response: any) => {})
      //     .catch((e: Error) => {
      //       console.log(e);
      //     });
      // }

      if (fbaLabel !== null) {
        FbaOrderService.uploadFbaLabel(orderId, fbaLabel)
          .then((response: any) => {
            openNotification("success", "Success", "FBA shipment created");
            //  navigate('/fba');
            setOrderCreateLoading(false);
            setFbaCreated(true);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      } else {
        openNotification("success", "Success", "FBA shipment created");
        setOrderCreateLoading(false);
        setFbaCreated(true);
      }
    } catch (e) {
      setOrderCreateLoading(false);
      console.error(e);
    }
  };

  const modalRender = () => {
    let fnskuWarning = 0;
    // eslint-disable-next-line
    const response = productList.map((orderItem: any, index: number) => {
      let services = orderItem.services;
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        if (
          service.id === 1 &&
          service.qty > 0 &&
          orderItem.fnskuLabel === null
        ) {
          fnskuWarning++;
        }
      }
    });

    if (fnskuWarning || fbaLabel === null) {
      if (fnskuWarning > 0) {
        setFnskuWarning(true);
      } else {
        setFnskuWarning(false);
      }

      setLabelModal(true);
    } else {
      setCurrent(current + 1);
    }

    return response;
  };

  return (
    <>
      <Modal
        open={labelModal}
        onCancel={() => {
          setLabelModal(false);
        }}
        footer={() => (
          <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
            <Col>
              <Button
                className="bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text"
                onClick={() => {
                  setLabelModal(false);
                }}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button
                className="bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text"
                loading={orderCreateLoading}
                onClick={() => {
                  setCurrent(current + 1);
                  setLabelModal(false);
                }}
              >
                Next
              </Button>
            </Col>
          </Row>
        )}
      >
        <Row justify={"center"}>
          <WarningIcon
            className="ml-32"
            style={{ fontSize: "48px", marginTop: "20px", color: "#FFCB2B" }}
          />
        </Row>
        <Row justify={"center"}>
          <Col span={20}>
            {fbaLabel === null && (
              <h3 style={{ textAlign: "center", fontWeight: 400 }}>
                <b>FBA</b> Label Not Uploaded
              </h3>
            )}
            {fnskuWarning && (
              <h3 style={{ textAlign: "center", fontWeight: 400 }}>
                <b>FNSKU</b> Label Not Uploaded
              </h3>
            )}
            <h3 style={{ textAlign: "center", fontWeight: 400 }}>
              Are you sure you want to continue?
            </h3>
          </Col>
        </Row>
      </Modal>
      {!fbaCreated ? (
        <>
          <Modal
            title={<h3 style={{ margin: "0px" }}>Insufficient Credit</h3>}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "black",
                      color: "pure-white",
                      padding: "0px 30px",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FFCB2B",
                        color: "black",
                        padding: "0px 20px",
                      }}
                      type="primary"
                    >
                      Upload Credit
                    </Button>
                  </a>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <InfoCircleOutlined
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <h3
              style={{
                textAlign: "center",
                fontWeight: 400,
                margin: "40px 0px",
              }}
            >
              You must load at least <b>${loadBalance}</b> credit for your
              transaction
            </h3>
          </Modal>
          <Modal
            open={isConfirmModal}
            onCancel={() => {
              setIsConfirmModal(false);
            }}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    className="bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text"
                    onClick={() => {
                      setIsConfirmModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text"
                    loading={orderCreateLoading}
                    onClick={() => {
                      onCreateOrder();
                    }}
                  >
                    Confirm
                  </Button>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <WarningIcon
                className="ml-32"
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <Row justify={"center"}>
              <Col span={20}>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px",
                  }}
                >
                  <b>${totalFulfillmentCost}</b> will be deducted from your
                  total credit for the FBA Shipment payment. Do you agree?
                </h3>
              </Col>
            </Row>
          </Modal>
          <ProgressCard
            setFBAShipmentId={setFBAShipment}
            currentStep={current}
          />
          <>{steps[current].content}</>
          <Row
            style={{ marginTop: 24 }}
            justify={current === 0 ? "end" : "space-between"}
          >
            {current > 0 && (
              <Button
                className="btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue"
                size="large"
                onClick={() => prev()}
              >
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                className="btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud"
                disabled={totalQty === 0 || warehouse === null}
                type="primary"
                size="large"
                onClick={() => next()}
              >
                Next Step
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                className="btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud"
                type="primary"
                size="large"
                onClick={() => getBalance()}
              >
                Create FBA Shipment
              </Button>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <StarterCard />
            </Col>
          </Row>
          <Card
            className="mt-24"
            bodyStyle={{ border: "none", padding: "0px" }}
            style={{ border: "none" }}
          >
            <Row gutter={24}>
              <Col xl={6} xs={24}>
                <Card className="bg-oneamz-yellow border-radius-md">
                  <p className="text font-color-slate-blue bg-cloud p-8 d-inline border-radius-sm">
                    <b>
                      FBA SHIPMENT CODE <InfoIcon className="wh-14" />
                    </b>
                  </p>
                  <h2 className="h-3 font-color-slate-blue mt-16 mb-0">
                    ONESHIP
                  </h2>
                  <h2 className="h-3 font-color-slate-blue mt-0">
                    ({externalUserId}-FBA-{fbaNo})
                  </h2>
                  <p className="text fs-18 font-color-slate-blue">
                    To access the details of your product, you can search within
                    the app with the oneship shipping code.
                  </p>
                  <Row justify={"center"} className="mt-64">
                    <Col>
                      <Image preview={false} src={FbaImg} />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xl={18} xs={24}>
                <Card className="bg-cloud border-radius-md">
                  <h1 className="h-2 font-color-slate-blue m-0">
                    Congratulations, you have completed Step 1!
                  </h1>
                  <p className="text fs-24 font-color-slate-blue m-0">
                    Your shipment request has been successfully registered
                  </p>
                  <p className="text font-color-slate-blue mt-8">
                    Thank you for your care and attention. You have successfully
                    completed this stage of your Amazon FBA process. In your
                    shipment details{" "}
                    <b>
                      {shipmentCode}
                    </b>{" "}
                    You can reach FBA with the Shipping Code. Complete Step 2
                    and finalize your FBA shipping process.
                  </p>
                  <p className="pretitle font-color-slate-blue mt-32 upper-case">
                    PRODUCT INFORMATION
                  </p>
                  {order?.items.map((item: any, index: any) => (
                    <FBAResultItemCard
                      item={item}
                      key={index}
                    />
                  ))}
                </Card>

                <Alert
                  className="border-none border-radius-md mt-24"
                  description={
                    <p className="text fs-14 m-0">
                      <b>IMPORTANT INFORMATION:</b> Please note that your order
                      will not be completed unless you send the products to the
                      address below.
                    </p>
                  }
                  type="info"
                  showIcon
                />
                <Card className="bg-cloud mt-24 border-radius-md">
                  <Row>
                    <Col span={24}>
                      <h2 className="h-2 m-0 font-color-slate-blue mb-0">
                        Step 2: Send Products to Warehouse
                      </h2>
                      <p className="font-color-slate-blue fs-14 btn-text-sm mt-8">
                        We expect you to send the products you have specified
                        above to the OneAmz Warehouse address below.
                      </p>
                    </Col>
                    <Col span={24}>
                      <WarehouseInfoCard shipmentCode={shipmentCode} />
                    </Col>
                  </Row>
                </Card>
                <Row justify={"end"} gutter={8}>
                  <Col>
                    <Button
                      onClick={() => navigate(`/fba-order/detail/${fbaNo}`)}
                      className=" btn btn-text btn-hover-dark bg-cloud font-color-slate-blue border-slate-blue border-radius-12 mt-48"
                    >
                      Go to Shipment Detail
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => refreshPage()}
                      className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-48"
                    >
                      Create New FBA Shipment
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
}

export default FBACreate;
