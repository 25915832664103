import { Col, Row, Tooltip, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { openNotification } from "../../../../utils";
import { ReactComponent as UploadIcon } from "../../../../assets/V2/OrderDetailV2/upload.svg";
import FbaOrderItemService from "../../../../services/FbaOrderItemService";

interface FNSKULabelProps {
  order: any;
  orderItem: any;
}

const FNSKULabel: React.FC<FNSKULabelProps> = ({ order, orderItem }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploaded, setUploaded] = useState(false);
  const [fnskuLabel, setFnskuLabel] = useState(null);

  const getFnskuLabel = (id: any) => {
    FbaOrderItemService.downloadFnskLabel(order?.id, orderItem?.id)
      .then((response: any) => {
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const printWindow = window.open(pdfUrl);
        if (printWindow) {
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        }
      })
      .catch((e: Error) => {});
  };

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification("warning", "Warning", "You can upload only one file");
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList,
    accept: ".pdf",
    multiple: false,
  };

  useEffect(() => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj as File;
      FbaOrderItemService.uploadFnskLabel(order?.id, orderItem?.id, file)
        .then((response: any) => {
          openNotification("success", "Success", "FNSKU Label loaded");
          setUploaded(true);
        })
        .catch((e: Error) => {
          openNotification("warning", "Failed", "FNSKU Label failed to load");
        });
    }
    // eslint-disable-next-line
  }, [fileList]);

  useEffect(() => {
    const fnskuLabels = order?.items.map((item: any) => item.fnskLabel) || [];
    setFnskuLabel(fnskuLabels);
    // eslint-disable-next-line
  }, [orderItem]);

  return (
    <>
      {fnskuLabel !== null || uploaded ? (
        <Row justify={"start"} align={"middle"}>
          <Col xl={2}>
            <UploadIcon className="pointer" />
          </Col>
          <Col xl={20}>
            <p
              className="link mb-0 pointer"
              onClick={() => getFnskuLabel(orderItem?.id)}
            >
              Show FNSKU Label
            </p>
            <p className="text fs-12 mt-0 font-color-success">
              <Tooltip title={orderItem?.product?.title}>
                *{orderItem?.product?.asin}
              </Tooltip>{" "}
              FNSKU Label Installed
            </p>
          </Col>
          <Col>
            <Tooltip title="Delete FNSKU Label">
              <DeleteOutlined
                onClick={() => {
                  setUploaded(false);
                  setFnskuLabel(null);
                  setFileList([]);
                }}
                className="cursor-pointer icon-size-20"
                style={{ marginLeft: "5px" }}
              />
            </Tooltip>
          </Col>
        </Row>
      ) : (
        <Upload {...props}>
          <Row gutter={[16, 16]} justify={"start"} align={"middle"}>
            <Col xl={2}>
              <UploadIcon className="pointer" />
            </Col>
            <Col xl={22}>
              <p className="link mb-0 pointer">Load FNSKU Label</p>
              <p className="text fs-12 mt-0 font-color-error">
                Waiting for FNSKU Tag for {orderItem?.product?.asin}
              </p>
            </Col>
          </Row>
        </Upload>
      )}
    </>
  );
};

export default FNSKULabel;
