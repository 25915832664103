import http from "../http-common";
import {
  IUserBoxesRequest,
  IPaginatedUserBoxesResponse,
  IUserBoxResponse,
  IUserBoxesCheckRequest,
  IUserBoxesCheckResponse,
  IUserBoxesPurchaseRequest,
  IUserBoxesCheckStorageRequest,
  IUserBoxesCheckStorageResponse,
} from "../types/UserBox";

const getUserBoxes = (filterCriteria: IUserBoxesRequest) => {
  return http.get<IPaginatedUserBoxesResponse>(
    `/api/v2/users/boxes${filterCriteria}`
  );
};

const getUserBoxById = (id: number) => {
  return http.get<IUserBoxResponse>(`/api/v2/users/boxes/${id}`);
};

const postUserBoxesCheck = (requestBody: IUserBoxesCheckRequest) => {
  return http.post<IUserBoxesCheckResponse>(
    `/api/v2/users/boxes/check`,
    requestBody
  );
};

const postUserBoxesPurchase = (
  requestBody: IUserBoxesPurchaseRequest,
  token: string
) => {
  return http.post<IUserBoxResponse>(
    `/api/v2/users/boxes/purchase`,
    requestBody,
    {
      headers: {
        "x-oneamz-token": token,
      },
    }
  );
};

const postUserBoxesCheckStorage = (requestBody: IUserBoxesCheckStorageRequest) => {
  return http.post<IUserBoxesCheckStorageResponse>(
    `/api/v2/users/boxes/check-storage`,
    requestBody
  )
}

const UserBoxService = {
  getUserBoxes,
  getUserBoxById,
  postUserBoxesCheck,
  postUserBoxesPurchase,
  postUserBoxesCheckStorage
};

export default UserBoxService;
