import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

const EmailVerificationCallback = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    verification(token);
    // eslint-disable-next-line
  }, []);

  const verification = (value: any) => {
    let verifyData: any = {
      token: value,
    };

    AuthService.verificationCallback(verifyData)
      .then((response: any) => {
        navigate("/verify");
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return <></>;
};

export default EmailVerificationCallback;
