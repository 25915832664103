import React from "react";
import { Card, Col, Row } from "antd";
import { ReactComponent as HouseCheckIcon } from "../../../../assets/V2/OrderDetailV2/house-check.svg";
import { CopyClipboard } from "../../../Common/CopyClipboard/CopyClipboard";
import StorageItemCard from "./StorageItemCard";
import { IIncomingInventoryResponse } from "../../../../types/IncomingInventory";
import StorageItemUpdateCard from "./StorageItemUpdateCard";

interface OrderDetailCardProps {
  order: IIncomingInventoryResponse;
  loading?: boolean;
}

const StorageDetail: React.FC<OrderDetailCardProps> = ({ order, loading }) => {
  const fullName =
    localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");
  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={4}>
                  <HouseCheckIcon className="wh-48" />
                </Col>
                <Col span={20}>
                  <p className="text font-color-onyx">
                    <b>OneAmz Warehouse Address</b>
                  </p>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>Full/Company Name:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={`ONESHIP-${fullName}`} />
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>Address Line 1:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={"356 GETTY AVE BLD-5A"} />
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>Address Line 2:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={`#${order.orderCode}`} />
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>City:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={"CLIFTON"} />
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>State:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={"NJ"} />
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>ZIP Code:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={"07011"} />
                    </Col>
                  </Row>
                  <Row className="mb-12">
                    <Col span={24}>
                      <b>Country:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={"United States"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <b>Phone:</b>
                    </Col>
                    <Col className="text font-color-onyx m-0">
                      {" "}
                      <CopyClipboard text={"917 306 6033"} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={24}>
                  <h3 className="text font-color-onyx">
                    <b>Note</b>
                  </h3>
                  <p className="text font-color-onyx m-0">{order?.note}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
      </Col>
      <Col>
        {/* <Divider type="vertical" className="vertical-divider"/> */}
      </Col>
      <Col xl={15} xs={24}>
        {Array.isArray(order) ? (
          order.map((item: any) => (
            <StorageItemCard key={item.id} orderItem={item} />
          ))
        ) : (
          <StorageItemCard orderItem={order} />
        )}
        {order && (
          <StorageItemUpdateCard orderItem={order} loading={loading} />
        )}
      </Col>
    </Row>
  );
};

export default StorageDetail;
