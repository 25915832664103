import { Card, Col, Collapse, Divider, Row } from "antd";
import { ReactComponent as ErrorIcon } from "../../../../assets/V2/OrderDetailV2/red-icon.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/V2/OrderDetailV2/success-icon.svg";
import TrackingCodeUpdate from "./TrackingCodeUpdate";
import { IFbaOrderResponse } from "../../../../types/FbaOrder";
import FNSKULabel from "./FNSKULabel";
import FBALabel from "./FBALabel";
import ShippingLabel from "./ShippingLabel";
import { useEffect, useState } from "react";

interface OrderDetailLabelsProps {
  order: IFbaOrderResponse;
}
const OrderDetailLabels: React.FC<OrderDetailLabelsProps> = ({ order }) => {
  const [total, setTotal] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [open, setOpen] = useState<any[]>(["1"]);
  
  useEffect(() => {
    let totalCount = 0;
    let completedCount = 0;

    totalCount++; // fbaLabel
    if (order?.fbaLabel !== null) {
      completedCount++;
    }

    order?.items.forEach((orderItem) => {
      if (orderItem?.shippingProvider === "AMAZON") {
        totalCount++; // shippingLabel
        if (orderItem?.shippingLabel !== null) {
          completedCount++;
        }
      }
    });
    //  eslint-disable-next-line
    order?.items.map((orderItem: any) => {
      totalCount++; // trackingCode
      if (orderItem?.trackingCode !== null && orderItem?.trackingCode !== "") {
        completedCount++;
      }
      //  eslint-disable-next-line
      orderItem?.additionalServices.map((serviceItem: any) => {
        if (serviceItem?.additionalService?.id === 1 && serviceItem?.quantity > 0) {
          totalCount++; //fnskuLabel
          if (orderItem.fnskLabel !== null) {
            completedCount++;
          }
        }
      });
    });
    setTotal(totalCount);
    setCompleted(completedCount);

    if (totalCount > completedCount) {
      setOpen(["1"]);
    } else {
      setOpen([]);
    }
    // eslint-disable-next-line
  }, [order]);

  return (
    <>
      <Card className="border-radius-md mt-24 antd-card-body-p-0 p-16">
        <Collapse
          className="label-collapse"
          expandIconPosition={"end"}
          defaultActiveKey={open}
          ghost
          items={[
            {
              key: "1",
              label: (
                <>
                  <Row align={"middle"}>
                    <Col span={4}>
                      {total === completed ? <SuccessIcon /> : <ErrorIcon />}
                    </Col>
                    <Col span={20}>
                      <p className="text m-0">Label and Shipping Information</p>
                      <Row>
                        <p className="pretitle m-0 font-color-slate-blue">
                          <b
                            className={
                              total === completed
                                ? "font-color-success"
                                : "font-color-error"
                            }
                          >
                            {completed}/{total} LOADED
                          </b>
                        </p>
                      </Row>
                    </Col>
                  </Row>
                </>
              ),
              children: (
                <>
                  <Divider className="m-0" />
                  {order?.items?.map(
                    (orderItem: any) =>
                      orderItem?.shippingProvider === "AMAZON" && (
                        <Row key={orderItem?.id}>
                          <Col span={24}>
                            <ShippingLabel order={order} />
                          </Col>
                        </Row>
                      )
                  )}
                  <Row>
                    <Col span={24}>
                      <FBALabel order={order} />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {order?.items.map((orderItem: any) =>
                        orderItem?.additionalServices.map((serviceItem: any) =>
                          serviceItem?.additionalService?.id === 1 && serviceItem?.quantity > 0 ? (
                            <FNSKULabel order={order} orderItem={orderItem} />
                          ) : null
                        )
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {order?.items.map((orderItem: any) => (
                        <TrackingCodeUpdate
                          order={order}
                          orderItem={orderItem}
                        />
                      ))}
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </Card>
    </>
  );
};

export default OrderDetailLabels;
