import http from "../http-common";
import {
  IInventoryRequest,
  IInventoryResponse,
  IPaginatedInventoriesResponse,
} from "../types/Inventory";

const getInventories = (filterCriteria: IInventoryRequest) => {
  return http.get<IPaginatedInventoriesResponse>(
    `/api/v2/inventories${filterCriteria}`
  );
};

const getInventoriesById = (id: number) => {
  return http.get<IInventoryResponse>(`/api/v2/inventories/${id}`);
};

const InventoryService = {
  getInventories,
  getInventoriesById,
};

export default InventoryService;
