import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { openNotification } from "../../../utils";
import FbaOrderService from "../../../services/FbaOrderService";
import { IFbaOrderResponse } from "../../../types/FbaOrder";
import FbmOrderService from "../../../services/FbmOrderService";
import { IFbmOrderResponse } from "../../../types/FbmOrder";

function Search() {
  const [loading, setLoading] = useState(false);
  const [search] = Form.useForm();
  const navigate = useNavigate();

  const getFbaOrderDetail = (value: string) => {
    const request: any = `?search=${value}`;
    FbaOrderService.getFbaOrders(request)
      .then((response: any) => {
        const order = response.data.data;
        if (Array.isArray(order) && order.length > 0) {
          const fbaOrder: IFbaOrderResponse = new IFbaOrderResponse(order[0]);
          navigate(`/fba-order/detail/${fbaOrder.id}`);
          search.resetFields();
          setLoading(false);
        } else {
          getFbmOrderDetail(value);
        }
      })
      .catch((e: Error) => {
        console.error("FBA order search failed", e);
        getFbmOrderDetail(value);
      });
  };

  const getFbmOrderDetail = (value: string) => {
    const request: any = `?search=${value}`;
    FbmOrderService.getFbmOrders(request)
      .then((response: any) => {
        const order = response.data.data;
        if (Array.isArray(order) && order.length > 0) {
          const fbmOrder: IFbmOrderResponse = new IFbmOrderResponse(order[0]);
          navigate(`/fbm-order/detail/${fbmOrder.id}`);
          search.resetFields();
        } else {
          openNotification(
            "error",
            "No Results",
            "No FBA or FBM orders found with this ASIN."
          );
        }
      })
      .catch((e: Error) => {
        console.error("FBM order search failed", e);
        openNotification(
          "error",
          "No Results",
          "No FBA or FBM orders found with this ASIN."
        );
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (values: any) => {
    if (values.asin) {
      setLoading(true);
      getFbaOrderDetail(values.asin);
    }
  };

  return (
    <>
      <Form form={search} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="asin">
          <Input.Search
            allowClear
            size="large"
            loading={loading}
            placeholder="Search by ASIN"
            enterButton={
              <Button loading={loading} htmlType="submit">
                Search
              </Button>
            }
          />
        </Form.Item>
      </Form>
    </>
  );
}

export default Search;
