import { Col, Row } from "antd";
import CustomersStarterCard from "../../components/Common/StarterCard/CustomersStarterCard";
import CustomersList from "../../components/Customers/CustomersList";

const CustomersPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <CustomersStarterCard />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomersList />
        </Col>
      </Row>
    </>
  );
};

export default CustomersPage;
