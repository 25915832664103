import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";

const links = [
  { path: "/settings/additional-services", label: "Additional Services" },
  { path: "/settings/amazon-fba-warehouses", label: "Amazon FBA Warehouses" },
  { path: "/settings/boxes", label: "Boxes" },
  { path: "/settings/warehouse-management", label: "Warehouse Management" },
  { path: "/settings/users", label: "Users" },
  { path: "/settings/roles", label: "Roles" },
];

const SettingsRouter = () => {
  const linkStyle = (isActive: boolean) =>
    isActive ? "font-color-slate-blue" : "font-color-slate-blue-05";

  return (
    <Row>
      {links.map(({ path, label }) => (
        <Col key={path} style={{ padding: "1rem" }}>
          <NavLink className={({ isActive }) => linkStyle(isActive)} to={path}>
            <p className="link fs-16">
              <b>{label}</b>
            </p>
          </NavLink>
        </Col>
      ))}
    </Row>
  );
};

export default SettingsRouter;
