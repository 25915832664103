import React from "react";
import { Avatar, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import AmazonIcon from "../../../assets/amazon.svg";

interface AmazonProductImageAvatarProps {
  orderItem: any;
}

const AmazonProductImageAvatar: React.FC<AmazonProductImageAvatarProps> = ({
  orderItem,
}) => {
  return (
    <Tooltip
      title={`${orderItem?.product?.asin} ${orderItem?.product?.title}`}
      placement="top"
    >
      <NavLink
        to={`https://www.amazon.com/dp/${orderItem?.product?.asin}`}
        target="_blank"
      >
        {/* <Badge overflowCount={9999999} color="#333E48" count={orderItem.qty}> */}
        <Avatar
          size={64}
          className="avatar-border"
          src={process.env.REACT_APP_API_BASE_URL === "https://oneship-test-api.oneamz.com" ? AmazonIcon : `https://api.oneamz.com/product-photo/${
            orderItem?.product?.asin
          }?Authorization=${localStorage.getItem("externalAccessToken")}`}
        />
        {/* </Badge> */}
      </NavLink>
    </Tooltip>
  );
};

export default AmazonProductImageAvatar;
