import { OrderStatus } from "../enums/OrderStatus";
import { IBoxResponse } from "./Box";
import { IProductResponse } from "./Product";
import { IUserResponse } from "./User";

export interface IncomingInventoryUpdateResponse {
  id: number;
  user: IUserResponse;
  receivedQuantity: number;
  damagedQuantity: number;
  createdAt: string;
}

interface IncomingInventoryResponse {
  id: number;
  user: IUserResponse;
  product: IProductResponse;
  status: OrderStatus;
  box: IBoxResponse;
  updates: IncomingInventoryUpdateResponse[];
  quantity: number;
  receivedQuantity: number;
  damagedQuantity: number;
  missingQuantity: number;
  createdAt: string;
  detailUrl: string;
  orderCode: string;
  printUrl: string;
}

interface IncomingInventoryQuantityUpdateRequest {
  receivedQuantity: number;
  damagedQuantity: number;
}

interface IncomingInventoryStatusUpdateRequest {
  status: OrderStatus;
}

interface IncomingInventoryCreateRequest {
  asin: string;
  quantity: number;
}

interface IncomingInventoryRequest {
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IIncomingInventoryResponse implements IncomingInventoryResponse {
  id: number;
  user: IUserResponse;
  product: IProductResponse;
  status: OrderStatus;
  box: IBoxResponse;
  updates: IncomingInventoryUpdateResponse[];
  quantity: number;
  receivedQuantity: number;
  damagedQuantity: number;
  missingQuantity: number;
  createdAt: string;
  detailUrl: string;
  orderCode: string;
  printUrl: string;

  constructor(obj: IncomingInventoryResponse) {
    this.id = obj.id;
    this.user = new IUserResponse(obj.user);
    this.product = new IProductResponse(obj.product);
    this.status = obj.status;
    this.box = new IBoxResponse(obj.box);
    this.updates = obj.updates.map(
      (update) => ({
        id: update.id,
        user: new IUserResponse(update.user),
        receivedQuantity: update.receivedQuantity,
        damagedQuantity: update.damagedQuantity,
        createdAt: update.createdAt,
      })
    );
    this.quantity = obj.quantity ?? 0;
    this.receivedQuantity = obj.receivedQuantity ?? 0;
    this.damagedQuantity = obj.damagedQuantity ?? 0;
    this.missingQuantity = obj.missingQuantity ?? 0;
    this.createdAt = obj.createdAt;
    this.detailUrl = `/storage-order/detail/${obj.id}`;
    this.orderCode = `${obj?.box?.name}-${obj?.id}-${obj?.user?.externalUserId}`;
    this.printUrl = `/storage/print/${obj.id}`;
  }
}

export class IIncomingInventoryQuantityUpdateRequest
  implements IncomingInventoryQuantityUpdateRequest
{
  receivedQuantity: number;
  damagedQuantity: number;

  constructor(obj: IncomingInventoryQuantityUpdateRequest) {
    this.receivedQuantity = obj.receivedQuantity;
    this.damagedQuantity = obj.damagedQuantity;
  }
}

export class IIncomingInventoryStatusUpdateRequest
  implements IncomingInventoryStatusUpdateRequest
{
  status: OrderStatus;

  constructor(obj: IncomingInventoryStatusUpdateRequest) {
    this.status = obj.status;
  }
}

export class IIncomingInventoryCreateRequest
  implements IncomingInventoryCreateRequest
{
  asin: string;
  quantity: number;

  constructor(obj: IncomingInventoryCreateRequest) {
    this.asin = obj.asin;
    this.quantity = obj.quantity;
  }
}

export class IIncomingInventoryRequest implements IncomingInventoryRequest {
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: IncomingInventoryRequest) {
    this.statuses = obj.statuses ?? [];
    this.userId = obj.userId;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedIncomingInventoriesResponse {
  data: IncomingInventoryResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedIncomingInventoriesResponse
  implements PaginatedIncomingInventoriesResponse
{
  data: IIncomingInventoryResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedIncomingInventoriesResponse) {
    this.data = obj.data.map((order) => new IIncomingInventoryResponse(order));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
