import { Card, Col, Divider, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { IFbmOrderResponse } from "../../../../types/FbmOrder";
import { NavLink } from "react-router-dom";
import { ReactComponent as InfoIcon } from "../../../../assets/info-icon.svg";

interface OrderDetailCardProps {
  order: IFbmOrderResponse;
}

const FBMStoragesWithAsin: React.FC<OrderDetailCardProps> = ({ order }) => {
  const [storages, setStorages] = useState<{ boxName: string; stock: number }[]>([]);

  useEffect(() => {
    const storageData = order?.items
      ?.flatMap(item => item.inventoryLogs || [])
      ?.filter(log => log.inventory?.product?.asin === order.items?.[0]?.product?.asin)
      ?.map(log => ({
        boxName: log.inventory?.box?.name || "Unknown",
        stock: log.inventory?.stock ?? 0,
      })) || [];

    setStorages(storageData);
    // eslint-disable-next-line
  }, [order]);

  return (
    <Card className="bg-cloud border-radius-md mt-24">
      <Row>
        <Col span={24}>
          <h3 className="text font-color-onyx mt-0">
            <b>Boxes</b>
          </h3>
          <NavLink
            to={`https://www.amazon.com/dp/${order?.items?.[0]?.product?.asin || "#"}`}
            target="_blank"
          >
            <p className="text font-color-slate-blue bg-dorian p-8 d-inline border-radius-sm">
              <b>{order?.items?.[0]?.product?.asin || "N/A"}</b>
              <Tooltip title="The Amazon Standard Identification Number (ASIN) is a unique identification number assigned to Amazon.com and its partners for product identification within the Amazon.com organization.">
                <InfoIcon className="ml-4 wh-14" />
              </Tooltip>
            </p>
          </NavLink>
          <Row className="mt-24">
            <Col span={12} style={{ textAlign: "center" }}>
              <h3 className="text font-color-onyx mt-0">
                <b>Box</b>
              </h3>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <h3 className="text font-color-onyx mt-0">
                <b>Stock</b>
              </h3>
            </Col>
          </Row>
          <Divider style={{ margin: "0 0 1em 0" }} />
          {storages.map((item, index) => (
            <Row key={index} align={"middle"}>
              <Col span={12} style={{ textAlign: "center" }}>
                <p className="text font-color-onyx m-0">{item.boxName}</p>
              </Col>
              <Col span={12} style={{ textAlign: "center" }}>
                <p className="text font-color-onyx m-0">{item.stock}</p>
              </Col>
              <Divider style={{ margin: "1em 0 0 0" }} />
            </Row>
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default FBMStoragesWithAsin;
