interface UserResponse {
  id?: number;
  externalUserId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  fullName: string;
  type: "CUSTOMER" | "ADMIN" | "WAREHOUSE_MANAGER";
  createdAt?: string;
}

interface UserRequest {
  types: ("CUSTOMER" | "ADMIN" | "WAREHOUSE_MANAGER")[];
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IUserResponse implements UserResponse {
  id?: number;
  externalUserId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  fullName: string;
  type: "CUSTOMER" | "ADMIN" | "WAREHOUSE_MANAGER";
  createdAt?: string | undefined;

  constructor(obj: UserResponse) {
    this.id = obj.id;
    this.externalUserId = obj.externalUserId;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.email = obj.email;
    this.phoneNumber = obj.phoneNumber;
    this.fullName = obj.fullName;
    this.type = obj.type;
    this.createdAt = obj.createdAt;
  }
}

export class IUserRequest implements UserRequest {
  types: ("CUSTOMER" | "ADMIN" | "WAREHOUSE_MANAGER")[];
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: UserRequest) {
    this.types = obj.types;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search;
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedUserResponse {
  data: UserResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedUserResponse implements PaginatedUserResponse {
  data: IUserResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedUserResponse) {
    this.data = obj.data.map((user) => new IUserResponse(user));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
