import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/XCircle.svg";
import { openNotification } from "../../utils";
import { IBoxCreateRequest } from "../../types/Box";
import BoxService from "../../services/BoxService";
import BoxSizeService from "../../services/BoxSizeService";
import { IBoxSizeResponse } from "../../types/BoxSize";

interface BoxDetailProps {
  onHandleBoxList: () => void;
}

const { Item } = Form;
const { Option } = Select;

const CreateBox: React.FC<BoxDetailProps> = ({ onHandleBoxList }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [boxSizes, setBoxSizes] = useState<IBoxSizeResponse[]>([]);

  useEffect(() => {
    form.resetFields();
    if (isModalVisible) {
      getBoxSizes();
    }
    // eslint-disable-next-line
  }, [isModalVisible]);

  const getBoxSizes = () => {
    setLoading(true);
    const request: any = "";
    BoxSizeService.getBoxSizes(request)
      .then((response) => {
        setBoxSizes(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const createBox = (values: any) => {
    setLoading(true);
    let postObj: IBoxCreateRequest = {
      name: values.name,
      isAvailable: values.isAvailable,
      boxSizeId: values.boxSizeId,
    };
    BoxService.createBox(postObj)
      .then((response: any) => {
        onHandleBoxList();
        openNotification("success", "Success", "Box added successfully");
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row justify={"end"}>
        <Button
          className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
          onClick={() => setIsModalVisible(true)}
        >
          Add Box
        </Button>
      </Row>
      <Modal
        title={<>Add Box</>}
        open={isModalVisible}
        className="antd-modal-secondary "
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={createBox} layout="vertical">
          <Item
            name="name"
            label="Box Name"
            rules={[
              {
                required: true,
                message: "Box name required",
              },
            ]}
          >
            <Input placeholder="Enter Box Name" />
          </Item>

          <Item
            name="boxSizeId"
            label="Box Size"
            rules={[
              {
                required: true,
                message: "Box size is required",
              },
            ]}
          >
            <Select placeholder="Select Box Size">
              {boxSizes.map((size) => (
                <Option key={size?.id} value={size?.id}>
                  {size?.name}
                </Option>
              ))}
            </Select>
          </Item>

          <Row gutter={24}>
            <Col>
              <Item
                name="isAvailable"
                label="Available"
                valuePropName="checked"
              >
                <Switch />
              </Item>
            </Col>
          </Row>

          <Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                  loading={loading}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateBox;
