import { Card, Col, Row, Segmented } from "antd";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DateRange from "../DateRange/DateRange";
import { formatDate, getDateRangeDefault } from "../../../utils";
import ReportService from "../../../services/ReportService";
import { IReportRequest } from "../../../types/Report";

const Graph = () => {
  const [dateRange, setDateRange] = useState<any[]>(() =>
    getDateRangeDefault()
  );
  const [data, setData] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [label, setLabel] = useState<any>("FBA");
  const [orderType, setOrderType] = useState<any>("FBA");

  const getReport = () => {
    const request: IReportRequest = {
      startDate: `${formatDate(dateRange[0])}T00:00:00`,
      endDate: `${formatDate(dateRange[1])}T23:59:59`,
    };

    ReportService.getDailyReport(orderType, request)
      .then((response: any) => {
        let data = response.data;
        let chartData = data.map((item: any) => item.count);
        let dates = data.map((item: any) => item.date);
        setData(chartData);
        setCategories(dates);
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
      getReport();
    // eslint-disable-next-line
  }, [orderType, dateRange]);
  

  const series: ApexAxisChartSeries = [
    {
      name: label + " operations",
      data: data,
      color: "#FFCB2B",
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "amz-example",
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    xaxis: {
      categories: categories,
      type: "datetime",
    },
    stroke: {
      curve: "smooth",
    },
    yaxis: {
      show: false,
    },
    grid: {
      borderColor: "#555",
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "";
        },
      },
    },
  };

  return (
    <>
      <Card className="bg-cloud h-full border-radius-md">
        <Row align={"middle"} justify={"space-between"}>
          <Col xxl={12}>
            <h3 className="h-3 font-color-slate-blue m-0">Process Graph</h3>
          </Col>
          <Col xxl={12} xs={24}>
            <DateRange
              key={`date-range-${orderType}`}
              onChangeDateRange={(dateRange) => setDateRange(dateRange)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={300}
            />
          </Col>
        </Row>
        <Row align={"middle"} gutter={16}>
          <Segmented
            className="btn-text"
            options={["FBA", "FBM", "STORAGE"]}
            onChange={(value) => {
              if (value === "FBA") {
                setLabel("FBA");
                setOrderType("FBA");
              } else if (value === "FBM") {
                setLabel("FBM");
                setOrderType("FBM");
              } else if (value === "STORAGE") {
                setLabel("STORAGE");
                setOrderType("INVENTORY");
              }
            }}
          />
        </Row>
      </Card>
    </>
  );
};

export default Graph;
