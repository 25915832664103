import { Col, Row, Card } from "antd";
import React from "react";
import AmazonProductInfo from "../../../Common/AmazonProduct/AmazonProductInfo";
import { Image } from "antd";
import AmazonIcon from "../../../../assets/amazon.svg";

interface StorageShipmentItemCardManagerProps {
  item: any;
}
const StorageResultItemCard: React.FC<StorageShipmentItemCardManagerProps> = ({
  item,
}) => {
  return (
    <>
      <Card>
        <Row align={"top"} justify={"start"}>
          <Col span={19}>
            <Row gutter={[24, 24]} align={"middle"} justify={"start"}>
              <Col span={8}>
                <Image
                  style={{
                    borderRadius: "50%",
                    border: "3px solid rgba(51,62,72,0.5)",
                  }}
                  height={"60px"}
                  width={"60px"}
                  src={
                    process.env.REACT_APP_API_BASE_URL ===
                    "https://oneship-test-api.oneamz.com"
                      ? AmazonIcon
                      : `https://api.oneamz.com/product-photo/${
                          item?.product?.asin
                        }?Authorization=${localStorage.getItem(
                          "externalAccessToken"
                        )}`
                  }
                />
              </Col>
              <Col span={16}>
                <AmazonProductInfo
                  asin={item?.product?.asin}
                  title={item?.product?.title}
                />
              </Col>
            </Row>
          </Col>
          {/* <Col span={5}>
            <Row>
              <Col span={24}>
                <p className="pretitle font-color-slate-blue upper-case">
                  ADDITIONAL SERVICES
                </p>
              </Col>
              <Col span={24}>
                {item?.additionalServices.map((service: any) => (
                  <>
                    <Row key={service?.additionalService?.id}>
                      <Col span={24}>
                        <p className="text-small font-color-slate-blue-05 m-0">
                          {service?.additionalService?.name}
                        </p>
                      </Col>
                    </Row>
                  </>
                ))}
              </Col>
            </Row>
          </Col> */}
          <Col span={5}>
            <Row>
              <Col span={24}>
                <Row justify={"center"}>
                  <Col>
                    <p className="pretitle font-color-slate-blue upper-case">
                      QUANTITY
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify={"center"}>
                  <Col>
                    <p className="btn-text-sm font-color-slate-blue-05 m-0">
                      {item?.quantity}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default StorageResultItemCard;
