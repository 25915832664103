import { Row } from "antd";
import React, { useRef } from "react";
import Barcode from "react-barcode";
import { ReactComponent as UploadIcon } from "../../../../assets/V2/OrderDetailV2/upload.svg";

interface BoxBarcodeProps {
  order: {
    orderCode: string;
  };
}

const BoxBarcode: React.FC<BoxBarcodeProps> = ({ order }) => {
  const printRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const printContent = printRef.current;
    const windowPrint = window.open("", "", "width=600,height=400");
    if (windowPrint && printContent) {
      windowPrint.document.write(printContent.innerHTML);
      windowPrint.document.close();
      windowPrint.print();
    }
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <UploadIcon className="pointer mr-8 mb-8" />
        <p className="link pointer fs-16" onClick={handlePrint}>
          Print Barcode
        </p>
      </Row>
      <div ref={printRef} style={{ textAlign: "center", marginTop: "0px" }}>
        <h1 style={{ fontSize: "32px", fontWeight: "bold" }}>
          {order.orderCode}
        </h1>
        <Barcode
          value={order.orderCode}
          width={2}
          height={60} // Barkod yüksekliği (default: 100)
          displayValue={true} // Barkod altında değeri gösterir (default: true)
          fontOptions="bold" // Font kalınlığı (örneğin: "italic", "bold")
          font="monospace" // Font türü (default: sans-serif)
          textAlign="center" // Alt metin hizalaması (default: center)
          textPosition="bottom" // Metin pozisyonu (default: bottom)
          textMargin={5} // Barkod ile metin arasındaki boşluk (default: 5)
          fontSize={18} // Alt metin font boyutu
          background="#ffffff" // Arka plan rengi (default: #ffffff)
          lineColor="#000000" // Çizgi rengi (default: #000000)
          margin={10}
        />
      </div>
    </>
  );
};

export default BoxBarcode;
