import http from "../http-common";
import { IProductResponse } from "../types/Product";

const getbyASIN = (asinCode: string) => {
  return http.get<IProductResponse>(`/api/v2/products/asin/${asinCode}`);
};

const ProductService = {
  getbyASIN,
};

export default ProductService;
