import http from "../http-common";
import {
  IPaginatedUserResponse,
  IUserRequest,
  IUserResponse,
} from "../types/User";

const getAllUser = (filter: IUserRequest) => {
  return http.get<IPaginatedUserResponse>(`/api/v2/users${filter}`);
};

const getUserById = (id: number) => {
  return http.get<IUserResponse>(`/api/v2/users/${id}`);
};

const UserService = {
  getAllUser,
  getUserById,
};

export default UserService;
