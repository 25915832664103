import React from "react";
import { Alert, Card, Col, Row } from "antd";
import FBMCard from "./FBMCard";

interface FBMCreateStepProps {
  changeCustomer: (id: any, data: any) => void;
  changeShipmentFee: (id: any, fee: any) => void;
  changeDeliveryOption: (id: any, data: any) => void;
  productList: [];
}

const FBMCreateStep2: React.FC<FBMCreateStepProps> = ({
  changeCustomer,
  changeShipmentFee,
  changeDeliveryOption,
  productList,
}) => {
  return (
    <>
      <Alert
        className="border-none border-radius-md p-24 mt-24"
        description={
          <p className="text fs-14 m-0">
            The shipping payment amount is calculated according to the
            measurement information received from Amazon. When the package
            arrives at the warehouse, there may sometimes be differences between
            the measurements. Due to the differences that will occur, the user
            will be notified to pay for the extra shipping fee.
          </p>
        }
        type="info"
        showIcon
      />
      <h3 className="h-3 font-color-slate-blue mt-24 mb-24">
        Customer Information
      </h3>
      <Card className="bg-cloud border-radius-md mt-24">
        <Row justify={"center"}>
          <Col span={24}>
            {productList.map((fbmItem: any) => (
              <FBMCard
                changeCustomer={changeCustomer}
                changeShipmentFee={changeShipmentFee}
                changeDeliveryOption={changeDeliveryOption}
                fbmItem={fbmItem}
              />
            ))}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FBMCreateStep2;
