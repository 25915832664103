import {
  Avatar,
  Card,
  Col,
  Image,
  Pagination,
  PaginationProps,
  Row,
  Table,
} from "antd";
import OneamzAppService from "../../services/OneamzAppService";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import UpImg from "../../assets/V2/WalletV2/Up.svg";
import DownImg from "../../assets/V2/WalletV2/down.svg";
import { formatMoney } from "../../utils";

const TransactionsList = () => {
  const [transactionsList, setTransactionsList] = useState<any[]>([]);
  const [totalTransactions, settotalTransactions] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = `?draw=1&start=${lastCurrentPage}&length=${lastPageSize}&timezone=%2B03:00&orderColumn=0&orderDirection=desc&categoryIds=1,9,10,16,17`;
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(start=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(length=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize, sortField, sortOrder]);

  const getBalanceHistory = () => {
    setLoading(true);
    OneamzAppService.getBalanceHistory(filterText)
      .then((response: any) => {
        setTransactionsList(response.data.data || []);
        settotalTransactions(response.data.recordsTotal || 0);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paginationOnChange: PaginationProps["onChange"] = (start, length) => {
    if (start > 0) {
      setCurrentPage(start - 1);
    }
    setPageSize(length);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&orderDirection=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&orderColumn=${field}&orderDirection=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getBalanceHistory();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const columns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "center",
      render: (text) =>
        text > 0 ? (
          <Avatar className="bg-success-02 pt-4" size={48}>
            <Image src={UpImg} alt="up" preview={false} />
          </Avatar>
        ) : (
          <Avatar className="bg-error-02 pt-4" size={48}>
            <Image src={DownImg} alt="up" preview={false} />
          </Avatar>
        ),
    },
    {
      title: "Shipment / Add Balance",
      dataIndex: "date",
      key: "date",
      width: 200,
      render: (text, record) => (
        <>
          <p className="text m-0 ">
            <b>{record?.amount > 0 ? "Add Balance" : "Shipment"}</b>
          </p>
          <p className="text-small m-0">{text}</p>
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      width: 150,
      render: (text) => {
        const formattedText = formatMoney(text);
        const color = text < 0 ? '#FF555C' : '#31D0AA';
        return <b style={{ color }}>{formattedText}</b>;
      },
    },
    // {
    //   title: "Remaining Balance",
    //   dataIndex: "balance",
    //   key: "balance",
    //   align: "center",
    //   width: 150,
    //   render: (text) => <b>{formatMoney(text)}</b>,
    // },
  ];

  return (
    <>
      <Card className="bg-cloud border-radius-md" loading={loading}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">Transactions List</h3>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table
              loading={loading}
              rowKey={(record) => record?.id || ""}
              columns={columns}
              dataSource={transactionsList}
              bordered
              scroll={{ x: 1000 }}
              pagination={false}
              onChange={handleTableChange}
            />
            <Row style={{ marginTop: "30px" }} justify={"center"}>
              <Pagination
                showSizeChanger={true}
                total={totalTransactions}
                defaultPageSize={pageSize}
                defaultCurrent={0}
                current={currentPage + 1}
                showQuickJumper
                onChange={paginationOnChange}
              />
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TransactionsList;
