import { Card, Col, Row, Image } from "antd";
import AddIcon from "../../../assets/Dashboard/add-icon.png";
import { NavLink } from "react-router-dom";

const AddCredit = () => {
  const externalToken = localStorage.getItem("externalAccessToken");
  const externalRefreshToken = localStorage.getItem("externalRefreshToken");

  return (
    <>
      <NavLink
        target="_blank"
        to={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D100%26openPaymentModal%3Dtrue`}
      >
        <Card className="bg-pure-white border-radius-md card-h-full h-full pointer pb-48">
          <Row className="h-full">
            <Col span={24}>
              <Row align={"top"}>
                <Image preview={false} height={48} width={48} src={AddIcon} />
              </Row>
            </Col>
            <Col className="h-full" span={24}>
              <Row className="h-full" align={"bottom"}>
                <Col xl={14} md={16} xs={24}>
                  <h3 className="h-3 m-0">Upload Credit</h3>
                  <p className="m-0 text-small">
                    Start Trading Now by topping up your account with credits!
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </NavLink>
    </>
  );
};

export default AddCredit;
