import { Card, Col, Image, Row } from "antd";
import FBACreateImg from "../../../../assets/FBA/fba-create-card1.png";

const CreateFbaCard = () => {
  return (
    <>
      <Card className="bg-oneamz-yellow pointer border-radius-md">
        <Row align={"middle"} gutter={24}>
          <Col xxl={6} lg={12}>
            <Image preview={false} src={FBACreateImg} />
          </Col>
          <Col xxl={18} lg={12}>
            <p className="subtitle font-color-slate-blue">
              Create <b>FBA</b> Shipment
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreateFbaCard;
