import { Button, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../assets/XCircle.svg";
import AdditionalServiceService from "../../../services/AdditionalServiceService";
import { IAdditionalServiceCreateRequest } from "../../../types/AdditionalServices";
import { openNotification } from "../../../utils";

interface AdditionalServiceDetailProps {
  onHandleServiceList: () => void;
}

const { Item } = Form;

const CreateAdditionalService: React.FC<AdditionalServiceDetailProps> = ({
  onHandleServiceList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line
  }, [isModalVisible]);

  const createAdditionalService = (values: any) => {
    setLoading(true);
    let postObj: IAdditionalServiceCreateRequest = {
      name: values.name,
      type: values.type,
      price: values.price,
      required: values.required,
    };
    AdditionalServiceService.createAdditionalService(postObj)
      .then((response: any) => {
        onHandleServiceList();
        openNotification("success", "Success", "Additional Service added successfully");
        setIsModalVisible(false);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row justify={"end"}>
        <Button
          className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
          onClick={() => setIsModalVisible(true)}
        >
          Add Additional Service
        </Button>
      </Row>
      <Modal
        title={<>Add AdditionalService</>}
        open={isModalVisible}
        className="antd-modal-secondary "
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={createAdditionalService} layout="vertical">
          <Item
            name="name"
            label="Additional Service Name"
            rules={[
              {
                required: true,
                message: "Additional Service name required",
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="type"
            label="Service Type"
            rules={[
              {
                required: true,
                message: "Service Type required",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={[
                { value: "FBA", label: "FBA" },
                { value: "FBM", label: "FBM" },
              ]}
            />
          </Item>
          <Item
            name="price"
            label="Unite Price"
            rules={[
              {
                required: true,
                message: "Unite Price required",
              },
            ]}
          >
            <Input/>
          </Item>
          <Row gutter={24}>
            <Col>
              <Item name="required" label="Required">
                <Switch />
              </Item>
            </Col>
          </Row>
          <Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={() => setIsModalVisible(false)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                  loading={loading}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateAdditionalService;
