import React, { useState } from "react";
import { Button, Card, Col, Divider, Row } from "antd";
import { ReactComponent as UserIcon } from "../../../../assets/icons/User.svg";
import ShipmentCostDetail from "../../Common/ShipmentCostDetail/ShipmentCostDetail";
import FBMShipmentItemCardManager from "./FBMShipmentCardManager";
import FBMStoragesWithAsin from "./FBMStoragesWithAsin";
import { IFbmOrderResponse } from "../../../../types/FbmOrder";
import AddNoteModal from "../../../Common/Note/AddNoteModal";
import FBMItemUpdateCardManager from "./FBMItemUpdateCardManager";

interface OrderDetailCardProps {
  order: IFbmOrderResponse;
}

const FBMShipmentDetailManager: React.FC<OrderDetailCardProps> = ({
  order,
}) => {
  const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false);
  const [notes, setNotes] = useState(order?.notes || []);

  const showNoteModal = () => {
    setIsNoteModalVisible(true);
  };

  const handleNoteModalClose = () => {
    setIsNoteModalVisible(false);
  };

  const handleNoteAdded = (response: any) => {
    const newNoteObject = {
      notes: response.notes,
      user: response.user,
      createdAt: new Date().toISOString(),
    };

    const updatedNotes = [...notes, newNoteObject];
    setNotes(updatedNotes);
  };

  // const readOnly = order.type === "ORDER" ? true : false;

  // const confirmOrder = () => {
  //   let orderItems: any = [];
  //   let errorCount = 0;

  //   order?.items.map((orderItem: any) => {
  //     let itemServices: any[] = [];

  //     orderItem?.additionalServices.map((servicesItem: any) => {
  //       let serviceItemObj = {
  //         generalServiceId: servicesItem?.id,
  //         // qty: orderItem?.approvalQty,
  //         qty: orderItem?.quantity,
  //       };

  //       itemServices.push(serviceItemObj);
  //     });

  //     // orderItem.binId = 1;

  //     let itemObj = {
  //       orderItemId: orderItem?.id,
  //       qty: orderItem?.quantity,
  //       // binId: orderItem.binId,
  //       orderItemGeneralServices: itemServices,
  //     };
  //     orderItems.push(itemObj);
  //   });

  //   if (errorCount > 0) {
  //     openNotification(
  //       "error",
  //       "Error",
  //       "Select the shelf where the product will be stored"
  //     );
  //     return false;
  //   }

  //   if (orderItems.length === 0) {
  //     openNotification("error", "Error", "Product quantities cannot be zero");
  //     return false;
  //   }

  //   let orderGeneralServices: any = [];

  //   order.items.map((item: any) => {
  //     let orderGeneralService = {
  //       generalServiceId: item.additionalServices.id,
  //       qty: item.qty,
  //     };

  //     orderGeneralServices.push(orderGeneralService);
  //   });

  //   let postObj = {
  //     orderId: order.id,
  //     orderItems: orderItems,
  //     employeeNote: "",
  //     oneamzToken: localStorage.getItem("externalAccessToken"),
  //     orderGeneralServices: orderGeneralServices,
  //   };

  //   OrderService.create(postObj)
  //     .then((response: any) => {
  //       openNotification("success", "Success", "Shipment confirmed");
  //       setTimeout(() => {
  //         window.location.replace(`/order/detail/${order.id}`);
  //       }, 1000);
  //     })
  //     .catch((e: Error) => {});
  // };

  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">User Information</h3>
          </Col>
        </Row>
        <Card className="bg-slate-blue border-radius-md">
          <Row>
            <Col span={4}>
              <UserIcon />
            </Col>
            <Col span={20}>
              <h2 className="font-color-pure-white mt-0 mb-0">
                <b>
                  {order?.user?.firstName} {order?.user?.lastName}
                </b>
              </h2>
              <h2 className="font-color-pure-white mt-0">
                <b>#{order?.user?.id} </b>
              </h2>
              <p className="text font-color-pure-white mt-24 mb-0">
                {order?.user?.phoneNumber}
              </p>
              <p className="text font-color-pure-white mt-0 mb-0">
                {order?.user?.email}
              </p>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24}>
            <Card className="bg-cloud border-radius-md mt-24">
              <Row>
                <Col span={24}>
                  <Row justify={"space-between"} align={"middle"}>
                    <h3 className="text font-color-onyx mt-0">
                      <b>Note</b>
                    </h3>
                    <Button
                      className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-8"
                      onClick={showNoteModal}
                    >
                      Add Note
                    </Button>
                    <AddNoteModal
                      orderId={order?.id}
                      visible={isNoteModalVisible}
                      onClose={handleNoteModalClose}
                      onNoteAdded={handleNoteAdded}
                    />
                  </Row>
                  <Row
                    justify={"space-between"}
                    align={"middle"}
                    className="p-16"
                  >
                    <Row style={{ width: "100%", padding: "0px 0" }}>
                      <Col span={12}>
                        <p className="text font-color-onyx m-0">
                          <strong>Note</strong>
                        </p>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <p className="text font-color-onyx m-0">
                          <strong>Added By</strong>
                        </p>
                      </Col>
                    </Row>
                  </Row>
                  <Divider style={{ margin: "0px 0" }} />
                  <Row
                    justify={"space-between"}
                    align={"middle"}
                    className="mt-16"
                  >
                    {notes.map((note, index) => (
                      <Row
                        key={index}
                        style={{ width: "100%", padding: "8px 0" }}
                      >
                        <Col span={12}>
                          <p className="text font-color-onyx m-0">
                            {note.notes}
                          </p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <p className="text font-color-onyx m-0">
                            {note.user?.firstName} {note.user?.lastName}
                          </p>
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FBMStoragesWithAsin order={order} />
          </Col>
        </Row>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">Package Details</h3>
          </Col>
          <Col span={24}>
            {order?.items.map((item: any, index: any) => (
              <FBMShipmentItemCardManager
                item={item}
                key={index}
                order={order}
                orderItem={item}
              />
            ))}
          </Col>
          <Col span={24}>
            <ShipmentCostDetail order={order} />
          </Col>
          <Col span={24}>
            <FBMItemUpdateCardManager order={order} />
          </Col>
          {/* <Col span={24}>
            {!readOnly && ( 
              <Row justify={"end"} className="mt-24">
                <Popconfirm
                  title="Do you confirm the FBA shipment?"
                  onConfirm={confirmOrder}
                >
                  <Button className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-lg mt-24">
                    Confirm
                  </Button>
                </Popconfirm>
              </Row>
            {/* )}
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default FBMShipmentDetailManager;
