import {
  Col,
  Collapse,
  Row,
  Form,
  Input,
  Alert,
  Select,
  Radio,
  Card,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import OneamzAppService from "../../../../services/OneamzAppService";
import AmazonProductImage from "../../../Common/AmazonProduct/AmazonProductImage";
import AmazonProductInfo from "../../../Common/AmazonProduct/AmazonProductInfo";
import type { InputRef } from "antd";
import type { RadioChangeEvent } from "antd/lib/radio";
import CountriesService from "../../../../services/CountriesService";
import { RuleType } from "rc-field-form/lib/interface";

interface FBMCardProps {
  fbmItem: any;
  changeShipmentFee: (id: any, fee: any) => void;
  changeCustomer: (id: any, data: any) => void;
  changeDeliveryOption: (id: any, data: any) => void;
}

interface InputRefs {
  [key: string]: React.RefObject<InputRef>;
}

const FBMCard: React.FC<FBMCardProps> = ({
  fbmItem,
  changeCustomer,
  changeShipmentFee,
  changeDeliveryOption,
}) => {
  const [countryListData, setCountryListData] = useState([]);
  const [country, setCountry] = useState<string | null>(null);
  const [shipmentOfferLoading, setShipmentOfferLoading] = useState(false);
  const [selectedShipmentOffer, setSelectedShipmentOffer] = useState<
    number | null
  >(null);
  const [shipmentOffers, setShipmentOffers] = useState<any[]>([]);
  const [customerForm] = Form.useForm();

  const countryList = () => {
    const request: any = ``;
    CountriesService.getCountries(request)
      .then((response: any) => {
        const data = response.data.data;
        setCountryListData(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  useEffect(() => {
    countryList();
    // eslint-disable-next-line
  }, []);

  const inputRefs: InputRefs = {
    fullName: useRef<InputRef>(null),
    addressLine1: useRef<InputRef>(null),
    addressLine2: useRef<InputRef>(null),
    state: useRef<InputRef>(null),
    city: useRef<InputRef>(null),
    postCode: useRef<InputRef>(null),
    phoneNumber: useRef<InputRef>(null),
    email: useRef<InputRef>(null),
  };

  useEffect(() => {
    if (fbmItem && fbmItem.customer) {
      customerForm.setFieldsValue({
        fullName: fbmItem.customer.fullName,
        country: fbmItem.customer.country,
        addressLine1: fbmItem.customer.addressLine1,
        addressLine2: fbmItem.customer.addressLine2,
        state: fbmItem.customer.state,
        city: fbmItem.customer.city,
        postCode: fbmItem.customer.postalCode,
        phoneNumber: fbmItem.customer.phoneNumber,
        email: fbmItem.customer.email,
      });
    }
  }, [fbmItem, customerForm]);

  const calcShipmentFee = useCallback(() => {
    if (!country) return;

    setShipmentOfferLoading(true);

    const items = [{ asin: fbmItem.asin, quantity: fbmItem.qty }];
    const options = ["STANDARD", "FEDEX_ECONOMY", "EXPRESS"].map(
      (deliveryOption) => ({
        country,
        deliveryOption,
        productList: items,
      })
    );

    setShipmentOffers([]);

    Promise.all(options.map((opt) => OneamzAppService.calcShipmentFee(opt)))
      .then((responses) => {
        const offers = responses
          .filter((res) => res.data.code === 200)
          .map((res, index) => ({
            deliveryOption: options[index].deliveryOption,
            title: options[index].deliveryOption.replace("FEDEX_", ""),
            shipmentFee: res.data.totalShippingFee,
          }));
        setShipmentOffers(offers);
        setShipmentOfferLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setShipmentOfferLoading(false);
      });
  }, [country, fbmItem.asin, fbmItem.qty]);

  useEffect(() => {
    calcShipmentFee();
  }, [country, calcShipmentFee]);

  const handleShipmentOfferChange = (e: RadioChangeEvent) => {
    const selectedOffer = shipmentOffers[parseInt(e.target.value)];
    setSelectedShipmentOffer(parseInt(e.target.value));
    changeDeliveryOption(fbmItem.id, selectedOffer.deliveryOption);
    changeShipmentFee(fbmItem.id, selectedOffer.shipmentFee);
  };

  // eslint-disable-next-line
  const handleFormChange = (changedValues: any, allValues: any) => {
    changeCustomer(fbmItem.id, allValues);
    const fieldName = Object.keys(changedValues)[0];
    const fieldRef = inputRefs[fieldName];
    if (fieldRef && fieldRef.current) {
      fieldRef.current.focus();
    }
  };

  const getLabel = (key: string) => {
    switch (key) {
      case "fullName":
        return "Name Surname";
      case "addressLine1":
        return "Address 1";
      case "addressLine2":
        return "Address 2";
      case "state":
        return "State";
      case "city":
        return "City";
      case "postCode":
        return "Postal Code";
      case "phoneNumber":
        return "Phone";
      case "email":
        return "Email";
      default:
        return key;
    }
  };

  const getPlaceholder = (key: string) => {
    switch (key) {
      case "fullName":
        return "Enter name and surname";
      case "addressLine1":
        return "Enter address";
      case "addressLine2":
        return "Enter the rest of the address";
      case "state":
        return "Enter state";
      case "city":
        return "Enter city";
      case "postCode":
        return "Enter postal code";
      case "phoneNumber":
        return "Enter phone number";
      case "email":
        return "Enter email";
      default:
        return "";
    }
  };

  const renderHeader = () => {
    return (
      <Row align={"middle"} justify={"space-between"} gutter={[24, 24]}>
        <Col span={10}>
          <Row align={"middle"} justify={"space-between"} gutter={[24, 24]}>
            <Col>
              <AmazonProductImage asin={fbmItem?.asin} />
            </Col>
            <Col span={19}>
              <AmazonProductInfo asin={fbmItem?.asin} title={fbmItem?.name} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <p className="text-sm fs-12 m-0">
                <b>DIMENSIONS</b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                {fbmItem?.length} x {fbmItem?.width} x {fbmItem?.height} in
                <br />
                {fbmItem?.weight} lb
              </p>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <p className="text-sm fs-12 m-0">
                <b>STOCK</b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>{fbmItem?.totalQty}</Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <div className="mr-24">
                <p className="text-sm fs-12 m-0">
                  <b>QUANTITY</b>
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>{fbmItem?.qty}</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderCustomerForm = () => (
    <Form
      form={customerForm}
      layout="vertical"
      onValuesChange={handleFormChange}
    >
      <Row gutter={[16, 16]}>
        {Object.keys(inputRefs).map((key) => (
          <Col md={6} sm={12} xs={12} key={key}>
            <Form.Item
              label={<b>{getLabel(key)}</b>}
              name={key}
              rules={[
                {
                  required: key !== "addressLine2" && key !== "state",
                  message: `${getLabel(key)} is required`,
                },
                ...(key === "phoneNumber"
                  ? [
                      {
                        type: "string" as RuleType,
                        message: "Please enter a valid phone number",
                        pattern: /^(?:\+?\d{1,3})?(?: ?\d{1,4}){3,5}$/,
                        transform: (value: string) => value.replace(/\s/g, ""),
                      },
                    ]
                  : []),
                
                ...(key === "email"
                  ? [
                      {
                        type: "email" as RuleType,
                        message: "Please enter a valid email address",
                      },
                    ]
                  : []),
              ]}
            >
              <Input
                className="amz-input"
                ref={inputRefs[key]}
                placeholder={`${getPlaceholder(key)}`}
              />
            </Form.Item>
          </Col>
        ))}
        <Col md={6} sm={12} xs={12}>
          <Form.Item
            label={<b>Country/Region</b>}
            name="country"
            rules={[
              { required: true, message: "Country selection is required" },
            ]}
          >
            <Select
              className="amz-input"
              placeholder="Select country"
              options={countryListData.map((country: any) => ({
                label: country.name,
                value: country.code,
              }))}
              value={country}
              onChange={setCountry}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const renderCargoForm = () => (
    <>
      <h2 className="title">Cargo Selection</h2>
      <Card loading={shipmentOfferLoading}>
        {country === null ? (
          <Alert
            showIcon
            type="warning"
            description="Select country for shipping offer"
          />
        ) : shipmentOffers.length > 0 ? (
          <Radio.Group
            className="w-full"
            onChange={handleShipmentOfferChange}
            value={
              selectedShipmentOffer !== null
                ? selectedShipmentOffer.toString()
                : ""
            }
          >
            <Row gutter={[24, 24]} justify="space-around">
              {shipmentOffers.map((offer, index) => (
                <Col span={7} key={index}>
                  <Row align="top" justify="space-around">
                    <Col>
                      <Radio
                        className="amz-radio border-slate-blue-02 border-radius-lg"
                        value={index.toString()}
                      />
                      <span>
                        <b>{offer.title}</b>
                      </span>
                    </Col>
                    <Col>
                      <p className="table-title m-0">Total Amount</p>
                      <p className="text fs-12 font-color-slate-blue-05 m-0">
                        ${offer.shipmentFee}
                      </p>
                    </Col>
                  </Row>
                  {/* {index < shipmentOffers.length - 1 && (
                    <Divider
                      type="vertical"
                      style={{
                        height: "35px",
                        borderInlineStartColor: "#c0c0c0",
                      }}
                    />
                  )} */}
                </Col>
              ))}
            </Row>
          </Radio.Group>
        ) : (
          <Alert
            showIcon
            type="warning"
            description="Shipping is not offered to the country of your choice"
          />
        )}
      </Card>
    </>
  );

  return (
    <div>
      <Collapse
        className="antd-order-detail-collapse bg-white border-radius-md mb-24 bg-cloud"
        defaultActiveKey={fbmItem.isCollapsed ? [] : ["1"]}
        expandIconPosition="start"
        expandIcon={({ isActive }) => (
          <i className="fe fe-chevron-down fs-6 text-black" />
        )}
        items={[
          {
            key: "1",
            label: renderHeader(),
            children: (
              <>
                {renderCustomerForm()}
                {renderCargoForm()}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default FBMCard;
