interface WarehousesResponse {
  id?: number;
  countryCode: string;
  type: "W" | "FC";
  name: string;
  code: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
}

interface WarehousesCreateRequest {
  countryCode: string;
  type: "W" | "FC";
  name: string;
  code: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
}

interface WarehousesUpdateRequest {
  countryCode: string;
  type: "W" | "FC";
  name: string;
  code: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;
}

interface WarehousesRequest {
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IWarehousesResponse implements WarehousesResponse {
  id?: number;
  countryCode: string;
  type: "W" | "FC";
  name: string;
  code: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;

  constructor(obj: WarehousesResponse) {
    this.id = obj.id;
    this.countryCode = obj.countryCode;
    this.type = obj.type;
    this.name = obj.name;
    this.code = obj.code;
    this.address = obj.address;
    this.addressLine1 = obj.addressLine1;
    this.addressLine2 = obj.addressLine2;
    this.city = obj.city;
    this.state = obj.state;
    this.postalCode = obj.postalCode;
    this.phone = obj.phone;
  }
}

export class IWarehousesUpdateRequest implements WarehousesUpdateRequest {
  countryCode: string;
  type: "W" | "FC";
  name: string;
  code: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;

  constructor(obj: WarehousesUpdateRequest) {
    this.countryCode = obj.countryCode;
    this.type = obj.type;
    this.name = obj.name;
    this.code = obj.code;
    this.address = obj.address;
    this.addressLine1 = obj.addressLine1;
    this.addressLine2 = obj.addressLine2;
    this.city = obj.city;
    this.state = obj.state;
    this.postalCode = obj.postalCode;
    this.phone = obj.phone;
  }
}

export class IWarehousesCreateRequest implements WarehousesCreateRequest {
  countryCode: string;
  type: "W" | "FC";
  name: string;
  code: string;
  address: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  phone: string;

  constructor(obj: WarehousesCreateRequest) {
    this.countryCode = obj.countryCode;
    this.type = obj.type;
    this.name = obj.name;
    this.code = obj.code;
    this.address = obj.address;
    this.addressLine1 = obj.addressLine1;
    this.addressLine2 = obj.addressLine2;
    this.city = obj.city;
    this.state = obj.state;
    this.postalCode = obj.postalCode;
    this.phone = obj.phone;
  }
}

export class IWarehousesRequest implements WarehousesRequest {
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: WarehousesRequest) {
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search;
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedWarehousesResponse {
  data: WarehousesResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedWarehousesResponse
  implements PaginatedWarehousesResponse
{
  data: IWarehousesResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedWarehousesResponse) {
    this.data = obj.data.map((warehouse) => new IWarehousesResponse(warehouse));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
