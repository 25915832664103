import { Col, Collapse, Row, Input, Divider, Skeleton, Button } from "antd";
import React, { useEffect, useState } from "react";
import AmazonProductImageAvatar from "../../../Common/AmazonProduct/AmazonProductImageAvatar";
import OrderAlert from "../../../Common/OrderAlert/OrderAlert";
import AmazonProductInfo from "../../../Common/AmazonProduct/AmazonProductInfo";
import { formatMoney, openNotification } from "../../../../utils";
import OneamzShippingLabel from "../../../Common/OneamzShippingLabel/OneamzShippingLabel";
import { IFbaOrderResponse } from "../../../../types/FbaOrder";
import FbaOrderItemService from "../../../../services/FbaOrderItemService";
import { IFbaOrderItemUpdateRequest } from "../../../../types/FbaOrderItem";

interface FBAShipmentItemCardManagerProps {
  item: any;
  order: IFbaOrderResponse;
  orderItem: any;
  loading?: boolean;
}
const FBAShipmentItemCardManager: React.FC<FBAShipmentItemCardManagerProps> = ({
  item,
  order,
  orderItem,
  loading,
}) => {
  const [incoming, setIncoming] = useState(0);
  const [damaged, setDamaged] = useState(0);
  const [received, setReceived] = useState(0);
  const mandatoryServices = item.additionalServices.filter(
    (service: any) => service.additionalService.required
  );
  const optionalServices = item.additionalServices.filter(
    (service: any) => !service.additionalService.required
  );

  useEffect(() => {
    if (orderItem) {
      setDamaged(orderItem.damagedQuantity || 0);
      setReceived(orderItem.receivedQuantity || 0);
      calculateMissing(
        orderItem.receivedQuantity || 0,
        orderItem.damagedQuantity || 0,
        orderItem.quantity
      );
    }
  }, [orderItem]);

  const calculateMissing = (
    received: number,
    damaged: number,
    quantity: number
  ) => {
    const calculatedIncoming = quantity - (received + damaged);
    setIncoming(calculatedIncoming > 0 ? calculatedIncoming : 0);
  };

  useEffect(() => {
    calculateMissing(received, damaged, orderItem?.quantity);
  }, [received, damaged, orderItem?.quantity]);

  const handleReceiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setReceived(newValue);
    // updateReceiveQty(orderItem.id, newValue);
  };

  const handleDamageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setDamaged(newValue);
    // updateDamageQty(orderItem.id, newValue);
  };

  // const updateDamageQty = debounce((orderItemId: any, value: any) => {
  //   const quantity = orderItem.quantity;
  //   const newValue = parseInt(value, 10);
  //   const postObj: IFbaOrderItemUpdateRequest = {
  //     damagedQuantity: newValue,
  //   };

  //   if (newValue >= 0 && received + newValue <= quantity) {
  //     FbaOrderItemService.updateFbaOrderItem(order.id, orderItemId, postObj)
  //       .then((response: any) => {
  //         const updatedReceived = response.data.receivedQuantity;
  //         const updatedDamaged = response.data.damagedQuantity;
  //         setReceived(updatedReceived);
  //         setDamaged(updatedDamaged);
  //         openNotification(
  //           "success",
  //           "Success",
  //           "Damaged quantity updated successfully."
  //         );
  //       })
  //       .catch((e: Error) => {
  //         console.error(e);
  //         openNotification(
  //           "error",
  //           "Error",
  //           "Failed to update damaged quantity."
  //         );
  //       });
  //   } else if (!Number.isNaN(newValue)) {
  //     openNotification(
  //       "error",
  //       "Error",
  //       "Damaged quantity exceeds the allowed limit."
  //     );
  //   }
  // }, 100);

  // const updateReceiveQty = debounce((orderItemId: any, value: any) => {
  //   const quantity = orderItem.quantity;
  //   const newValue = parseInt(value, 10);
  //   const postObj: IFbaOrderItemUpdateRequest = {
  //     receivedQuantity: newValue,
  //   };

  //   if (newValue >= 0 && damaged + newValue <= quantity) {
  //     FbaOrderItemService.updateFbaOrderItem(order.id, orderItemId, postObj)
  //       .then((response: any) => {
  //         const updatedReceived = response.data.receivedQuantity;
  //         const updatedDamaged = response.data.damagedQuantity;
  //         setReceived(updatedReceived);
  //         setDamaged(updatedDamaged);
  //         openNotification(
  //           "success",
  //           "Success",
  //           "Received quantity updated successfully."
  //         );
  //       })
  //       .catch((e: Error) => {
  //         console.error(e);
  //         openNotification(
  //           "error",
  //           "Error",
  //           "Failed to update received quantity."
  //         );
  //       });
  //   } else if (!Number.isNaN(newValue)) {
  //     openNotification(
  //       "error",
  //       "Error",
  //       "Received quantity exceeds the allowed limit."
  //     );
  //   }
  // }, 100);

  const handleConfirm = () => {
    const quantity = orderItem.quantity;

    if (received + damaged > quantity) {
      openNotification(
        "error",
        "Error",
        "Received and damaged quantities exceed the total quantity."
      );
      return;
    }
    if (received < 0 || damaged < 0) {
      openNotification(
        "error",
        "Error",
        "Received and damaged quantities cannot be negative."
      );
      return;
    }

    const postObj: IFbaOrderItemUpdateRequest = {
      quantity: orderItem.quantity,
      trackingCode: orderItem.trackingCode,
      receivedQuantity: received,
      damagedQuantity: damaged,
    };

    FbaOrderItemService.updateFbaOrderItem(order.id, orderItem.id, postObj)
      .then((response: any) => {
        const updatedReceived = response.data.receivedQuantity;
        const updatedDamaged = response.data.damagedQuantity;
        setReceived(updatedReceived);
        setDamaged(updatedDamaged);
        window.location.reload();
        openNotification(
          "success",
          "Success",
          "Quantities updated successfully."
        );
      })
      .catch((e: Error) => {
        console.error(e);
        openNotification("error", "Error", "Failed to update quantities.");
      });
  };

  if (loading || !orderItem) {
    return (
      <Skeleton active>
        <Skeleton.Input style={{ width: 200 }} active={true} size="large" />
        <Skeleton paragraph={{ rows: 4 }} active />
      </Skeleton>
    );
  }

  return (
    <>
      <Collapse
        className="antd-order-detail-collapse border-radius-md mb-24"
        expandIconPosition={"end"}
        items={[
          {
            key: "1",
            label: (
              <>
                <Row
                  gutter={[24, 24]}
                  align={"middle"}
                  justify={"space-between"}
                >
                  <Col span={3}>
                    <AmazonProductImageAvatar orderItem={item} />
                  </Col>
                  <Col span={10}>
                    <Row>
                      <AmazonProductInfo
                        asin={item?.product?.asin}
                        title={item?.product?.title}
                      />
                    </Row>
                  </Col>
                  <Col span={8}>
                    <OneamzShippingLabel order={order} orderItem={orderItem} />
                  </Col>
                  <Col className="text-center" span={3}>
                    <OrderAlert order={order} />
                  </Col>
                </Row>
              </>
            ),
            children: (
              <>
                <Row justify={"space-between"} align={"top"}>
                  <Col xl={11}>
                    <h4 className="text mt-0 mb-8">
                      <b>FBA REQUIRED SERVICES</b>
                    </h4>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Service</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Unit Price</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Quantity</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Total</b>
                        </p>
                      </Col>
                    </Row>
                    {mandatoryServices.map((service: any) => (
                      <React.Fragment key={service?.id}>
                        <Row>
                          <Col span={9}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.additionalService?.name}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>
                                {" "}
                                {formatMoney(service?.additionalService?.price)}
                              </b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.quantity}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue mb-8">
                              <b> {formatMoney(service?.price)}</b>
                            </p>
                          </Col>
                        </Row>
                        <Divider className="m-0 mt-8" />
                      </React.Fragment>
                    ))}
                  </Col>

                  <Col xl={11}>
                    <h4 className="text mt-0 mb-8">
                      <b>ADDITIONAL SERVICES</b>
                    </h4>
                    <Row>
                      <Col span={9}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Service</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Unit Price</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Quantity</b>
                        </p>
                      </Col>
                      <Col className="text-center" span={5}>
                        <p className="text-small font-color-slate-blue mb-8">
                          <b>Total</b>
                        </p>
                      </Col>
                    </Row>
                    {optionalServices.map((service: any) => (
                      <React.Fragment key={service?.id}>
                        <Row>
                          <Col span={9}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.additionalService?.name}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>
                                {" "}
                                {formatMoney(service?.additionalService?.price)}
                              </b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue-05 mb-8">
                              <b>{service?.quantity}</b>
                            </p>
                          </Col>
                          <Col className="text-center" span={5}>
                            <p className="text-small font-color-slate-blue mb-8">
                              <b> {formatMoney(service?.price)}</b>
                            </p>
                          </Col>
                        </Row>
                        <Divider className="m-0 mt-8" />
                      </React.Fragment>
                    ))}
                  </Col>
                </Row>
                <Row gutter={[24, 24]} className="mt-24">
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>QUANTITY</b>
                    </p>
                    <Input
                      className="amz-input w-full"
                      placeholder="Quantity"
                      value={Number(orderItem?.quantity)}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>RECEIVED</b>
                    </p>
                    <Input
                      type="number"
                      min={0}
                      className="amz-input w-full"
                      placeholder="Received"
                      value={received}
                      onChange={handleReceiveChange}
                    />
                  </Col>
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b>INCOMING</b>
                    </p>
                    <Input
                      className="amz-input w-full"
                      placeholder="Incoming"
                      value={incoming}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <p className="text-sm fs-12 m-0">
                      <b style={{ color: "red" }}>DAMAGED</b>
                    </p>
                    <Input
                      type="number"
                      min={0}
                      className="amz-input w-full"
                      style={{ color: "red" }}
                      placeholder="Damaged"
                      value={damaged}
                      onChange={handleDamageChange}
                    />
                  </Col>
                </Row>
                <Row justify="end" className="mt-24">
                  <Button
                    className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-lg mt-24"
                    onClick={handleConfirm}
                    loading={loading}
                  >
                    Confirm
                  </Button>
                </Row>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default FBAShipmentItemCardManager;
