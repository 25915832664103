import { Image, Card, Col, Popconfirm, Row, InputNumber } from "antd";
import React, { useState } from "react";
import AmazonProductInfo from "../../../Common/AmazonProduct/AmazonProductInfo";
import AmazonProductImage from "../../../Common/AmazonProduct/AmazonProductImage";
import TrashIcon from "../../../../assets/trash.svg";

interface InventoryShipmentCardProps {
  item: any;
  removeProduct: (asin: any, id: any) => void;
  changeProduct: (item: any) => void;
  fnskuLabel?: any;
  checkStorage: (asin: string, qty: number) => void;
}

const InventoryShipmentCard: React.FC<InventoryShipmentCardProps> = ({
  item,
  changeProduct,
  removeProduct,
  checkStorage
}) => {
  // eslint-disable-next-line
  const [totalFee, setTotalFee] = useState(0);

  const qtyChange = (qty: any) => {
    item.qty = qty;
    changeProduct(item);
    calcTotalFee(item);
    checkStorage(item.asin, qty);
  };

  // const trackingCodeChange = (value: any) => {
  //   console.log(item);
  //   item.trackingCode = value;
  //   changeProduct(item);
  // };

  const calcTotalFee = (item: any) => {
    let total = 0;
    // const updatedServices = item.services.map((service: any) => {
    //   console.log(service);
    //   if (service.isActive || !service.isOptional) {
    //     if (service.isPerUnit) {
    //       total += service.qty * service.fee;
    //     } else {
    //       total += service.fee;
    //     }
    //   }
      setTotalFee(total);
    // });
  };

  // const panelStyle: React.CSSProperties = {};

  // function convertInchesToMeters(inches: number): number {
  //   return inches * 0.0254;
  // }

  // function calculateVolumeInCubicMeters(
  //   depthInInches: number,
  //   widthInInches: number,
  //   heightInInches: number
  // ): number {
  //   let depthInMeters = convertInchesToMeters(depthInInches);
  //   let widthInMeters = convertInchesToMeters(widthInInches);
  //   let heightInMeters = convertInchesToMeters(heightInInches);

  //   let volumeInCubicMeters = depthInMeters * widthInMeters * heightInMeters;

  //   return Math.ceil(volumeInCubicMeters * 100) / 100;
  // }

  return (
    <>
      <Card className="bg-white border-radius-md">
        <Row align={"middle"} justify={"space-between"}>
          <Col xl={8} md={8} xs={24}>
            <Row>
              <Col className="mr-24">
                <AmazonProductImage asin={item?.asin} />
              </Col>
              <Col span={16}>
                <AmazonProductInfo asin={item?.asin} title={item?.name} />
              </Col>
            </Row>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <p className="text-sm fs-12 m-0">
              <b>DIMENSIONS</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {item?.width} X {item?.length} X {item?.height} <b>IN</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {item?.weight} <b>LB</b>
            </p>
          </Col>
          <Col xl={3} md={8} xs={12}>
            <h3 style={{ margin: 0 }}>Quantity</h3>
            <InputNumber
              defaultValue={item?.qty}
              onChange={(value: any) => qtyChange(value)}
              min={1}
              max={9999999}
            />
          </Col>
          <Col xl={3}>
            <Row align={"middle"} justify={"space-around"}>
              <Popconfirm
                title="Should the product be delisted?"
                onConfirm={() => removeProduct(item.asin, item.id)}
                okText="Yes"
                cancelText="No"
              >
                <Image
                  style={{ cursor: "pointer" }}
                  preview={false}
                  src={TrashIcon}
                />
              </Popconfirm>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InventoryShipmentCard;
