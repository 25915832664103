import React, { useState } from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import FbmOrdersNotesService from "../../../services/FbmOrdersNotesService";
import { openNotification } from "../../../utils";
import closeIcon from "../../../assets/XCircle.svg";
import { useLocation } from "react-router-dom";
import FbaOrdersNotesService from "../../../services/FbaOrdersNotesService";

interface AddNoteModalProps {
  orderId: number;
  visible: boolean;
  onClose: () => void;
  onNoteAdded: (newNote: string) => void;
}

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  orderId,
  visible,
  onClose,
  onNoteAdded,
}) => {
  const [noteText, setNoteText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const location = useLocation();

  const handleAddNote = () => {
    if (noteText.trim()) {
      const noteRequest = { notes: noteText };
      setLoading(true);
      if (location.pathname.includes("fbm-order/detail")) {
        FbmOrdersNotesService.createFbmOrderNote(orderId, noteRequest)
          .then((response: any) => {
            openNotification("success", "Success", "Note added successfully");
            onNoteAdded(response.data);
            setNoteText("");
            form.resetFields();
            onClose();
          })
          .catch((error: Error) => {
            console.error("Error adding note:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (location.pathname.includes("fba-order/detail")) {
        FbaOrdersNotesService.createFbaOrderNote(orderId, noteRequest)
          .then((response: any) => {
            openNotification("success", "Success", "Note added successfully");
            onNoteAdded(response.data);
            setNoteText("");
            form.resetFields();
            onClose();
          })
          .catch((error: Error) => {
            console.error("Error adding note:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <>
      <Modal
        title={<>Add Note</>}
        open={visible}
        className="antd-modal-secondary"
        onCancel={onClose}
        footer={null}
        closeIcon={<img src={closeIcon} height={24} alt="close" />}
      >
        <Form form={form} onFinish={handleAddNote} layout="vertical">
          <Form.Item
            name="notes"
            label="Note"
            rules={[
              {
                required: true,
                message: "Please enter a note",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              placeholder="Enter your note here"
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-dark font-color-slate-blue border-slate-blue w-full border-radius-8"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="btn btn-text btn-hover-white font-color-cloud bg-slate-blue w-full border-radius-8"
                  htmlType="submit"
                  loading={loading}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddNoteModal;
