import { Card, Col, Divider, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { FbmOrderItemUpdateResponse } from "../../../../types/FbmOrderItem";
import { formatDateTime } from "../../../../utils";

interface OrderDetailCardProps {
  order: any;
  loading?: boolean;
}

const FBMItemUpdateCardManager: React.FC<OrderDetailCardProps> = ({
  order,
  loading,
}) => {
  // eslint-disable-next-line
  const [updates, setUpdates] = useState<FbmOrderItemUpdateResponse[]>([]);

  useEffect(() => {
    if (order && order.items && order.items[0].inventoryLogs && order.items[0].inventoryLogs[0]?.inventory) {
      setUpdates(
        order.items[0].inventoryLogs[0].inventory.stockLogs as FbmOrderItemUpdateResponse[]
      );
    }
  }, [order]);
  

  if (loading || !order) {
    return (
      <Skeleton active>
        <Skeleton.Input style={{ width: 200 }} active={true} size="large" />
        <Skeleton paragraph={{ rows: 4 }} active />
      </Skeleton>
    );
  }

  return (
    <>
      <Card
        className="border-radius-md w-full p-12 mt-24"
        style={{ overflowX: "auto" }}
      >
        <Row
          style={{
            backgroundColor: "#f5f5f5",
            padding: "4px 16px",
            borderRadius: "8px 8px 0 0",
            minWidth: "450px",
          }}
        >
          <Col flex="1 1 100px">
            <p className="text-sm fs-12">
              <b>DATE</b>
            </p>
          </Col>
          <Col flex="1 1 60px" style={{ textAlign: "center" }}>
            <p className="text-sm fs-12">
              <b>TYPE</b>
            </p>
          </Col>
          <Col flex="1 1 60px" style={{ textAlign: "center" }}>
            <p className="text-sm fs-12">
              <b>QUANTITY</b>
            </p>
          </Col>
          <Col flex="1 1 150px" style={{ textAlign: "end" }}>
            <p className="text-sm fs-12">
              <b>CONFIRMED BY</b>
            </p>
          </Col>
        </Row>
        <Divider className="bg-slate-blue-01 m-0" />
        {updates?.map((update: any, index: number) => (
          <Row
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5",
              padding: "8px 16px",
              minWidth: "450px",
            }}
          >
            <Col flex="1 1 100px">
              <p className="text-sm fs-12">
                <b>{formatDateTime(update?.createdAt)}</b>
              </p>
            </Col>
            <Col flex="1 1 60px">
              <p
                className="text-sm fs-12"
                style={{
                  color: update.type === "ADD" ? "green" : "red",
                  textAlign: "center",
                }}
              >
                <b>{update?.type}</b>
              </p>
            </Col>
            <Col flex="1 1 60px">
              <p
                className="text-sm fs-12"
                style={{
                  color: update.type === "ADD" ? "green" : "red",
                  textAlign: "center",
                }}
              >
                <b>{update?.quantity}</b>
              </p>
            </Col>
            <Col flex="1 1 150px">
              <p className="text-sm fs-12" style={{ textAlign: "end" }}>
                <b>{update?.user?.fullName}</b>
              </p>
            </Col>
          </Row>
        ))}
      </Card>
    </>
  );
};

export default FBMItemUpdateCardManager;
