import http from "../http-common";
import {
  IIncomingInventoryCreateRequest,
  IIncomingInventoryRequest,
  IIncomingInventoryResponse,
  IIncomingInventoryStatusUpdateRequest,
  IIncomingInventoryQuantityUpdateRequest,
  IPaginatedIncomingInventoriesResponse,
} from "../types/IncomingInventory";

const getIncomingInventories = (filterCriteria: IIncomingInventoryRequest) => {
  return http.get<IPaginatedIncomingInventoriesResponse>(
    `/api/v2/incoming-inventories${filterCriteria}`
  );
};

const getIncomingInventoriesById = (id: number) => {
  return http.get<IIncomingInventoryResponse>(
    `/api/v2/incoming-inventories/${id}`
  );
};

const updateIncomingInventoryQuantity = (
  id: number,
  requestBody: IIncomingInventoryQuantityUpdateRequest
) => {
  return http.put<IIncomingInventoryResponse>(
    `/api/v2/incoming-inventories/${id}`,
    requestBody
  );
};

const updateIncomingInventoryStatus = (
  id: number,
  requestBody: IIncomingInventoryStatusUpdateRequest
) => {
  return http.put<IIncomingInventoryResponse>(
    `/api/v2/incoming-inventories/${id}/status`,
    requestBody
  );
};

const createIncomingInventory = (
  requestBody: IIncomingInventoryCreateRequest
) => {
  return http.post<IIncomingInventoryResponse>(
    `/api/v2/incoming-inventories`,
    requestBody
  );
};

const IncomingInventoryService = {
  getIncomingInventories,
  getIncomingInventoriesById,
  updateIncomingInventoryQuantity,
  updateIncomingInventoryStatus,
  createIncomingInventory,
};

export default IncomingInventoryService;
