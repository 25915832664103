import { Card, Col, Row, Image } from "antd";
import FBAQuickAccessImg from "../../../assets/Dashboard/fba-quickaccess.png";
import FBMQuickAccessImg from "../../../assets/Dashboard/fbm-quickaccess.png";
import AddIcon from "../../../assets/Dashboard/add-icon.png";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import OneamzAppService from "../../../services/OneamzAppService";
import { formatMoney } from "../../../utils";

const QuickAccess = () => {
  const [balance, setBalance] = useState<number>();
  const [loading, setLoading] = useState(false);
  const externalToken = localStorage.getItem("externalAccessToken");
  const externalRefreshToken = localStorage.getItem("externalRefreshToken");

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line
  }, []);

  const getBalance = () => {
    setLoading(true);
    OneamzAppService.getBalance()
      .then((response: any) => {
        let CurrentBalance: number = response.data.balance;
        setBalance(CurrentBalance);
        setLoading(false);
      })
      .catch((e: Error) => {});
  };

  return (
    <>
      <Card className="bg-cloud border-radius-md h-full">
        <Row justify={"space-between"}>
          <Col>
            <h3 className="h-3 m-0">Quick Access</h3>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="mt-24" xl={8} sm={12} xs={24}>
            <NavLink
              to={"/fba/new"}
              style={{ pointerEvents: "none", opacity: 0.5 }}
            >
              <Card className="bg-oneamz-yellow border-radius-md h-full pointer">
                <Row justify={"center"}>
                  <h3 className="text fs-24">
                    Create <b>FBA</b> Shipment
                  </h3>
                </Row>
                <Row justify={"center"}>
                  <Image
                    preview={false}
                    className="img-fluid"
                    src={FBAQuickAccessImg}
                  />
                </Row>
              </Card>
            </NavLink>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%) rotate(-53deg)",
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "36px",
                fontWeight: "bold",
                pointerEvents: "none",
                zIndex: 1,
                whiteSpace: "nowrap",
              }}
            >
              Coming Soon
            </div>
          </Col>
          {/* <Col className="mt-24" xl={8} sm={12} xs={24}>
            <Card
              className="bg-oneamz-yellow border-radius-md h-full pointer"
              style={{
                backgroundColor: "rgba(255, 215, 0, 0.7)",
                position: "relative",
                pointerEvents: "none",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(-53deg)",
                  color: "rgba(0, 0, 0, 0.7)",
                  fontSize: "44px",
                  fontWeight: "bold",
                  pointerEvents: "none",
                  zIndex: 1,
                  whiteSpace: "nowrap",
                }}
              >
                Coming Soon
              </div>
              <Row justify={"center"}>
                <h3 className="text fs-24">
                  Create <b>FBA</b> Shipment
                </h3>
              </Row>
              <Row justify={"center"}>
                <Image
                  preview={false}
                  className="img-fluid"
                  src={FBAQuickAccessImg}
                ></Image>
              </Row>
            </Card>
          </Col> */}

          <Col className="mt-24" xl={8} sm={12} xs={24}>
            <NavLink to={"/fbm/new"}>
              <Card className="bg-slate-blue-02 border-radius-md h-full pointer">
                <Row justify={"center"}>
                  <h3 className="text fs-24">
                    Create <b>FBM</b> Shipment
                  </h3>
                </Row>
                <Row justify={"center"}>
                  <Image
                    preview={false}
                    className="img-fluid"
                    src={FBMQuickAccessImg}
                  />
                </Row>
              </Card>
            </NavLink>
          </Col>
          <Col className="mt-24" xl={8} sm={12} xs={24}>
            <NavLink
              target="_blank"
              to={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D100%26openPaymentModal%3Dtrue`}
            >
              <Card
                loading={loading}
                className="bg-pure-white border-radius-md card-h-full h-full pointer pb-48"
              >
                <Row className="h-full">
                  <Col span={24}>
                    <Row align={"top"}>
                      <Image
                        preview={false}
                        height={48}
                        width={48}
                        src={AddIcon}
                      />
                    </Row>
                  </Col>
                  <Col className="h-full" span={24}>
                    <Row className="h-full" align={"bottom"}>
                      <Col>
                        <h3 className="h-3 m-0">{formatMoney(balance)}</h3>
                        <p className="m-0 text fs-14">Total Balance</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </NavLink>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default QuickAccess;
