import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import InventoryItemList from "../../components/Inventory/InventoryItemList";

const InventoryPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InventoryItemList />
        </Col>
      </Row>
    </>
  );
};

export default InventoryPage;
