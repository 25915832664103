import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row, Skeleton } from "antd";
import { openNotification } from "../../utils";
import OrderDetailCard from "../../components/OrderDetail/OrderDetailCard";
import OrderDetailStatus from "../../components/OrderDetail/OrderDetailStatus";
import StorageDetail from "../../components/Inbound/Storage/StorageDetail/StorageDetail";
import StorageDetailManager from "../../components/Inbound/Storage/StorageDetail/StorageDetailManager";
import FBAShipmentDetail from "../../components/Outbound/FBA/FBADetail/FBAShipmentDetail";
import FBAShipmentDetailManager from "../../components/Outbound/FBA/FBADetail/FBAShipmentDetailManager";
import FBMShipmentDetail from "../../components/Outbound/FBM/FBMDetail/FBMShipmentDetail";
import FBMShipmentDetailManager from "../../components/Outbound/FBM/FBMDetail/FBMShipmentDetailManager";
import FbaOrderService from "../../services/FbaOrderService";
import FbmOrderService from "../../services/FbmOrderService";
import IncomingInventoryService from "../../services/IncomingInventoryService";
import { IFbaOrderResponse } from "../../types/FbaOrder";
import { IFbmOrderResponse } from "../../types/FbmOrder";
import { IIncomingInventoryResponse } from "../../types/IncomingInventory";

const OrderDetailPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>();
  // eslint-disable-next-line
  const [status, setStatus] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  let { id } = useParams();
  const location = useLocation();
  let currentRole = localStorage.getItem("userRole");

  const getOrderDetail = (id: number) => {
    setLoading(true);
    if (location.pathname.includes("/fba-order/detail")) {
      setTitle("FBA SHIPMENT DETAIL");
      FbaOrderService.getFbaOrderById(id)
        .then((response) => {
          const orderData = new IFbaOrderResponse(response.data);
          setOrder(orderData);
          setStatus(orderData.status);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/fbm-order/detail")) {
      setTitle("FBM SHIPMENT DETAIL");
      FbmOrderService.getFbmOrderById(id)
        .then((response) => {
          const orderData = new IFbmOrderResponse(response.data);
          setOrder(orderData);
          setStatus(orderData.status);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/storage-order/detail")) {
      setTitle("WAREHOUSE SHIPMENT DETAILS");
      IncomingInventoryService.getIncomingInventoriesById(id)
        .then((response) => {
          const orderData = new IIncomingInventoryResponse(response.data);
          setOrder(orderData);
          setStatus(orderData.status);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const orderId = Number(id);
    if (!isNaN(orderId)) {
      getOrderDetail(orderId);
    }
    // eslint-disable-next-line
  }, [id]);

  const handleStatusChange = (newStatus: string) => {
    setStatus(newStatus);
    setOrder((prevOrder: any) => ({
      ...prevOrder,
      status: newStatus,
    }));
  };

  if (loading || !order) {
    return (
      <Skeleton active>
        <Skeleton.Input style={{ width: 200 }} active={true} size="large" />
        <Skeleton paragraph={{ rows: 4 }} active />
      </Skeleton>
    );
  }

  return (
    <>
      <OrderDetailCard title={title} order={order} />
      <Row className="mt-24">
        <Col span={24}>
          <OrderDetailStatus order={order}/>
        </Col>
      </Row>
      {location.pathname.includes("/storage-order/detail") && (
        <>
          {currentRole === "CUSTOMER" && <StorageDetail order={order} loading={loading}/>}
          {(currentRole === "ADMIN" || currentRole === "WAREHOUSE_MANAGER") && (
            <StorageDetailManager order={order} loading={loading} onStatusChange={handleStatusChange}/>
          )}
        </>
      )}
      {location.pathname.includes("/fba-order/detail") && (
        <>
          {currentRole === "CUSTOMER" && (
            <FBAShipmentDetail order={order} />
          )}
          {(currentRole === "ADMIN" || currentRole === "WAREHOUSE_MANAGER") && (
            <FBAShipmentDetailManager order={order} loading={loading} />
          )}
        </>
      )}
      {location.pathname.includes("/fbm-order/detail") && (
        <>
          {currentRole === "CUSTOMER" && <FBMShipmentDetail order={order} />}
          {(currentRole === "ADMIN" || currentRole === "WAREHOUSE_MANAGER") && (
            <FBMShipmentDetailManager order={order} />
          )}
        </>
      )}
    </>
  );
};

export default OrderDetailPage;
