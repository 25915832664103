interface AdditionalServiceResponse {
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  id?: number;
  name: string;
  type: "FBA" | "FBM";
  price: number;
  required: boolean;
}

interface AdditionalServiceUpdateRequest {
  name: string;
  type: "FBA" | "FBM";
  price: number;
  required: boolean;
}

interface AdditionalServiceCreateRequest {
  name: string;
  type: "FBA" | "FBM";
  price: number;
  required: boolean;
}

interface AdditionalServicesRequest {
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IAdditionalServiceResponse implements AdditionalServiceResponse {
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
  id?: number;
  name: string;
  type: "FBA" | "FBM";
  price: number;
  required: boolean;
  constructor(obj: AdditionalServiceResponse) {
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
    this.createdBy = obj.createdBy;
    this.updatedBy = obj.updatedBy;
    this.id = obj.id;
    this.name = obj.name;
    this.type = obj.type;
    this.price = obj.price;
    this.required = obj.required;
  }
}
export class IAdditionalServiceUpdateRequest
  implements AdditionalServiceUpdateRequest
{
  name: string;
  type: "FBA" | "FBM";
  price: number;
  required: boolean;

  constructor(obj: AdditionalServiceUpdateRequest) {
    this.name = obj.name;
    this.type = obj.type;
    this.price = obj.price;
    this.required = obj.required;
  }
}

export class IAdditionalServiceCreateRequest
  implements AdditionalServiceCreateRequest
{
  name: string;
  type: "FBA" | "FBM";
  price: number;
  required: boolean;

  constructor(obj: AdditionalServiceCreateRequest) {
    this.name = obj.name;
    this.type = obj.type;
    this.price = obj.price;
    this.required = obj.required;
  }
}

export class IAdditionalServicesRequest implements AdditionalServicesRequest {
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: AdditionalServicesRequest) {
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedAdditionalServicesResponse {
  data: AdditionalServiceResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedAdditionalServicesResponse
  implements PaginatedAdditionalServicesResponse
{
  data: IAdditionalServiceResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedAdditionalServicesResponse) {
    this.data = obj.data.map((order) => new IAdditionalServiceResponse(order));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}

interface AdditionalServicesResponse {
  id?: number;
  additionalService: IAdditionalServiceResponse;
  price: number;
  quantity: number;
}

export class IAdditionalServicesResponse implements AdditionalServicesResponse {
  id?: number;
  additionalService: IAdditionalServiceResponse;
  price: number;
  quantity: number;

  constructor(obj: AdditionalServicesResponse) {
    this.id = obj.id;
    this.additionalService = new IAdditionalServiceResponse(
      obj.additionalService
    );
    this.price = obj.price;
    this.quantity = obj.quantity;
  }
}
