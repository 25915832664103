import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import StorageShipmentList from "../../components/Inbound/Storage/StorageShipmentList";

const StoragePage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StorageShipmentList/>
        </Col>
      </Row>
    </>
  );
};

export default StoragePage;
