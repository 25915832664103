import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import type { PaginationProps } from "antd";
import { ColumnProps } from "antd/es/table";
import { NavLink, useLocation } from "react-router-dom";
import { formatDateTime, openNotification } from "../../utils";
import { debounce } from "lodash";
import closeIcon from "../../assets/XCircle.svg";
import AmazonIcon from "../../assets/amazon.svg";
import { SearchOutlined } from "@ant-design/icons";
import { CopyClipboard } from "../Common/CopyClipboard/CopyClipboard";
import AmazonProductImage from "../Common/AmazonProduct/AmazonProductImage";
import AmazonProductInfo from "../Common/AmazonProduct/AmazonProductInfo";
import CheckProductCard from "./CheckProduct/CheckProductCard";
import { IInventoryResponse } from "../../types/Inventory";
import UserService from "../../services/UserService";
import InventoryService from "../../services/InventoryService";

const InventoryItemList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [customerList, setCustomerList] = useState<
    { value: number; label: string }[]
  >([]);
  const [inventoryList, setInventoryList] = useState<IInventoryResponse[]>([]);
  // eslint-disable-next-line
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<number | string>("");
  const [searchText, setSearchText] = useState<string>("");
  // eslint-disable-next-line
  const [storageVolume, setStorageVolume] = useState<any>(0);
  // eslint-disable-next-line
  const [totalStorage, setTotalStorage] = useState(0);
  // eslint-disable-next-line
  const [totalInventory, setTotalInventory] = useState(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const currentRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("id");
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = () => {
    if (currentRole === "CUSTOMER") {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&userId=${userId}&search=${searchText}`;
    } else {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&userId=${selectedCustomer}&search=${searchText}`;
    }
  };
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [isShowDetailModalVisible, setIsShowDetailModalVisible] =
    useState(false);
  const [isStockDetailModalVisible, setIsStockDetailModalVisible] =
    useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState<any>(null);
  // const [selectedProduct, setSelectedProduct] = useState<any>(null);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    newFilterText = newFilterText.replace(
      /(userId=)[^&]*/,
      currentRole === "CUSTOMER" ? `$1${userId}` : `$1${selectedCustomer}`
    );
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [
    currentPage,
    pageSize,
    selectedCustomer,
    searchText,
    sortField,
    sortOrder,
  ]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchText(value);
    }, 500)
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getInventoryList = () => {
    setLoading(true);
    InventoryService.getInventories(filterText)
      .then((response) => {
        const inventories = response.data.data.map(
          (inventory) => new IInventoryResponse(inventory)
        );
        setInventoryList(inventories);
        setTotalOrders(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e);
        openNotification("error", "Error", "Failed to fetch orders");
      })
      .finally(() => setLoading(false));
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getInventoryList();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const getAllUser = () => {
    setLoading(true);
    const customerRequest: any = "?types=CUSTOMER&page=0&size=1000";
    UserService.getAllUser(customerRequest)
      .then((response) => {
        let data = response.data.data;
        const newArray = data?.map((item: any) => {
          return {
            value: item?.id,
            label: `#${item?.id} ${item?.firstName} ${item?.lastName}`,
          };
        });
        setCustomerList(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!customerList?.length && currentRole === "ADMIN") {
      getAllUser();
    }
    getTotalStorageVolume();
    // eslint-disable-next-line
  }, [customerList]);

  const getTotalStorageVolume = () => {
    // InventoryServiceV1.getTotalStorageVolume()
    //   .then((response: any) => {
    //     let data = response.data;
    //     let total = Math.ceil(data);
    //     let percent = (data / total) * 100;
    //     setStorageVolume(percent.toFixed(0));
    //     setTotalStorage(total);
    //   })
    //   .catch((e: Error) => {});
  };

  const showDetailModal = (details: any) => {
    const sortedDetails = details;
    setSelectedItemDetails(sortedDetails);
    setIsShowDetailModalVisible(true);
  };

  // const stockDetailModal = (details: any) => {
  //   const sortedDetails = details;
  //   setSelectedItemDetails(sortedDetails);
  //   setIsStockDetailModalVisible(true);
  // };

  const columns: ColumnProps<IInventoryResponse>[] = [
    {
      title: "Box",
      dataIndex: "box",
      key: "box",
      width: 80,
      sorter: true,
      render: (text, record) => <span>{record?.box?.name}</span>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      sorter: true,
      render: (text) => <span>{formatDateTime(text)}</span>,
    },
    ...(currentRole !== "CUSTOMER"
      ? [
          {
            title: "Customer",
            dataIndex: "user",
            key: "user",
            ellipsis: { showTitle: false },
            width: 150,
            sorter: true,
            render: (text: any, record: any) => (
              <Tooltip
                placement="topLeft"
                title={`${record?.user?.firstName} ${record?.user?.lastName} #${record?.user?.id}`}
              >
                <p style={{ margin: 0 }}>
                  {record?.user?.firstName} {record?.user?.lastName}
                </p>
                <b>#{record?.user?.id}</b>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      title: "Items",
      dataIndex: "product",
      key: "product",
      width: 200,
      render: (text, record) => (
        <Tooltip title={record?.product?.title}>
          <span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <NavLink
                to={`https://www.amazon.com/dp/${record?.product?.asin}`}
                target="_blank"
                style={{ marginRight: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `https://www.amazon.com/dp/${record?.product?.asin}`,
                    "_blank"
                  );
                }}
              >
                <Image
                  style={{
                    borderRadius: "50%",
                    border: "3px solid rgba(51,62,72,0.5)",
                  }}
                  height={"40px"}
                  width={"40px"}
                  preview={false}
                  src={
                    process.env.REACT_APP_API_BASE_URL ===
                    "https://oneship-test-api.oneamz.com"
                      ? AmazonIcon
                      : `https://api.oneamz.com/product-photo/${
                          record?.product?.asin
                        }?Authorization=${localStorage.getItem(
                          "externalAccessToken"
                        )}`
                  }
                />
              </NavLink>
              <CopyClipboard text={record?.product?.asin} />
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: <p style={{ margin: 0, textAlign: "center" }}>Dimensions</p>,
      dataIndex: "product",
      key: "dimensions",
      width: 110,
      render: (text, record) => (
        <span>
          <p style={{ margin: 0, textAlign: "center" }}>
            {record?.product?.length} X {record?.product?.width} X{" "}
            {record?.product?.height} IN
            <p style={{ margin: 0 }}>{record?.product?.weight} LB</p>
          </p>
        </span>
      ),
    },
    {
      title: <p style={{ margin: 0, textAlign: "center" }}>Stock</p>,
      dataIndex: "stock",
      key: "stock",
      width: 80,
      sorter: true,
      render: (text: number, record: any) => (
        <p style={{ margin: 0, textAlign: "center" }}>{text}</p>
      ),
    },
    // {
    //   title: <p style={{ margin: 0, textAlign: "center" }}>Actions</p>,
    //   key: "actions",
    //   width: 150,
    //   render: (record) => (
    //     <div style={{ textAlign: "center" }}>
    //       <Button
    //         className="btn btn-hover-dark bg-cloud font-color-slate-blue border-radius-lg"
    //         style={{ fontWeight: 600 }}
    //         type="primary"
    //         onClick={() => showDetailModal(record)}
    //       >
    //         Show Details
    //       </Button>
    //     </div>
    //   ),
    // },
    {
      title: <p style={{ margin: 0, textAlign: "center" }}>Actions</p>,
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 110,
      render: (text, record) => {
        // const items: MenuProps["items"] = [
        //   {
        //     key: "showDetails",
        //     label: (
        //       <NavLink to={"#"} onClick={() => showDetailModal(record)}>
        //         Show Details
        //       </NavLink>
        //     ),
        //   },
        //   {
        //     key: "stockDetails",
        //     label: (
        //       <NavLink to={"#"} onClick={() => stockDetailModal(record)}>
        //         Stock Details
        //       </NavLink>
        //     ),
        //   },
        // ];
        // return (
        //   <Dropdown placement="topRight" menu={{ items }}>
        //     <EllipsisOutlined
        //       rotate={90}
        //       style={{ fontSize: "24px", cursor: "pointer" }}
        //     />
        //   </Dropdown>
        // );
        return (
          <Button className="border-radius-lg" style={{ fontWeight: 600 }} type="text" onClick={() => showDetailModal(record)}>
            Show Details
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={24}>
        {currentRole === "CUSTOMER" && (
          <Col className="mt-24" xs={24} xl={12}>
            <NavLink to={"/storage/new"}>
              <CheckProductCard />
            </NavLink>
          </Col>
        )}
        {/* <Col xs={24} xl={8}>
          <Card
            className="bg-cloud border-radius-md mt-24"
            style={{ minHeight: 165, padding: "1rem" }}
          >
            <p className="mt-0 text fs-18 font-color-slate-blu mb-0">
              <b>STORAGE AREA</b>
            </p>
            <Progress
              className="progress-slate-blue-2 mt-8"
              percent={100}
              size={["100%", 14]}
              success={{ percent: storageVolume }}
              showInfo={false}
            />
            <p className="text font-color-slate-blue fs-14 mb-0 mt-8">
              The <b>{totalStorage}</b> Storage Box is using{" "}
              <b>{storageVolume > 0 && storageVolume}.</b>
            </p>
          </Card>
        </Col> */}
        <Col
          className="mt-24"
          xl={currentRole === "CUSTOMER" ? 12 : 24}
          xs={24}
        >
          <Card
            className="bg-cloud border-radius-md h-full"
            style={{ minHeight: 165, padding: "1rem" }}
          >
            <Row align={"middle"} gutter={24}>
              {currentRole !== "CUSTOMER" ? (
                <Col className="mt-16" xl={12} xs={24}>
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    className="amz-input w-full"
                    options={[{ value: "", label: "Select" }, ...customerList]}
                    showSearch
                    onChange={(customer) => setSelectedCustomer(customer)}
                    placeholder={"Customer"}
                  />{" "}
                </Col>
              ) : (
                <></>
              )}
              <Col
                className="mt-16"
                xl={currentRole === "CUSTOMER" ? 24 : 12}
                xs={24}
              >
                <Input
                  className="amz-input w-full"
                  placeholder="You can search by ASIN or Product name"
                  prefix={<SearchOutlined className="fs-24 mx-8" />}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <Card loading={loading} className="bg-cloud border-radius-md">
            <Row>
              <Col span={24}>
                <h3 className="h-3 m-0">Inventory List</h3>
              </Col>
              <Col className="antd-card-body-p-0" span={24}>
                <Table
                  loading={loading}
                  rowKey={(record) => record?.id}
                  columns={columns}
                  dataSource={inventoryList}
                  bordered
                  scroll={{ x: 1000 }}
                  pagination={false}
                  onChange={handleTableChange}
                />
                <Row style={{ marginTop: "30px" }} justify={"center"}>
                  <Pagination
                    showSizeChanger={true}
                    total={totalInventory}
                    defaultPageSize={pageSize}
                    defaultCurrent={0}
                    current={currentPage + 1}
                    showQuickJumper
                    onChange={paginationOnChange}
                  />
                </Row>
              </Col>
            </Row>
          </Card>
          <Modal
            title="Product Details"
            open={isShowDetailModalVisible}
            className="antd-modal-secondary "
            onOk={() => setIsShowDetailModalVisible(false)}
            onCancel={() => setIsShowDetailModalVisible(false)}
            footer={null}
            closeIcon={<img src={closeIcon} height={24} alt="close" />}
          >
            <Row>
              <Col span={24}>
                <Card className="border-radius-md w-full p-24 mt-24 antd-card-body-p-0">
                  <Row gutter={24}>
                    <Col span={6}>
                      <AmazonProductImage
                        asin={selectedItemDetails?.product?.asin}
                      />
                    </Col>
                    <Col span={18}>
                      <AmazonProductInfo
                        asin={selectedItemDetails?.product?.asin}
                        title={selectedItemDetails?.product?.title}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="mt-24" justify={"space-between"} gutter={24}>
              <Col>
                <p className="text font-color-slate-blue">
                  <b>Number of Added / Subtracted Products</b>
                </p>
              </Col>
              <Col>
                <p className="text font-color-slate-blue">
                  <b>Updated Date</b>
                </p>
              </Col>
            </Row>
            {selectedItemDetails?.stockLogs?.length ? (
              selectedItemDetails.stockLogs.map((log: any) => (
                <Row justify={"space-between"} key={log?.id}>
                  <Col>
                    <p>
                      {log?.type === "ADD" ? "Added" : "Subtracted"}:{" "}
                      <b
                        className={
                          log?.type === "ADD"
                            ? "font-color-success"
                            : "font-color-error"
                        }
                      >
                        {log?.type === "ADD" ? "+" : "-"}
                        {log?.quantity}
                      </b>
                    </p>
                  </Col>
                  <Col>
                    <p className="text fs-14">
                      {log?.createdAt
                        ? formatDateTime(log?.createdAt)
                        : "No date"}
                    </p>
                  </Col>
                  <Divider className="m-0" />
                </Row>
              ))
            ) : (
              <p>No stock log details available</p>
            )}
            <Row>
              <Col span={24}>
                <Button
                  onClick={() => setIsShowDetailModalVisible(false)}
                  className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-lg mt-24"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal>
          <Modal
            title="Stock Details"
            open={isStockDetailModalVisible}
            className="antd-modal-secondary "
            onOk={() => setIsStockDetailModalVisible(false)}
            onCancel={() => setIsStockDetailModalVisible(false)}
            footer={null}
            closeIcon={<img src={closeIcon} height={24} alt="close" />}
          >
            <Row>
              <Col span={24}>
                <Card className="border-radius-md w-full p-24 mt-24 antd-card-body-p-0">
                  <Row gutter={24}>
                    <Col span={6}>
                      <AmazonProductImage
                        asin={selectedItemDetails?.product?.asin}
                      />
                    </Col>
                    <Col span={18}>
                      <AmazonProductInfo
                        asin={selectedItemDetails?.product?.asin}
                        title={selectedItemDetails?.product?.title}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row
              className="mt-24"
              align={"middle"}
              justify={"space-between"}
              gutter={24}
              style={{ padding: "0 24px" }}
            >
              <Col>
                <p className="text font-color-slate-blue">
                  <b>Box</b>
                </p>
              </Col>
              <Col>
                <p className="text font-color-slate-blue">
                  <b>Stock</b>
                </p>
              </Col>
            </Row>
            {selectedItemDetails?.box ? (
              <Row
                align={"middle"}
                justify={"space-between"}
                gutter={24}
                style={{ padding: "0 24px" }}
              >
                <Col>
                  <p>{selectedItemDetails.box.name || "No Box Name"}</p>
                </Col>
                <Col>
                  <p className="text fs-14">
                    {selectedItemDetails.stock || "No Stock"}
                  </p>
                </Col>
                <Divider className="m-0" />
              </Row>
            ) : (
              <p>No box details available</p>
            )}
            <Row>
              <Col span={24}>
                <Button
                  onClick={() => setIsStockDetailModalVisible(false)}
                  className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud w-full border-radius-lg mt-24"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default InventoryItemList;
