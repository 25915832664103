import { Card, Col, Divider, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import StorageItemCardManager from "./StorageItemCardManager";
import { ReactComponent as UserIcon } from "../../../../assets/icons/User.svg";
import { IIncomingInventoryResponse } from "../../../../types/IncomingInventory";
import StorageItemUpdateCard from "./StorageItemUpdateCard";
import BoxBarcode from "./BoxBarcode";

interface OrderDetailCardProps {
  order: IIncomingInventoryResponse;
  loading?: boolean;
  onStatusChange: (newStatus: string) => void;
}

const StorageDetailManager: React.FC<OrderDetailCardProps> = ({
  order,
  loading,
  onStatusChange,
}) => {
  // eslint-disable-next-line
  const [currentOrder, setCurrentOrder] =
    useState<IIncomingInventoryResponse | null>(order);

  useEffect(() => {
    setCurrentOrder(order);
  }, [order]);

  const onUpdateOrder = (updatedOrder: IIncomingInventoryResponse) => {
    setCurrentOrder((prevOrder) => {
      if (prevOrder?.id === updatedOrder.id) {
        return {
          ...prevOrder,
          ...updatedOrder,
        };
      }
      return prevOrder;
    });
  };

  if (loading || !order) {
    return (
      <Skeleton active>
        <Skeleton.Input style={{ width: 200 }} active={true} size="large" />
        <Skeleton paragraph={{ rows: 4 }} active />
      </Skeleton>
    );
  }

  return (
    <Row justify={"space-between"}>
      <Col xl={8} xs={24}>
        <Row>
          <Col>
            <h3 className="h-3 mt-24">User Information</h3>
          </Col>
        </Row>
        <Card className="bg-slate-blue border-radius-md">
          <Row>
            <Col span={4}>
              <UserIcon />
            </Col>
            <Col span={20}>
              <h2 className="font-color-pure-white mt-0 mb-0">
                <b>
                  {order?.user?.firstName} {order?.user?.lastName}
                </b>
              </h2>
              <h2 className="font-color-pure-white mt-0">
                <b>#{order?.user?.id} </b>
              </h2>
              <p className="text font-color-pure-white mt-24 mb-0">
                {order?.user?.phoneNumber}
              </p>
              <p className="text font-color-pure-white mt-0 mb-0">
                {order?.user?.email}
              </p>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col span={24}>
            <Card className="border-radius-md mt-24 antd-card-body-p-0 p-16" style={{ background: "#f5f5f5",  }}>
              <Row>
                <Col span={24}>
                  <BoxBarcode order={order} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col>
        <Divider type="vertical" className="vertical-divider" />
      </Col>
      <Col xl={15} xs={24}>
        {order && (
          <StorageItemCardManager
            orderItem={order}
            loading={loading}
            onStatusChange={onStatusChange}
            onUpdateOrder={onUpdateOrder}
          />
        )}
        {order && (
          <StorageItemUpdateCard orderItem={order} loading={loading} />
        )}
      </Col>
    </Row>
  );
};

export default StorageDetailManager;
