import http from "../http-common";
import { INotesCreateRequest, INotesResponse, INotesUpdateRequest } from "../types/Notes";

const createFbmOrderNote = (id: number, requestBody: INotesCreateRequest) => {
  return http.post<INotesResponse>(`/api/v2/fbm-orders/${id}/notes`, requestBody);
};

const updateFbmOrderNote = (id: number, noteId: number, requestBody: INotesUpdateRequest) => {
  return http.put<INotesResponse>(`/api/v2/fbm-orders/${id}/notes/${noteId}`, requestBody);
};

const deleteFbmOrderNote = (id: number, noteId: number) => {
  return http.delete<void>(`/api/v2/fbm-orders/${id}/notes/${noteId}`);
};

const FbmOrdersNotesService = {
  createFbmOrderNote,
  updateFbmOrderNote,
  deleteFbmOrderNote,
};

export default FbmOrdersNotesService;
