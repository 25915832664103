import { Button, Col, Divider, InputNumber, Modal, Row, Switch } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { formatMoney } from "../../../../utils";

interface ServiceListProps {
  product: any;
  setQty: (productId: any, serviceId: any, qty: any) => void;
}

const ServiceList: React.FC<ServiceListProps> = ({ product, setQty }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    product.services.forEach((service: any) => {
      if (service?.required === false && service.id !== 1) {
        setQty(product?.id, service?.id, 0);
      }
    });
    setIsModalOpen(false);
  };

  const onChangeFNSKULabel = (
    product: any,
    service: number,
    checked: boolean
  ) => {
    if (checked) {
      setQty(product.id, service, product.qty);
    } else {
      setQty(product.id, service, 0);
    }
  };

  return (
    <>
      <Button
        type="primary"
        className="btn bg-slate-blue font-color-cloud btn-hover-white btn-text border-radius-lg"
        onClick={showModal}
        icon={<PlusOutlined />}
      >
        Add Additional Service
      </Button>
      <Modal
        title="Additional Services"
        onCancel={handleOk}
        footer={[]}
        open={isModalOpen}
        onOk={handleOk}
      >
        <div className="px-16">
          <Row>
            <Col span={9}>
              <p className="text">
                <b>Service Name</b>
              </p>
            </Col>
            <Col span={5} className="text-center">
              <p className="text">
                <b>Unit Price</b>
              </p>
            </Col>
            <Col span={5} className="text-center">
              <p className="text">
                <b>Quantity</b>
              </p>
            </Col>
            <Col span={5} className="text-center">
              <p className="text">
                <b>Total</b>
              </p>
            </Col>
          </Row>
          {product?.services.map((service: any, index: any) =>
            service?.required === false && service?.id !== 1 ? (
              <React.Fragment key={index}>
                <Row align={"middle"}>
                  <Col span={9}>
                    <p className="text">{service?.name}</p>
                  </Col>
                  <Col span={5}>
                    <Row justify={"center"}>
                      <Col>
                        <p className="text">{formatMoney(service.price)}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5}>
                    {service?.id === 1 ? (
                      <>
                        <Switch
                          defaultChecked={service.qty > 0}
                          onChange={(value: any) =>
                            onChangeFNSKULabel(product, service.id, value)
                          }
                        />
                      </>
                    ) : (
                      <p>
                        <InputNumber
                          name="serviceQty"
                          style={{ marginRight: "10px" }}
                          min={0}
                          max={!service?.isPerUnit ? 1 : product?.qty}
                          onChange={(value: any) => {
                            setQty(product?.id, service?.id, value);
                          }}
                          defaultValue={service?.qty}
                        />
                      </p>
                    )}
                  </Col>
                  <Col span={5}>
                    <Row justify={"center"}>
                      <Col>
                        <p className="text">
                          {formatMoney(service?.price * service?.qty)}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider className="m-0" />
              </React.Fragment>
            ) : (
              <></>
            )
          )}
          <Divider />
          <Row justify={"end"} gutter={16}>
            <Col>
              <Button
                onClick={handleCancel}
                className="btn btn-hover-dark btn-text border-radius-lg bg-cloud border-slate-blue font-color-slate-blue"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => setIsModalOpen(false)}
                className="btn btn-hover-white btn-text border-radius-lg bg-slate-blue font-color-cloud"
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ServiceList;
