import { Card, Col, Row } from "antd";
import { ReactComponent as WarehouseIcon } from "../../../assets/AdminDashboard/warehouse-icon-white.svg";

interface TotalProductsProps {
  value: any;
  loading: boolean;
}

const TotalProducts: React.FC<TotalProductsProps> = ({ value, loading }) => {
  return (
    <>
      <Card loading={loading} className="bg-slate-blue border-radius-md h-full">
        <Row justify={"end"}>
          <Col>
            <div className="pt-16 pb-12 pr-16 pl-16 bg-cloud-02 border-radius-lg">
              <WarehouseIcon className="mt-4" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="h-2 mb-0 font-color-cloud">{value}</h2>
            <p className="m-0 text font-color-cloud">
              Total Product in Warehouse
            </p>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TotalProducts;
