import React from "react";
import { ReactComponent as WarningOutlined } from "../../../assets/error-icon.svg";
import { Tooltip } from "antd";

interface AlertProps {
  order: any;
}

const OrderAlert: React.FC<AlertProps> = ({ order }) => {
  const alerts: any[] = [];

  if (!order?.fbaLabel) {
    alerts.push("The FBA label is missing.");
  }

  if (order?.items[0]?.shippingProvider === "AMAZON" && !order?.items[0]?.shippingLabel) {
    alerts.push("Amazon Missing Shipping Label");
  }

  if (!order?.warehouse) {
    alerts.push("Amazon warehouse information missing");
  }

  order?.items?.forEach((item: any, index: number) => {
    if (!item?.fnskLabel) {
      let services = Array.isArray(item?.additionalServices) ? item?.additionalServices : [];
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        if (service?.additionalService?.id === 1 && service?.quantity > 0) {
          let text = `${item?.product?.asin}: The FNSKU label is missing.`;
          const itemExists = alerts.includes(text);
          if (!itemExists) {
            alerts.push(`${item?.product?.asin}: The FNSKU label is missing.`);
          }
        }
      }
    }

    if (!item?.trackingCode) {
      alerts.push(`${item?.product?.asin}: The tracking code is missing.`);
    }

    if (!item?.fnskLabel) {
      alerts.push(`${item?.product?.asin}: The FNSKU label is missing.`);
    }
    
    if (!item.trackingCode) {
      alerts.push(`${item?.product?.asin}: The tracking code is missing.`);
    }
  });

  return (
    <div>
      {alerts.length > 0 ? (
        <Tooltip
          color="black"
          title={alerts.map((alert, index) => (
            <p
              key={index}
              style={{
                color: "pure-white",
                borderBottom: "1px solid rgba(255,255,255,0.3)",
                padding: "0px",
                paddingBottom: "10px",
              }}
            >
              {alert}
            </p>
          ))}
        >
          <WarningOutlined className="wh-24 mt-8" />
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderAlert;
