import { Card, Input, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Bin from "./Bin";
import { IAisleResponse } from "../../../types/Aisle";
import { IShelfRequest, IShelfResponse } from "../../../types/Shelf";
import ShelfService from "../../../services/ShelfService";
import AisleService from "../../../services/AisleService";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface Pane {
  label: string;
  children: React.ReactNode;
  key: string;
  isNew?: boolean; // Yeni oluşturulan sekmeler için işaretçi
}

interface ShelfProps {
  aisleData?: IAisleResponse; // Define the type for floorData
}

const defaultPanes: Pane[] = [];

const Shelf: React.FC<ShelfProps> = ({ aisleData }) => {
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<Pane[]>(defaultPanes);
  const newTabIndex = useRef(0);
  const [newTabName, setNewTabName] = useState("");
  const [editMode, setEditMode] = useState<string | undefined>(undefined);
  const [editingName, setEditingName] = useState("");

  useEffect(() => {
    if (aisleData) {
      getShelves(aisleData.id);
    }
    // eslint-disable-next-line
  }, [aisleData]); // Dependency array with floorData

  const getShelves = (id: any) => {
    if (aisleData) {
      AisleService.get(id) // Assuming you have a method to get aisles by floor id
        .then((response: any) => {
          let data: IShelfResponse[] = response.data.shelves;
          console.log(data);
          const shelfPanes: Pane[] = data.map((shelf) => ({
            label: shelf.name,
            children: <Bin shelfData={shelf} />, // You might want to pass aisle data to Shelf as well
            key: `shelfTab${shelf.id}`,
          }));
          setItems(shelfPanes);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const onTabClick = (key: string) => {
    setEditMode(key);
    const currentPane = items.find((pane) => pane.key === key);
    setEditingName(currentPane ? currentPane.label : "");
  };

  const handleEditConfirm = (key: string | null) => {
    if (key === null) return;

    const currentPane = items.find((pane) => pane.key === key);
    if (!currentPane) return;

    if (currentPane.isNew) {
      // Yeni sekme için addAisle çağrıldığında
      createShelf({ name: editingName }); // API isteği
    } else {
      // Mevcut sekme için editAisle çağrıldığında
      editShelf(parseInt(key.replace("shelfTab", "")), {
        name: editingName,
        aisleId: aisleData?.id,
      });
    }

    const updatedItems = items.map((pane) => {
      if (pane.key === key) {
        return { ...pane, label: editingName, isNew: false };
      }
      return pane;
    });

    setItems(updatedItems);
    setEditMode(undefined);
  };

  const editShelf = (id: number, shelf: IShelfRequest) => {
    ShelfService.update(id, shelf)
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const renderTabBar = (
    props: any,
    DefaultTabBar: React.ComponentType<any>
  ) => {
    return (
      <DefaultTabBar {...props}>
        {(node: React.ReactElement) =>
          node.key === editMode && typeof node.key === "string" ? (
            <Input
              autoFocus
              defaultValue={editingName}
              onBlur={() => handleEditConfirm(node.key as string)}
              onPressEnter={() => handleEditConfirm(node.key as string)}
              onChange={(e) => setEditingName(e.target.value)}
            />
          ) : (
            node
          )
        }
      </DefaultTabBar>
    );
  };

  const addShelf = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newTabLabel = newTabName || `Section ${newTabIndex.current}`;
    setItems([
      ...items,
      {
        label: newTabLabel,
        children: <Bin />,
        key: newActiveKey,
      },
    ]);

    let shelf = {
      name: newTabLabel,
      aisleId: aisleData?.id,
    };
    createShelf(shelf);

    setActiveKey(newActiveKey);
    setNewTabName(""); // Reset the input field after adding
  };

  const createShelf = (shelf: any) => {
    ShelfService.create(shelf)
      .then((response: any) => {
        console.log(response);
        getShelves(aisleData?.id);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const removeShelf = (id: number, targetKey: TargetKey) => {
    ShelfService.remove(id)
      .then((response: any) => {
        const actualKey = typeof targetKey === "string" ? targetKey : null;
        if (actualKey !== null) {
          const targetIndex = items.findIndex((pane) => pane.key === actualKey);
          const newPanes = items.filter((pane) => pane.key !== actualKey);
          if (newPanes.length && actualKey === activeKey) {
            const { key } =
              newPanes[
                targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
              ];
            setActiveKey(key);
          }
          setItems(newPanes);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const remove = (targetKey: TargetKey) => {
    const actualKey = typeof targetKey === "string" ? targetKey : null;

    if (actualKey !== null) {
      const targetIndex = items.findIndex((pane) => pane.key === actualKey);

      if (targetIndex !== -1) {
        const removedShelfId = parseInt(actualKey.replace("shelfTab", ""));
        removeShelf(removedShelfId, targetKey); // Tab kapatıldığında ilgili flooru kaldır
      }
    }
  };

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "add") {
      addShelf();
    } else {
      remove(targetKey);
    }
  };
  return (
    <div>
      <Card>
        <p style={{ color: "green", textDecoration: "underline", margin: 0 }}>
          <b>SECTIONS</b>
        </p>
        <Tabs
          onChange={onChange}
          activeKey={activeKey}
          type="editable-card"
          onEdit={onEdit}
          items={items}
          renderTabBar={renderTabBar}
          onTabClick={onTabClick}
        />
      </Card>
    </div>
  );
};

export default Shelf;
