import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Card,
  Col,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { Table } from "antd/lib";
import { ColumnProps } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import UserBoxService from "../../services/UserBoxService";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { IUserBoxResponse } from "../../types/UserBox";
import { formatDateTime } from "../../utils";

const BoxList = () => {
  const [userBoxList, setUserBoxList] = useState<IUserBoxResponse[]>([]);
  const [totalUserBoxes, setTotalUserBoxes] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("ACTIVE");
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = `?page=${lastCurrentPage}&size=${lastPageSize}&status=${selectedStatus}&search=${searchText}`;
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const currentRole = localStorage.getItem("userRole");

  const statusList = [
    { value: "ACTIVE", label: "Active" },
    { value: "RELEASED", label: "Released" },
    { value: "", label: "All" },
  ];

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(
      /(status=)[^&]*/,
      `$1${selectedStatus}`
    );
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize, selectedStatus, searchText, sortField, sortOrder]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchText(value);
    }, 500)
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getUserBoxes = () => {
    setLoading(true);
    UserBoxService.getUserBoxes(filterText)
      .then((response: any) => {
        const userBoxes = response.data.data.map(
          (userBox: IUserBoxResponse) => new IUserBoxResponse(userBox)
        );
        setUserBoxList(userBoxes || []);
        setTotalUserBoxes(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getUserBoxes();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const columns: ColumnProps<IUserBoxResponse>[] = [
    ...(currentRole !== "CUSTOMER"
      ? [
          {
            title: "Customer",
            dataIndex: "fullName",
            key: "firstName",
            ellipsis: { showTitle: false },
            width: 200,
            sorter: true,
            render: (text: string, record: any) => (
              <Tooltip placement="topLeft" title={`${text}`}>
                <Row align={"middle"} gutter={[8, 8]}>
                  <Avatar size={48}>
                    {record?.user?.firstName?.charAt(0)}
                  </Avatar>
                  <p
                    style={{
                      margin: "0 0 0 8px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <b>{record?.user?.firstName}</b>
                  </p>
                </Row>
              </Tooltip>
            ),
          },
        ]
      : []),
    {
      title: "Box",
      dataIndex: "name",
      key: "name",
      width: 150,
      sorter: true,
      render: (text, record) => <span>{record?.box?.name}</span>,
    },
    {
      title: "Assigned Date",
      dataIndex: "assignedDate",
      key: "assignedDate",
      width: 150,
      align: "center",
      sorter: true,
      render: (text) => <span>{formatDateTime(text)}</span>,
    },
    {
      title: "Released Date",
      dataIndex: "releasedDate",
      key: "releasedDate",
      width: 150,
      align: "center",
      sorter: true,
      render: (text) => <span>{formatDateTime(text)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      align: "center",
      sorter: true,
      render: (text) => (
        <Tag
          color={
            text === "ACTIVE" ? "green" : text === "RELEASED" ? "orange" : "red"
          }
          style={{ textTransform: "capitalize" }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Fill Rate",
      dataIndex: "fillRate",
      key: "fillRate",
      width: 150,
      align: "center",
      sorter: true,
      render: (text, record) => {
        if (record.status === "ACTIVE") return <span>{text}%</span>;
      },
    },
  ];

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} className="mt-24">
          <Card className="bg-cloud border-radius-md h-full">
            <Row align={"middle"} gutter={24}>
              <Col xs={24} md={12}>
                <Input
                  className="amz-input"
                  placeholder="Keyword"
                  prefix={<SearchOutlined className="fs-24 mx-8" />}
                  onChange={handleChange}
                />
              </Col>
              <Col md={12} xs={24}>
                <Select
                  className="amz-input w-full"
                  options={statusList}
                  onChange={(status) => setSelectedStatus(status)}
                  placeholder={"Status"}
                  value={selectedStatus}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card className="bg-cloud border-radius-md mt-24" loading={loading}>
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">Box List</h3>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table
              loading={loading}
              rowKey={(record) => record?.id || ""}
              columns={columns}
              dataSource={userBoxList}
              bordered
              scroll={{ x: 1000 }}
              pagination={false}
              onChange={handleTableChange}
            />
            <Row style={{ marginTop: "30px" }} justify={"center"}>
              <Pagination
                showSizeChanger={true}
                total={totalUserBoxes}
                defaultPageSize={pageSize}
                defaultCurrent={0}
                current={currentPage + 1}
                showQuickJumper
                onChange={paginationOnChange}
              />
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default BoxList;
