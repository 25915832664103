import { Button, Card, Col, Divider, Modal, Radio, Row, Select } from "antd";
import React, { useState } from "react";

interface ProgressCardProps {
  currentStep: number;
}

const FBMProgressCard: React.FC<ProgressCardProps> = ({ currentStep }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const getStepBgColor = (step: number) => {
    return currentStep >= step ? "bg-slate-blue" : "bg-slate-blue-05";
  };

  return (
    <>
      <Modal
        title="Amazon FBA Shipment List"
        onCancel={handleOk} // onCancel da handleOk ile aynı işlevi görecek şekilde ayarlandı
        footer={[]}
        open={isModalOpen}
        onOk={handleOk}
      >
        <p className="text font-color-slate-blue">
          Select Store to Auto Add Your FBA Order
        </p>
        <Select
          options={[
            { value: "cd", label: "Canada" },
            { value: "us", label: "USA" },
            { value: "uk", label: "UK" },
          ]}
          className="border-slate-blue border-radius-8 w-full btn amz-select-input"
          placeholder={
            <p className="text font-color-slate-blue">Choose Your Store</p>
          }
        />
        <Radio.Group className="w-full mt-24">
          <>
            <Row align={"middle"} justify={"space-between"}>
              <Col span={2}>
                <Radio
                  className="amz-radio border-slate-blue-02 border-radius-lg"
                  value="1"
                />
              </Col>
              <Col span={5}>
                <p className="table-title m-0">SHIPMENT ID</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0"> YXU1</p>
              </Col>
              <Col span={9}>
                <p className="table-title m-0">SHIPMENT NAME</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0">
                  {" "}
                  FBA STA (02/01/2024 06:31) - YXU1
                </p>
              </Col>
              <Col span={5}>
                <p className="table-title m-0">SHIPPED WAREHOUSE CODE</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0"> YXU1</p>
              </Col>
              <Col span={3}>
                <p className="table-title m-0">COUNTRY CODE</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0"> CA</p>
              </Col>
            </Row>
            <Divider className="bg-slate-blue-02" />
            <Row align={"middle"} justify={"space-between"}>
              <Col span={2}>
                <Radio
                  className="amz-radio border-slate-blue-02 border-radius-lg"
                  value="2"
                />
              </Col>
              <Col span={5}>
                <p className="table-title m-0">SHIPMENT ID</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0"> YXU1</p>
              </Col>
              <Col span={9}>
                <p className="table-title m-0">SHIPMENT NAME</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0">
                  {" "}
                  FBA STA (02/01/2024 06:31) - YXU1
                </p>
              </Col>
              <Col span={5}>
                <p className="table-title m-0">SHIPPED WAREHOUSE CODE</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0"> YXU1</p>
              </Col>
              <Col span={3}>
                <p className="table-title m-0">COUNTRY CODE</p>
                <p className="text fs-12 font-color-slate-blue-05 m-0"> CA</p>
              </Col>
            </Row>
          </>
        </Radio.Group>
        <Divider className="bg-slate-blue-02" />
        <Row gutter={16}>
          <Col span={12}>
            <Button
              onClick={() => setIsModalOpen(false)}
              className="btn border-slate-blue btn-hover-dark btn-text w-full"
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button className="btn btn-hover-white btn-text w-full bg-slate-blue-05 font-color-cloud">
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
      <Card className="create-fbm-progress-card bg-slate-blue border-radius-md">
        <Row justify={"space-between"} align={"middle"}>
          <Col span={24}>
            <p className="pretitle font-color-oneamz-yellow">
              Grow on Amazon Using OneAMZ Fulfillment App!
            </p>
            {currentStep === 0 && (
              <Row justify={"space-between"}>
                <Col>
                  <div>
                    <h3 className="h-3 font-color-pure-white m-0">
                      Direct Delivery from
                    </h3>
                    <h3 className="h-3 font-color-pure-white mt-0">
                      Warehouse via FBM!
                    </h3>
                  </div>
                </Col>
                {/* <Col>
                  <Button onClick={showModal} className='btn btn-hover-dark btn-text font-color-slate-blue border-radius-lg'>
                    Amazon FBM Gönderileri
                  </Button>
                </Col> */}
              </Row>
            )}
            {currentStep === 1 && (
              <div>
                <h3 className="h-3 font-color-pure-white m-0">
                  Special Service and Cargo{" "}
                </h3>
                <h3 className="h-3 font-color-pure-white mt-0">
                  Company Selection
                </h3>
              </div>
            )}
            <Card>
              <p className="text m-0">
                <b>FBM Shipping Process</b>
              </p>
              <p className="text fs-12 mt-0">
                2 steps
                {currentStep === 0 && " 1 "}
                {currentStep === 1 && " 2 "}
                completed.
              </p>
              <Row gutter={5}>
                {[0, 1].map((step) => (
                  <Col key={step} span={12}>
                    <div
                      className={`height-6 ${getStepBgColor(
                        step
                      )} border-radius-md`}
                    />
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default FBMProgressCard;
