import React from "react";
import { Image } from "antd";
import AmazonIcon from "../../../assets/amazon.svg";

interface AmazonProductImageProps {
  asin: string;
}

const AmazonProductImage: React.FC<AmazonProductImageProps> = ({ asin }) => {
  return (
    <Image
      style={{ borderRadius: "50%", border: "3px solid rgba(51,62,72,0.5)" }}
      height={"90px"}
      width={"90px"}
      src={process.env.REACT_APP_API_BASE_URL === "https://oneship-test-api.oneamz.com" ? AmazonIcon : `https://api.oneamz.com/product-photo/${asin}?Authorization=${localStorage.getItem(
        "externalAccessToken"
      )}`}
    />
  );
};

export default AmazonProductImage;
