import http from "../http-common";
import {
  IPaginatedFbmOrdersResponse,
  IFbmOrderResponse,
  IFbmOrderRequest,
  IFbmOrderStatusUpdateRequest,
  IFbmOrderCreateRequest,
} from "../types/FbmOrder";

const getFbmOrders = (filterCriteria: IFbmOrderRequest) => {
  return http.get<IPaginatedFbmOrdersResponse>(`/api/v2/fbm-orders${filterCriteria}`);
};

const getFbmOrderById = (id: number) => {
  return http.get<IFbmOrderResponse>(`/api/v2/fbm-orders/${id}`);
};

const createFbmOrder = (requestBody: IFbmOrderCreateRequest) => {
  return http.post<IFbmOrderResponse>(`/api/v2/fbm-orders`, requestBody);
};

const updateFbmOrderStatus = (id: number, requestBody: IFbmOrderStatusUpdateRequest) => {
  return http.put<IFbmOrderResponse>(`/api/v2/fbm-orders/${id}/status`, requestBody);
};

const deleteFbmOrderById = (id: number) => {
  return http.delete<IFbmOrderResponse>(`/api/v2/fbm-orders/${id}`);
};

const FbmOrderService = {
  getFbmOrders,
  getFbmOrderById,
  createFbmOrder,
  updateFbmOrderStatus,
  deleteFbmOrderById,
};

export default FbmOrderService;