import http from "../http-common";
import {
  ICountryRequest,
  ICountryResponse,
  IPaginatedCountriesResponse,
} from "../types/Countries";

const getCountries = (filterCriteria: ICountryRequest) => {
  return http.get<IPaginatedCountriesResponse>(
    `/api/v2/countries${filterCriteria}`
  );
};

const getCountriesById = (id: number) => {
  return http.get<ICountryResponse>(`/api/v2/countries/${id}`);
};

const CountriesService = {
  getCountries,
  getCountriesById,
};

export default CountriesService;
