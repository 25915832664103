import { Card, Input, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Shelf from "./Shelf";
import { IFloorResponse } from "../../../types/Floor";
import { IAisleRequest, IAisleResponse } from "../../../types/Aisle";
import AisleService from "../../../services/AisleService";
import FloorService from "../../../services/FloorService";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface Pane {
  label: string;
  children: React.ReactNode;
  key: string;
  isNew?: boolean; // Yeni oluşturulan sekmeler için işaretçi
}

interface AisleProps {
  floorData?: IFloorResponse; // Define the type for floorData
}

const defaultPanes: Pane[] = [];

const Aisle: React.FC<AisleProps> = ({ floorData }) => {
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<Pane[]>(defaultPanes);
  const newTabIndex = useRef(0);
  const [newTabName, setNewTabName] = useState("");
  const [editMode, setEditMode] = useState<string | undefined>(undefined);
  const [editingName, setEditingName] = useState("");

  useEffect(() => {
    if (floorData) {
      getAisles(floorData.id);
    }
    // eslint-disable-next-line
  }, [floorData]); // Dependency array with floorData

  const getAisles = (id: any) => {
    if (floorData) {
      FloorService.get(id) // Assuming you have a method to get aisles by floor id
        .then((response: any) => {
          let data: IAisleResponse[] = response.data.aisles;
          console.log(data);
          const aislePanes: Pane[] = data.map((aisle) => ({
            label: aisle.name,
            children: <Shelf aisleData={aisle} />, // You might want to pass aisle data to Shelf as well
            key: `aisleTab${aisle.id}`,
          }));
          setItems(aislePanes);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const onTabClick = (
    key: string,
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    setEditMode(key);
    const currentPane = items.find((pane) => pane.key === key);
    setEditingName(currentPane ? currentPane.label : "");
  };

  const handleEditConfirm = (key: string | null) => {
    if (key === null) return;

    const currentPane = items.find((pane) => pane.key === key);
    if (!currentPane) return;

    if (currentPane.isNew) {
      // Yeni sekme için addAisle çağrıldığında
      createAisle({ name: editingName }); // API isteği
    } else {
      // Mevcut sekme için editAisle çağrıldığında
      editAisle(parseInt(key.replace("aisleTab", "")), {
        name: editingName,
        floorId: floorData?.id,
      });
    }

    const updatedItems = items.map((pane) => {
      if (pane.key === key) {
        return { ...pane, label: editingName, isNew: false };
      }
      return pane;
    });

    setItems(updatedItems);
    setEditMode(undefined);
  };

  const editAisle = (id: number, aisle: IAisleRequest) => {
    AisleService.update(id, aisle)
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const removeAisle = (id: number) => {
    AisleService.remove(id)
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const renderTabBar = (
    props: any,
    DefaultTabBar: React.ComponentType<any>
  ) => {
    return (
      <DefaultTabBar {...props}>
        {(node: React.ReactElement) =>
          node.key === editMode && typeof node.key === "string" ? (
            <Input
              autoFocus
              defaultValue={editingName}
              onBlur={() => handleEditConfirm(node.key as string)}
              onPressEnter={() => handleEditConfirm(node.key as string)}
              onChange={(e) => setEditingName(e.target.value)}
            />
          ) : (
            node
          )
        }
      </DefaultTabBar>
    );
  };

  const addAisle = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newTabLabel = newTabName || `Corridor ${newTabIndex.current}`;
    setItems([
      ...items,
      {
        label: newTabLabel,
        children: <Shelf />,
        key: newActiveKey,
      },
    ]);

    let aisle = {
      name: newTabLabel,
      floorId: floorData?.id,
    };
    createAisle(aisle);
    setActiveKey(newActiveKey);
    setNewTabName(""); // Reset the input field after adding
  };

  const createAisle = (aisle: any) => {
    AisleService.create(aisle)
      .then((response: any) => {
        console.log(response);
        getAisles(floorData?.id);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const remove = (targetKey: TargetKey) => {
    const actualKey = typeof targetKey === "string" ? targetKey : null;

    if (actualKey !== null) {
      const targetIndex = items.findIndex((pane) => pane.key === actualKey);
      const newPanes = items.filter((pane) => pane.key !== actualKey);

      if (targetIndex !== -1) {
        const removedAisleId = parseInt(actualKey.replace("aisleTab", ""));
        removeAisle(removedAisleId); // Tab kapatıldığında ilgili flooru kaldır
      }

      if (newPanes.length && actualKey === activeKey) {
        const { key } =
          newPanes[
            targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
          ];
        setActiveKey(key);
      }
      setItems(newPanes);
    }
  };

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "add") {
      addAisle();
    } else {
      remove(targetKey);
    }
  };
  return (
    <div>
      <Card>
        <p style={{ margin: 0 }}>
          <b>CORRIDORS</b>
        </p>
        <Tabs
          onChange={onChange}
          activeKey={activeKey}
          type="editable-card"
          onEdit={onEdit}
          items={items}
          renderTabBar={renderTabBar}
          onTabClick={onTabClick}
          tabPosition="left"
        />
      </Card>
    </div>
  );
};

export default Aisle;
