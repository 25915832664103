import React, { useEffect, useState } from "react";
import { Button, Card, Col, Popconfirm, Radio, Row, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { openNotification } from "../../utils";
import { IFbaOrderResponse } from "../../types/FbaOrder";
import { IFbmOrderResponse } from "../../types/FbmOrder";
import FbaOrderService from "../../services/FbaOrderService";
import FbmOrderService from "../../services/FbmOrderService";
import { OrderStatus } from "../../enums/OrderStatus";
import { IIncomingInventoryResponse } from "../../types/IncomingInventory";
import IncomingInventoryService from "../../services/IncomingInventoryService";

interface OrderDetailStatusProps {
  order: IFbaOrderResponse | IFbmOrderResponse | IIncomingInventoryResponse;
}

const OrderDetailStatus: React.FC<OrderDetailStatusProps> = ({ order }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>([]);
  const [cancelVisible, setCancelVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRole = localStorage.getItem("userRole");

  const statusOrder = location.pathname.includes("/storage-order/detail")
    ? ["WAITING", "IN_WAREHOUSE", "COMPLETED"]
    : location.pathname.includes("/fbm-order/detail")
    ? ["WAITING", "COMPLETED", "SHIPPED"]
    : ["WAITING", "IN_WAREHOUSE", "COMPLETED", "SHIPPED"];

  const statusTranslations: any = {
    WAITING: "WAITING",
    IN_WAREHOUSE: "IN WAREHOUSE",
    COMPLETED: "COMPLETED",
    SHIPPED: "SHIPPED",
  };

  const optionStatus: any[] = location.pathname.includes(
    "/storage-order/detail"
  )
    ? [
        { value: "WAITING", label: "WAITING" },
        { value: "IN_WAREHOUSE", label: "IN WAREHOUSE" },
        { value: "COMPLETED", label: "COMPLETED" },
      ]
    : location.pathname.includes("/fbm-order/detail")
    ? [
        { value: "WAITING", label: "WAITING" },
        { value: "COMPLETED", label: "COMPLETED" },
        { value: "SHIPPED", label: "SHIPPED" },
      ]
    : [
        { value: "WAITING", label: "WAITING" },
        { value: "IN_WAREHOUSE", label: "IN WAREHOUSE" },
        { value: "COMPLETED", label: "COMPLETED" },
        { value: "SHIPPED", label: "SHIPPED" },
      ];

  const radioButtonChecker = (status: string) => {
    return value.includes(status);
  };

  useEffect(() => {
    const statusIndex = statusOrder.indexOf(order?.status);
    if (statusIndex !== -1) {
      setValue(statusOrder.slice(0, statusIndex + 1));
    }
    setCancelVisible(
      order?.status !== "COMPLETED" &&
        order?.status !== "SHIPPED" &&
        order?.status !== "CANCELLED"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.status]);

  const confirm = () => {
    setLoading(true);
    if (location.pathname.includes("/fba-order/detail")) {
      FbaOrderService.updateFbaOrderStatus(order.id, {
        status: OrderStatus.CANCELLED,
      })
        .then((response) => {
          navigate("/fba");
          openNotification("success", "Success", "Shipment canceled");
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/fbm-order/detail")) {
      FbmOrderService.updateFbmOrderStatus(order.id, {
        status: OrderStatus.CANCELLED,
      })
        .then((response) => {
          navigate("/fbm");
          openNotification("success", "Success", "Shipment canceled");
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/storage-order/detail")) {
      IncomingInventoryService.updateIncomingInventoryStatus(order.id, {
        status: OrderStatus.CANCELLED,
      })
        .then((response) => {
          navigate("/storage");
          openNotification("success", "Success", "Shipment canceled");
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    }
  };

  const changeStatus = (value: any) => {
    let postObj = {
      status: value,
    };
    setLoading(true);
    if (location.pathname.includes("/fba-order/detail")) {
      FbaOrderService.updateFbaOrderStatus(order?.id, postObj)
        .then((response: any) => {
          openNotification("success", "Success", "Changed shipment status");
          setValue((prevValue) => {
            const newValueIndex = statusOrder.indexOf(value);
            if (newValueIndex !== -1) {
              const newSelectedStatuses = statusOrder.slice(
                0,
                newValueIndex + 1
              );
              return newSelectedStatuses;
            }
            return prevValue;
          });
          order.status = value;
          setCancelVisible(
            value !== "COMPLETED" &&
              value !== "SHIPPED" &&
              value !== "CANCELLED"
          );
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification(
            "error",
            "Error",
            "Failed to change shipment status"
          );
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/fbm-order/detail")) {
      FbmOrderService.updateFbmOrderStatus(order?.id, postObj)
        .then((response: any) => {
          openNotification("success", "Success", "Changed shipment status");
          setValue((prevValue) => {
            const newValueIndex = statusOrder.indexOf(value);
            if (newValueIndex !== -1) {
              const newSelectedStatuses = statusOrder.slice(
                0,
                newValueIndex + 1
              );
              return newSelectedStatuses;
            }
            return prevValue;
          });
          order.status = value;
          setCancelVisible(
            value !== "COMPLETED" &&
              value !== "SHIPPED" &&
              value !== "CANCELLED"
          );
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification(
            "error",
            "Error",
            "Failed to change shipment status"
          );
        })
        .finally(() => setLoading(false));
    } else if (location.pathname.includes("/storage-order/detail")) {
      IncomingInventoryService.updateIncomingInventoryStatus(order?.id, postObj)
        .then((response: any) => {
          openNotification("success", "Success", "Changed shipment status");
          setValue((prevValue) => {
            const newValueIndex = statusOrder.indexOf(value);
            if (newValueIndex !== -1) {
              const newSelectedStatuses = statusOrder.slice(
                0,
                newValueIndex + 1
              );
              return newSelectedStatuses;
            }
            return prevValue;
          });
          order.status = value;
          setCancelVisible(
            value !== "COMPLETED" &&
              value !== "SHIPPED" &&
              value !== "CANCELLED"
          );
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification(
            "error",
            "Error",
            "Failed to change shipment status"
          );
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Card className="border-radius-md bg-slate-blue">
        <Row justify={"space-between"} align={"middle"}>
          {statusOrder.map((status, index) => (
            <Col key={index}>
              <Radio
                className={`cursor-default amz-radio-oneamz-yellow table-title ${
                  value.includes(status)
                    ? "font-color-pure-white"
                    : "font-color-amz-gray"
                }`}
                checked={radioButtonChecker(status)}
                onChange={() => changeStatus(status)}
                disabled
              >
                {statusTranslations[status]}
              </Radio>
            </Col>
          ))}
        </Row>
        <Row justify={"space-between"} align={"middle"}>
          <Col span={12}>
            {currentRole !== "CUSTOMER" &&
            !location.pathname.includes("/storage-order/detail") ? (
              <Select
                className="amz-input mt-12"
                placeholder="Change Status"
                options={optionStatus}
                size="large"
                style={{ width: "50%", minWidth: "185px" }}
                defaultValue={order?.status}
                onChange={(value: any) => {
                  changeStatus(value);
                }}
                disabled={loading}
              />
            ) : null}
          </Col>
          <Col span={12}>
            {(currentRole === "CUSTOMER" && order?.status === "WAITING" &&
              location.pathname.includes("/storage-order/detail") &&              
              "receivedQuantity" in order && order?.receivedQuantity === 0 &&
              "damagedQuantity" in order && order?.damagedQuantity === 0) ||
            (currentRole === "CUSTOMER" && order?.status === "WAITING") ||
            currentRole !== "CUSTOMER" ? (
              <Row justify={"end"}>
                {cancelVisible && (
                  <Popconfirm
                    title="Shipment Cancel"
                    description="Do you want to cancel the shipment?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      className="btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue mt-12"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Popconfirm>
                )}
              </Row>
            ) : null}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default OrderDetailStatus;
