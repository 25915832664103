import http from "../http-common";
import {
  IPaginatedFbaOrdersResponse,
  IFbaOrderResponse,
  IFbaOrderRequest,
  IFbaOrderStatusUpdateRequest,
  IFbaOrderCreateRequest,
} from "../types/FbaOrder";

const getFbaOrders = (filterCriteria: IFbaOrderRequest) => {
  return http.get<IPaginatedFbaOrdersResponse>(`/api/v2/fba-orders${filterCriteria}`);
};

const getFbaOrderById = (id: number) => {
  return http.get<IFbaOrderResponse>(`/api/v2/fba-orders/${id}`);
};

const createFbaOrder = (requestBody: IFbaOrderCreateRequest) => {
  return http.post<IFbaOrderResponse>(`/api/v2/fba-orders`, requestBody);
};

const updateFbaOrderStatus = (id: number, requestBody: IFbaOrderStatusUpdateRequest) => {
  return http.put<IFbaOrderResponse>(`/api/v2/fba-orders/${id}/status`, requestBody);
};

const downloadFbaLabel = (id: number) => {
  return http.get(`/api/v2/fba-orders/${id}/fba-label/download`, { responseType: "blob" });
};

const uploadFbaLabel = (id: number, file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return http.put<IFbaOrderResponse>(
    `/api/v2/fba-orders/${id}/fba-label/upload`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

const FbaOrderService = {
  getFbaOrders,
  getFbaOrderById,
  createFbaOrder,
  updateFbaOrderStatus,
  downloadFbaLabel,
  uploadFbaLabel,
};

export default FbaOrderService;