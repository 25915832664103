import { Col, Row } from "antd";
import { Outlet } from "react-router-dom";
import SettingsStarterCard from "../../components/Common/StarterCard/SettingsStarterCard";
import SettingsRouter from "../../components/Settings/SettingsRouter";


const SettingsPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <SettingsStarterCard />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SettingsRouter />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default SettingsPage;
