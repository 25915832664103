interface ProductResponse {
  asin: string;
  title: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  imageUrl: string | null;
}

export class IProductResponse implements ProductResponse {
  asin: string;
  title: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  imageUrl: string | null;

  constructor(obj: ProductResponse) {
    this.asin = obj.asin;
    this.title = obj.title;
    this.length = obj.length;
    this.width = obj.width;
    this.height = obj.height;
    this.weight = obj.weight;
    this.imageUrl = obj.imageUrl;
  }
}
