import { OrderStatus } from "../enums/OrderStatus";
import { IFbaOrderItemResponse } from "./FbaOrderItem";
import { INotesResponse } from "./Notes";
import { IProductResponse } from "./Product";
import { IUserResponse } from "./User";
import { IWarehousesResponse } from "./Warehouses";

interface FbaOrderResponse {
  id: number;
  user: IUserResponse;
  warehouse: IWarehousesResponse;
  status: OrderStatus;
  items: IFbaOrderItemResponse[];
  createdAt: string;
  notes: INotesResponse[] | null;
  fbaLabel: string | null;
  detailUrl: string;
  orderCode: string;
  printUrl: string;
}

interface FbaOrderStatusUpdateRequest {
  status: OrderStatus;
}

interface FbaOrderCreateRequest {
  userId: number;
  warehouseId: number;
  items: [
    {
      asin: string;
      quantity: number;
      additionalServices?: Array<{ id: number; quantity: number }> | null;
    }
  ];
  notes?: string;
}

interface FbaOrderRequest {
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";
}

export class IFbaOrderResponse implements FbaOrderResponse {
  id: number;
  user: IUserResponse;
  warehouse: IWarehousesResponse;
  status: OrderStatus;
  items: IFbaOrderItemResponse[];
  createdAt: string;
  notes: INotesResponse[] | null;
  fbaLabel: string | null;
  detailUrl: string;
  orderCode: string;
  printUrl: string;

  constructor(obj: FbaOrderResponse) {
    this.id = obj.id;
    this.user = new IUserResponse(obj.user);
    this.warehouse = new IWarehousesResponse(obj.warehouse);
    this.status = obj.status;
    this.items = obj.items.map((item) => ({
      id: item.id,
      product: new IProductResponse(item.product),
      additionalServices: item.additionalServices ?? [],
      quantity: item.quantity,
      fnskLabel: item.fnskLabel,
      shippingLabel: item.shippingLabel,
      shippingProvider: item.shippingProvider,
      trackingCode: item.trackingCode,
      receivedQuantity: item.receivedQuantity,
      damagedQuantity: item.damagedQuantity,
      createdAt: item.createdAt,
    }));
    this.createdAt = obj.createdAt;
    this.notes = obj.notes ?? [];
    this.fbaLabel = obj.fbaLabel ?? "";
    this.detailUrl = `/fba-order/detail/${obj.id}`;
    this.orderCode = `ONESHIP-${obj?.user?.externalUserId}-FBA-${obj?.id}`;
    this.printUrl = `/fba/print/${obj.id}`;
  }
}

export class IFbaOrderStatusUpdateRequest implements FbaOrderStatusUpdateRequest {
  status: OrderStatus;

  constructor(obj: FbaOrderStatusUpdateRequest) {
    this.status = obj.status;
  }
}

export class IFbaOrderCreateRequest implements FbaOrderCreateRequest {
  userId: number;
  warehouseId: number;
  items: [
    {
      asin: string;
      quantity: number;
      additionalServices?: Array<{ id: number; quantity: number }> | null;
    }
  ];
  notes: string;

  constructor(obj: FbaOrderCreateRequest) {
    this.userId = obj.userId;
    this.warehouseId = obj.warehouseId;
    this.items = obj.items;
    this.notes = obj.notes ?? "";
  }
}

export class IFbaOrderRequest implements FbaOrderRequest {
  statuses?: OrderStatus[];
  userId?: number;
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
  search?: string;
  sort?: string;
  direction?: "ASC" | "DESC";

  constructor(obj: FbaOrderRequest) {
    this.statuses = obj.statuses ?? [];
    this.userId = obj.userId;
    this.startDate = obj.startDate;
    this.endDate = obj.endDate;
    this.page = obj.page;
    this.size = obj.size;
    this.search = obj.search ?? "";
    this.sort = obj.sort;
    this.direction = obj.direction;
  }
}

interface PaginatedFbaOrdersResponse {
  data: FbaOrderResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class IPaginatedFbaOrdersResponse implements PaginatedFbaOrdersResponse {
  data: IFbaOrderResponse[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(obj: PaginatedFbaOrdersResponse) {
    this.data = obj.data.map((order) => new IFbaOrderResponse(order));
    this.page = obj.page;
    this.size = obj.size;
    this.totalElements = obj.totalElements;
    this.totalPages = obj.totalPages;
  }
}
