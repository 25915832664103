import http from "../http-common";
import {
  IWarehousesRequest,
  IWarehousesResponse,
  IPaginatedWarehousesResponse,
  IWarehousesCreateRequest,
  IWarehousesUpdateRequest,
} from "../types/Warehouses";

const getWarehouses = (filterCriteria: IWarehousesRequest) => {
  return http.get<IPaginatedWarehousesResponse>(
    `/api/v2/warehouses${filterCriteria}`
  );
};

const getWarehousesById = (id: number) => {
  return http.get<IWarehousesResponse>(
    `/api/v2/warehouses/${id}`
  );
};

const createWarehouse = (requestBody: IWarehousesCreateRequest) => {
  return http.post<IWarehousesResponse>(`/api/v2/warehouses`, requestBody);
};

const updateWarehouse = (id: number, requestBody: IWarehousesUpdateRequest) => {
  return http.put<IWarehousesResponse>(`/api/v2/warehouses/${id}`, requestBody);
}

const deleteWarehouse = (id: number) => {
  return http.delete<IWarehousesResponse>(`/api/v2/warehouses/${id}`);
}

const WarehousesService = {
  getWarehouses,
  getWarehousesById,
  createWarehouse,
  updateWarehouse,
  deleteWarehouse
};

export default WarehousesService;
