import { Card, Col, Input, Row, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Aisle from "./Aisle";
import FloorService from "../../../services/FloorService";
import { IFloorRequest, IFloorResponse } from "../../../types/Floor";
import "./warehouse-management.css";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface Pane {
  label: string;
  children: React.ReactNode;
  key: string;
  isNew?: boolean;
}

const defaultPanes: Pane[] = [];

const WarehouseManagement = () => {
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [items, setItems] = useState<Pane[]>(defaultPanes);
  const newTabIndex = useRef(0);
  const [newTabName, setNewTabName] = useState("");
  const [editMode, setEditMode] = useState<string | undefined>(undefined);
  const [editingName, setEditingName] = useState("");
  const [, setFloors] = useState<IFloorResponse[]>([]);

  useEffect(() => {
    getFloors();
    // eslint-disable-next-line
  }, []);

  const onChange = (key: string) => {
    setActiveKey(key);
    // Burada, seçili taba ait veriyi yükleyip state'e veya context'e aktarabilirsin.
    loadTabData(key);
  };

  const getFloors = () => {
    FloorService.getAll()
      .then((response: any) => {
        let data: IFloorResponse[] = response.data;
        setFloors(data);
        const floorPanes = data.map((floor) => ({
          label: floor.name,
          children: <Aisle floorData={floor} />, // Burada her tab için uygun veriyi aktarıyorsun
          key: `floorTab${floor.id}`,
        }));
        setItems([...defaultPanes, ...floorPanes]);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const onTabClick = (
    key: string,
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    setEditMode(key);
    const currentPane = items.find((pane) => pane.key === key);
    setEditingName(currentPane ? currentPane.label : "");
  };

  const handleEditConfirm = (key: string | null) => {
    if (key === null) return;

    const currentPane = items.find((pane) => pane.key === key);
    if (!currentPane) return;

    if (currentPane.isNew) {
      // Yeni sekme için addFloor çağrıldığında
      createFloor({ name: editingName }); // API isteği
    } else {
      // Mevcut sekme için editFloor çağrıldığında
      editFloor(parseInt(key.replace("floorTab", "")), { name: editingName });
    }

    const updatedItems = items.map((pane) => {
      if (pane.key === key) {
        return { ...pane, label: editingName, isNew: false };
      }
      return pane;
    });

    setItems(updatedItems);
    setEditMode(undefined);
  };

  const renderTabBar = (
    props: any,
    DefaultTabBar: React.ComponentType<any>
  ) => {
    return (
      <DefaultTabBar {...props}>
        {(node: React.ReactElement) =>
          node.key === editMode && typeof node.key === "string" ? (
            <Input
              autoFocus
              defaultValue={editingName}
              onBlur={() => handleEditConfirm(node.key as string)}
              onPressEnter={() => handleEditConfirm(node.key as string)}
              onChange={(e) => setEditingName(e.target.value)}
            />
          ) : (
            node
          )
        }
      </DefaultTabBar>
    );
  };

  const loadTabData = (key: string) => {
    // Örnek: key'e göre API'den veri çekme ve state'e aktarma
    // API'den veri çekme işlemi ve sonrasında state'i güncelleme
    const floorId: any = key.replace("floorTab", "");
    FloorService.get(floorId)
      .then((data) => {
        // Burada elde edilen veriyi ilgili bileşene aktarabilirsin.
      })
      .catch((error) => console.error(error));
  };

  const addFloor = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newTabLabel = newTabName || `Kat ${newTabIndex.current}`;

    setItems([
      ...items,
      {
        label: newTabLabel,
        children: <Aisle />, // Missing floorData prop
        key: newActiveKey,
      },
    ]);
    setActiveKey(newActiveKey);

    setNewTabName("");

    createFloor({ name: newTabLabel })
      .then(() => {
        getFloors(); // Sekmeleri yenilemek için getFloors'u çağır
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createFloor = (floorName: any) => {
    return FloorService.create(floorName); // Promise döndür
  };

  const editFloor = (id: number, floor: IFloorRequest) => {
    FloorService.update(id, floor)
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const removeFloor = (id: number) => {
    FloorService.remove(id)
      .then((response: any) => {
        console.log(response);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const remove = (targetKey: TargetKey) => {
    const actualKey = typeof targetKey === "string" ? targetKey : null;

    if (actualKey !== null) {
      const targetIndex = items.findIndex((pane) => pane.key === actualKey);
      const newPanes = items.filter((pane) => pane.key !== actualKey);

      if (targetIndex !== -1) {
        const removedFloorId = parseInt(actualKey.replace("floorTab", ""));
        removeFloor(removedFloorId); // Tab kapatıldığında ilgili flooru kaldır
      }

      if (newPanes.length && actualKey === activeKey) {
        const { key } =
          newPanes[
            targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
          ];
        setActiveKey(key);
      }
      setItems(newPanes);
    }
  };

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "add") {
      addFloor();
    } else {
      remove(targetKey);
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card style={{ overflowX: "auto" }}>
            <h1>Warehouse Management</h1>
            <p style={{ margin: 0 }}>
              <b>FLOORS</b>
            </p>
            <Tabs
              className="warehouse-management"
              onChange={onChange}
              activeKey={activeKey}
              type="editable-card"
              onEdit={onEdit}
              items={items}
              renderTabBar={renderTabBar}
              onTabClick={onTabClick}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WarehouseManagement;
