import { Button, Card, Col, Input, Popconfirm, Row, Skeleton } from "antd";
import AmazonProductInfo from "../../../Common/AmazonProduct/AmazonProductInfo";
import AmazonProductImageAvatar from "../../../Common/AmazonProduct/AmazonProductImageAvatar";
import { openNotification } from "../../../../utils";
import { useState, useEffect } from "react";
import IncomingInventoryService from "../../../../services/IncomingInventoryService";
import { IIncomingInventoryResponse } from "../../../../types/IncomingInventory";

interface OrderDetailCardProps {
  orderItem: any;
  loading?: boolean;
  onStatusChange: (newStatus: string) => void;
  onUpdateOrder: (updatedOrder: IIncomingInventoryResponse) => void;
}

const StorageItemCardManager: React.FC<OrderDetailCardProps> = ({
  orderItem,
  loading,
  onStatusChange,
  onUpdateOrder,
}) => {
  // eslint-disable-next-line
  const [incoming, setIncoming] = useState<number>(0);
  const [damaged, setDamaged] = useState<number>(0);
  const [received, setReceived] = useState<number>(0);

  useEffect(() => {
    if (orderItem) {
      setIncoming(orderItem.missingQuantity || 0);
    }
  }, [orderItem]);

  const handleReceiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setReceived(newValue);
  };

  const handleDamageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setDamaged(newValue);
  };

  const handleConfirm = () => {
    if (received === 0 && damaged === 0) {
      openNotification(
        "error",
        "Error",
        "Received and damaged quantities must be non-zero."
      );
      return;
    }
    if (received < 0 || damaged < 0) {
      openNotification(
        "error",
        "Error",
        "Received and damaged quantities must be non-negative."
      );
      return;
    }
    if (isNaN(received) || isNaN(damaged)) {
      openNotification(
        "error",
        "Error",
        "Please enter valid numbers for received and damaged quantities."
      );
      return;
    }
    if (received > orderItem.quantity) {
      openNotification(
        "error",
        "Error",
        "Received quantity cannot exceed the total quantity."
      );
      return;
    }
    if (damaged > orderItem.quantity) {
      openNotification(
        "error",
        "Error",
        "Damaged quantity cannot exceed the total quantity."
      );
      return;
    }

    IncomingInventoryService.updateIncomingInventoryQuantity(orderItem.id, {
      receivedQuantity: received,
      damagedQuantity: damaged,
    })
      .then((response: any) => {
        const updatedOrder = {
          ...orderItem,
          updates: response.data.updates,
        };
        setIncoming(response.data.missingQuantity);
        onStatusChange(response.data.status);
        onUpdateOrder(updatedOrder);
        window.location.reload();
        openNotification(
          "success",
          "Success",
          "Quantities updated successfully."
        );
      })
      .catch((e: any) => {
        console.error(e);
        if (
          e.response.data.message ===
          "Total quantity cannot be greater than the original quantity"
        ) {
          openNotification(
            "error",
            "Error",
            "Total quantity cannot be greater than the original quantity"
          );
        } else {
          openNotification("error", "Error", "Failed to update quantities.");
        }
      });
  };

  if (loading || !orderItem) {
    return (
      <Skeleton active>
        <Skeleton.Input style={{ width: 200 }} active={true} size="large" />
        <Skeleton paragraph={{ rows: 4 }} active />
      </Skeleton>
    );
  }

  return (
    <>
      <Card className="border-radius-md w-full p-24 mt-24">
        <Row align={"top"} justify={"start"}>
          <Col xl={16} md={8} xs={24}>
            <Row>
              <Col className="mr-24">
                <AmazonProductImageAvatar orderItem={orderItem} />
              </Col>
              <Col span={16}>
                <AmazonProductInfo
                  asin={orderItem?.product?.asin}
                  title={orderItem?.product?.title}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text-sm fs-12 m-0">
              <b>QUANTITY</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {orderItem?.quantity}
            </p>
          </Col>
          <Col xl={4} md={8} xs={12}>
            <p className="text-sm fs-12 m-0">
              <b>DIMENSIONS</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {orderItem?.product?.width} X {orderItem?.product?.length} X{" "}
              {orderItem?.product?.height} <b>IN</b>
            </p>
            <p className="text-sm fs-12 font-color-slate-blue-06 m-0">
              {orderItem?.product?.weight} <b>LB</b>
            </p>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className="mt-24">
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>QUANTITY</b>
            </p>
            <Input
              className="amz-input w-full"
              placeholder="Quantity"
              value={Number(orderItem?.quantity)}
              disabled
            />
          </Col>
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>RECEIVED</b>
            </p>
            <Input
              type="number"
              min={0}
              className="amz-input w-full"
              placeholder="Received"
              onChange={handleReceiveChange}
              disabled={orderItem?.status === "COMPLETED"}
            />
          </Col>
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b>INCOMING</b>
            </p>
            <Input
              className="amz-input w-full"
              placeholder="Incoming"
              value={Number(orderItem?.missingQuantity)}
              disabled
            />
          </Col>
          <Col span={12}>
            <p className="text-sm fs-12 m-0">
              <b style={{ color: "red" }}>DAMAGED</b>
            </p>
            <Input
              type="number"
              min={0}
              className="amz-input w-full"
              style={{ color: "red" }}
              placeholder="Damaged"
              defaultValue={0}
              onChange={handleDamageChange}
              disabled={orderItem?.status === "COMPLETED"}
            />
          </Col>
        </Row>
        <Row justify="end" className="mt-24">
          <Popconfirm
            title="Are you sure to confirm this action?"
            onConfirm={handleConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-lg mt-24"
              loading={loading}
              disabled={orderItem?.status === "COMPLETED"}
            >
              Confirm
            </Button>
          </Popconfirm>
        </Row>
      </Card>
    </>
  );
};

export default StorageItemCardManager;
