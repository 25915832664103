import http from "../http-common";
import {
  IReportRequest,
  IReportResponse,
} from "../types/Report";

const getDailyReport = (type: string, filterCriteria: IReportRequest) => {
    const { startDate, endDate } = filterCriteria;
    const params = new URLSearchParams({
      startDate: startDate || '',
      endDate: endDate || '',
    });
    return http.get<IReportResponse>(
      `/api/v2/reports/daily/${type}?${params.toString()}`
    );
  };
  

const ReportService = {
  getDailyReport,
};

export default ReportService;
