import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  InputNumber,
  Popconfirm,
  Empty,
  Collapse,
  Divider,
  Alert,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatMoney, openNotification } from "../../../../utils";
import InventoryService from "../../../../services/InventoryService";
import ServiceList from "./ServiceList";
import AmazonProductInfo from "../../../Common/AmazonProduct/AmazonProductInfo";
import AmazonProductImage from "../../../Common/AmazonProduct/AmazonProductImage";

interface FBMCreateStepProps {
  addProduct: (data: any) => void;
  removeProduct: (id: any) => void;
  setQty: (id: any, qty: any) => void;
  setServiceQty: (productId: any, serviceId: any, qty: any) => void;
  productList: [];
  totalQty: 0;
}

const FBMCreateStep1: React.FC<FBMCreateStepProps> = ({
  addProduct,
  removeProduct,
  setQty,
  setServiceQty,
  productList,
  totalQty,
}) => {
  const [productLoad, setProductLoad] = useState(false);
  const [asinFind] = Form.useForm();
  const userId = localStorage.getItem("id");

  const handleSubmit = (values: any) => {
    const request: any = `?userId=${userId}&search=${values.asin}`;
    setProductLoad(true);
    InventoryService.getInventories(request)
      .then((response: any) => {
        let data: any = response.data.data;
        const totalStock = data.reduce(
          (acc: number, item: any) => acc + item.stock,
          0
        );

        const product: any = {
          ...data[0].product,
          totalQty: totalStock,
          id: data[0].id,
        };
        addProduct(product);
        setProductLoad(false);
        asinFind.resetFields();
      })
      .catch((e: Error) => {
        setProductLoad(false);
        openNotification(
          "warning",
          "Not found",
          "The product is not available in your inventory"
        );
      });
  };

  return (
    <>
      <Card className="border-radius-md border-slate-blue-02 mt-24">
        <h3 className="h-3 font-color-slate-blue mt-0">
          Add Products from Inventory with ASIN Code!
        </h3>
        <p className="text fs-14">
          Delivered directly from the warehouse using the FBM (Fulfillment by
          Merchant) method, the products offer a fast and reliable shopping
          experience. Each product is selected and carefully packaged in
          accordance with quality standards, so that it reaches customers on
          time and without any problems.
        </p>
        <Form form={asinFind} onFinish={handleSubmit}>
          <Row className="mt-24">
            <Col>
              <Form.Item
                className="mb-0"
                name="asin"
                rules={[
                  {
                    required: true,
                    message: "Enter ASIN code",
                  },
                ]}
              >
                <Input
                  className="amz-input-44px"
                  placeholder="ASIN"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                loading={productLoad}
                className="ml-24 btn btn-hover-dark bg-slate-blue font-color-slate-blue btn-text font-color-cloud"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Alert
        className="border-none border-radius-md mt-24"
        description={
          <p className="text fs-14 m-0">
            {" "}
            The shipping payment amount is calculated according to the
            measurement information received from Amazon. When the package
            arrives at the warehouse, there may sometimes be differences between
            the measurements. Due to the differences that will occur, the user
            will be notified to pay for the extra shipping fee.
          </p>
        }
        type="info"
        showIcon
      />
      <h3 className="h-3 font-color-slate-blue mt-24 mb-24">
        Order Information
      </h3>
      <Row justify={"center"}>
        <Col span={24}>
          <Card className="bg-cloud border-radius-md">
            {productList.map((item: any, key) => (
              <Collapse
                key={key}
                className="antd-order-detail-collapse bg-white border-radius-md mb-24 bg-cloud"
                expandIconPosition={"end"}
                items={[
                  {
                    key: "1",
                    label: (
                      <>
                        <Row
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          align={"middle"}
                          justify={"space-between"}
                          gutter={[24, 24]}
                        >
                          <Col span={10}>
                            <Row
                              align={"middle"}
                              justify={"space-between"}
                              gutter={[24, 24]}
                            >
                              <Col>
                                <AmazonProductImage asin={item?.asin} />
                              </Col>
                              <Col span={19}>
                                <AmazonProductInfo
                                  asin={item?.asin}
                                  title={item?.name}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <p className="text-sm fs-12 m-0">
                                  <b>DIMENSIONS</b>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p className="mt-8 text-sm fs-12 font-color-slate-blue-06 mb-0">
                                  {item?.length} x {item?.width} x{" "}
                                  {item?.height} in
                                  <br />
                                  {item?.weight} lb
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <p className="text-sm fs-12 m-0">
                                  <b>STOCK</b>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>{item?.totalQty}</Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <p className="text-sm fs-12 m-0">
                                  <b>QUANTITY</b>
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <InputNumber
                                  min={1}
                                  onChange={(value: any) => {
                                    if (value > item?.totalQty) {
                                      openNotification(
                                        "error",
                                        "Error",
                                        "The quantity cannot be greater than the stock!"
                                      );
                                      setQty(item?.id, item?.totalQty);
                                    } else {
                                      setQty(item?.id, value);
                                    }
                                  }}
                                  defaultValue={item?.qty}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col style={{ textAlign: "right" }}>
                            <ServiceList
                              setQty={setServiceQty}
                              product={item}
                            />
                          </Col>
                          <Col>
                            <Popconfirm
                              title="Should the product be delisted?"
                              onConfirm={() => removeProduct(item.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <DeleteOutlined className="cursor-pointer icon-size-20 mr-16" />
                            </Popconfirm>
                          </Col>
                        </Row>
                      </>
                    ),
                    children: (
                      <React.Fragment key={key}>
                        <Row justify={"space-between"} align={"top"}>
                          <Col xl={12}>
                            <h4 className="text mt-0 mb-8">
                              <b>FBM REQUIRED SERVICES</b>
                            </h4>
                            <Row>
                              <Col span={9}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Service</b>
                                </p>
                              </Col>
                              <Col className="text-center" span={5}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Unit Price</b>
                                </p>
                              </Col>
                              <Col className="text-center" span={5}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Quantity</b>
                                </p>
                              </Col>
                              <Col className="text-center" span={5}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Total</b>
                                </p>
                              </Col>
                            </Row>
                            {item?.services.map(
                              (service: any) =>
                                service?.qty > 0 &&
                                service?.required === true && (
                                  <>
                                    <Row key={service?.id}>
                                      <Col span={9}>
                                        <p className="text-small font-color-slate-blue-05 mb-8">
                                          <b>{service?.name}</b>
                                        </p>
                                      </Col>
                                      <Col className="text-center" span={5}>
                                        <p className="text-small font-color-slate-blue-05 mb-8">
                                          <b> {formatMoney(service?.price)}</b>
                                        </p>
                                      </Col>
                                      <Col className="text-center" span={5}>
                                        <p className="text-small font-color-slate-blue-05 mb-8">
                                          <b>{service?.qty}</b>
                                        </p>
                                      </Col>
                                      <Col className="text-center" span={5}>
                                        <p className="text-small font-color-slate-blue mb-8">
                                          <b>
                                            {" "}
                                            {formatMoney(
                                              service?.qty * service?.price
                                            )}
                                          </b>
                                        </p>
                                      </Col>
                                    </Row>
                                    <Divider className="m-0 mt-8" />
                                  </>
                                )
                            )}
                          </Col>
                          <Col xl={12}>
                            <h4 className="text mt-0 mb-8">
                              <b>FBM ADDITIONAL SERVICES</b>
                            </h4>
                            <Row>
                              <Col span={9}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Service</b>
                                </p>
                              </Col>
                              <Col className="text-center" span={5}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Unit Price</b>
                                </p>
                              </Col>
                              <Col className="text-center" span={5}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Quantity</b>
                                </p>
                              </Col>
                              <Col className="text-center" span={5}>
                                <p className="text-small font-color-slate-blue mb-8">
                                  <b>Total</b>
                                </p>
                              </Col>
                            </Row>
                            {item?.services.map(
                              (service: any) =>
                                service?.qty > 0 &&
                                service?.required === false && (
                                  <>
                                    <Row key={service?.id}>
                                      <Col span={9}>
                                        <p className="text-small font-color-slate-blue-05 mb-8">
                                          <b>{service?.name}</b>
                                        </p>
                                      </Col>
                                      <Col className="text-center" span={5}>
                                        <p className="text-small font-color-slate-blue-05 mb-8">
                                          <b> {formatMoney(service?.price)}</b>
                                        </p>
                                      </Col>
                                      <Col className="text-center" span={5}>
                                        <p className="text-small font-color-slate-blue-05 mb-8">
                                          <b>{service?.qty}</b>
                                        </p>
                                      </Col>
                                      <Col className="text-center" span={5}>
                                        <p className="text-small font-color-slate-blue mb-8">
                                          <b>
                                            {" "}
                                            {formatMoney(
                                              service?.qty * service?.price
                                            )}
                                          </b>
                                        </p>
                                      </Col>
                                    </Row>
                                    <Divider className="m-0 mt-8" />
                                  </>
                                )
                            )}
                          </Col>
                        </Row>
                      </React.Fragment>
                    ),
                  },
                ]}
              />
            ))}
            {productList.length === 0 ? (
              <Empty
                description="Add product"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ) : (
              <></>
            )}
            <Row justify={"end"}>
              <Col span={6}>
                <p className="text-align-right font-color-slate-blue">
                  <b>Total Product Quantity: {totalQty}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default FBMCreateStep1;
