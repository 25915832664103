import { Input, Form, Button, Tooltip } from "antd";
import React, { useState } from "react";
import { openNotification } from "../../../../utils";
import { SaveOutlined } from "@ant-design/icons";
import FbaOrderItemService from "../../../../services/FbaOrderItemService";
import { IFbaOrderItemUpdateRequest } from "../../../../types/FbaOrderItem";

interface TrackingCodeUpdateProps {
  order: any;
  orderItem: any;
}

const TrackingCodeUpdate: React.FC<TrackingCodeUpdateProps> = ({
  order,
  orderItem,
}) => {
  const [trackingCode] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: { trackingCode: string }) => {
    setLoading(true);
    const postObj: IFbaOrderItemUpdateRequest = {
      trackingCode: values.trackingCode,
    }

    FbaOrderItemService.updateFbaOrderItem(order.id, order.items[0].id, postObj)
      .then((response: any) => {
        setLoading(false);
        openNotification("success", "Success", "Cargo Tracking Code saved");
      })
      .catch((e: Error) => {
        setLoading(false);
        openNotification("error", "Warning", "Cargo Tracking Code not saved");
      });
  };

  const initialTrackingCode =
    order?.items.map((item: any) => item.trackingCode) || [];
    
  return (
    <>
      <p className="text-small mb-0 font-color-slate-blue-05 mt-16">
        <Tooltip title={orderItem?.product?.title}>
          *{orderItem?.product?.asin} - Enter Tracking Code for Product
        </Tooltip>
      </p>
      <Form form={trackingCode} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="trackingCode"
          rules={[
            {
              required: true,
              message: "Enter Cargo Tracking Code",
            },
          ]}
        >
          <Input.Search
            size="large"
            loading={loading}
            defaultValue={initialTrackingCode[0]}
            placeholder="Tracking Code"
            enterButton={
              <Button
                loading={loading}
                size="large"
                className="bg-slate-blue font-color-cloud"
                htmlType="submit"
              >
                <SaveOutlined />
              </Button>
            }
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default TrackingCodeUpdate;
