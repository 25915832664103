import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import BoxList from "../../components/Box/BoxList";

const BoxPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard/>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BoxList/>
        </Col>
      </Row>
    </>
  );
};

export default BoxPage;
