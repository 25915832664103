import { Col, Row } from "antd";
import SolutionCenterStarterCard from "../../components/Common/StarterCard/SolutionCenterStarterCard";
import TicketList from "../../components/SolutionCenter/TicketList";

const SolutionCenterPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <SolutionCenterStarterCard/>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <TicketList/>
        </Col>
      </Row>
    </>
  );
};

export default SolutionCenterPage;
