import { Col, Row, Tooltip, Upload } from "antd";
import React, { useEffect, useState } from "react";
import type { UploadFile, UploadProps } from "antd/es/upload/interface";
import { openNotification } from "../../../../utils";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as UploadIcon } from "../../../../assets/V2/OrderDetailV2/upload.svg";

interface ShippingLabelProps {
  order: any;
}

const ShippingLabel: React.FC<ShippingLabelProps> = ({ order }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploaded, setUploaded] = useState(false);
  const [shippingLabel, setShippingLabel] = useState(null);

  const getShippingLabel = (id: any) => {
    // if (order?.type === "CLAIM_ORDER") {
    //   ClaimOrderService.getShippingLabel(id)
    //     .then((response: any) => {
    //       const pdfBlob = new Blob([response.data], {
    //         type: "application/pdf",
    //       });
    //       const pdfUrl = URL.createObjectURL(pdfBlob);

    //       // Yeni bir yazdırma penceresi oluştur
    //       const printWindow = window.open(pdfUrl);
    //       if (printWindow) {
    //         printWindow.onload = () => {
    //           printWindow.print(); // PDF yüklendikten sonra yazdır
    //           URL.revokeObjectURL(pdfUrl); // Kullanılmayan URL'yi temizle
    //         };
    //       }
    //     })
    //     .catch((e: Error) => {});
    // } else {
    // OrderService.getShippingLabel(id)
    //   .then((response: any) => {
    //     const pdfBlob = new Blob([response.data], {
    //       type: "application/pdf",
    //     });
    //     const pdfUrl = URL.createObjectURL(pdfBlob);

    //     // Yeni bir yazdırma penceresi oluştur
    //     const printWindow = window.open(pdfUrl);
    //     if (printWindow) {
    //       printWindow.onload = () => {
    //         printWindow.print(); // PDF yüklendikten sonra yazdır
    //         URL.revokeObjectURL(pdfUrl); // Kullanılmayan URL'yi temizle
    //       };
    //     }
    //   })
    //   .catch((e: Error) => {});
  };
  // };

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification("warning", "Warning", "You can upload only one file");
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: ".pdf", // Accept only PDF files
    multiple: false, // Disable multiple file selection
  };

  useEffect(() => {
    // if (fileList.length > 0) {
    //   if (order?.id === undefined) {
    //     FileUploadService.uploadCargoLabel(order?.id, fileList[0])
    //       .then((response: any) => {
    //         openNotification(
    //           "success",
    //           "Success",
    //           "Amazon uploaded shipping label"
    //         );
    //         setUploaded(true);
    //       })
    //       .catch((e: Error) => {
    //         console.log(e);
    //         openNotification(
    //           "warning",
    //           "Warning",
    //           "Amazon failed to upload shipping label"
    //         );
    //       });
    //   } else {
    //     FileUploadService.uploadOrderCargoLabel(order?.id, fileList[0])
    //       .then((response: any) => {
    //         openNotification(
    //           "success",
    //           "Success",
    //           "Amazon uploaded shipping label"
    //         );
    //         setUploaded(true);
    //       })
    //       .catch((e: Error) => {
    //         console.log(e);
    //         openNotification(
    //           "warning",
    //           "Warning",
    //           "Amazon failed to upload shipping label"
    //         );
    //       });
    //   }
    // }
    // eslint-disable-next-line
  }, [fileList]);

  useEffect(() => {
    const shippingLabels =
      order?.items.map((item: any) => item.shippingLabel) || [];
    setShippingLabel(shippingLabels);
    // eslint-disable-next-line
  }, [order]);

  return (
    <>
      {shippingLabel !== null || uploaded ? (
        <Row justify={"start"} align={"middle"}>
          <Col xl={2}>
            <UploadIcon className="pointer" />
          </Col>
          <Col xl={20}>
            <p
              className="link mb-0 pointer"
              onClick={() => getShippingLabel(order?.id)}
            >
              Amazon Show Shipping Label
            </p>
            <p className="text fs-12 mt-0 font-color-success">
              Amazon Shipping Label Uploaded
            </p>
          </Col>
          <Col>
            <Tooltip title="Amazon Delete Shipping Label">
              <DeleteOutlined
                onClick={() => {
                  setUploaded(false);
                  setShippingLabel(null);
                  setFileList([]);
                }}
                className="cursor-pointer icon-size-20"
                style={{ marginLeft: "5px" }}
              />
            </Tooltip>
          </Col>
        </Row>
      ) : (
        <Upload {...props}>
          <Row gutter={[16, 16]} justify={"start"} align={"middle"}>
            <Col span={4}>
              <UploadIcon className="pointer" />
            </Col>
            <Col span={20}>
              <p className="link mb-0 pointer">Upload Amazon Shipping Label</p>
              <p className="text fs-12 mt-0 font-color-error">
                Amazon Waiting for Shipping Label
              </p>
            </Col>
          </Row>
        </Upload>
      )}
    </>
  );
};

export default ShippingLabel;
