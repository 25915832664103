export enum OrderStatus {
  WAITING = "WAITING",
  IN_WAREHOUSE = "IN_WAREHOUSE",
  SHIPPED = "SHIPPED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export const OrderStatusDetails: {
  [key in OrderStatus]: { label: string; color: string };
} = {
  [OrderStatus.WAITING]: { label: "Waiting", color: "orange" },
  [OrderStatus.IN_WAREHOUSE]: { label: "In Warehouse", color: "purple" },
  [OrderStatus.CANCELLED]: { label: "Cancelled", color: "red" },
  [OrderStatus.COMPLETED]: { label: "Completed", color: "blue" },
  [OrderStatus.SHIPPED]: { label: "Shipped", color: "green" },
};
