import { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Popconfirm,
  Input,
  Card,
  Pagination,
  PaginationProps,
  MenuProps,
  Button,
  Dropdown,
} from "antd";
import CreateWarehouse from "./CreateWarehouse";
import WarehouseDetail from "./WarehouseDetail";
import Table, { ColumnsType } from "antd/es/table";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { IWarehousesResponse } from "../../../types/Warehouses";
import WarehousesService from "../../../services/WarehousesService";
import { openNotification } from "../../../utils";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import StarterCard from "../../Common/StarterCard/StarterCard";

const AmazonFBAWarehouses = () => {
  const [warehouseList, setWarehouseList] = useState<IWarehousesResponse[]>([]);
  const [totalWarehouses, setTotalWarehouses] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = `?page=${lastCurrentPage}&size=${lastPageSize}&search=${searchText}`;
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);

  const currentRole = localStorage.getItem("userRole");

  const WarehouseCodes: Record<string, string> = {
    W: "Warehouse",
    FC: "Fulfillment Center",
  };

  function getWarehouseTypeName(code: string): string {
    return WarehouseCodes[code] || "Unknown Warehouse Type";
  }

  const countryCodes: Record<string, string> = {
    CA: "Canada",
    MX: "Mexico",
    US: "US",
    CZ: "Czech Republic",
    FR: "France",
    DE: "Germany",
    IT: "Italy",
    PL: "Poland",
    ES: "Spain",
    GB: "UK",
    IN: "India",
    JP: "Japan",
    SG: "Singapore",
    AE: "UAE",
    AU: "Australia",
  };

  function getCountryName(code: string): string {
    return countryCodes[code] || "Unknown Country";
  }

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(/(search=)[^&]*/, `$1${searchText}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize, searchText, sortField, sortOrder]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      setSearchText(value);
    }, 500)
  ).current;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch(value);
  };

  const getWarehouses = () => {
    setLoading(true);
    WarehousesService.getWarehouses(filterText)
      .then((response: any) => {
        const warehouses = response.data.data.map(
          (warehouse: IWarehousesResponse) => new IWarehousesResponse(warehouse)
        );
        setWarehouseList(warehouses || []);
        setTotalWarehouses(response.data.totalElements);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getWarehouses();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const deleteWarehouse = (id: any) => {
    setLoading(true);
    WarehousesService.deleteWarehouse(id)
      .then((response: any) => {
        openNotification("success", "Success", "Warehouse Deleted");
        getWarehouses();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const showDetailModal = (warehouse: any) => {
    setSelectedWarehouse(warehouse);
    setIsModalVisible(true);
  };

  const columns: ColumnsType<IWarehousesResponse> = [
    {
      title: "Country",
      dataIndex: "countryCode",
      key: "countryCode",
      render: (_, row) => (
        <>
          <b>{getCountryName(row?.countryCode)}</b>
        </>
      ),
    },
    {
      title: "Warehouse Type",
      dataIndex: "type",
      key: "type",
      render: (_, row) => (
        <>
          <b>{getWarehouseTypeName(row?.type)}</b>
        </>
      ),
    },
    {
      title: "Warehouse Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 30,
      render: (text, record) => {
        const items: MenuProps["items"] = [
          {
            key: "detail",
            label: (
              <Button
                type="default"
                className="font-color-slate-blue btn-text"
                onClick={() => showDetailModal(record)}
              >
                Detail
              </Button>
            ),
          },
          {
            key: "delete",
            label: (
              <Popconfirm
                title="Delete Warehouse"
                description="Are you sure you want to delete the warehouse?"
                onConfirm={() => deleteWarehouse(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  danger
                  className="font-color-slate-blue btn-text"
                  loading={loading}
                >
                  Delete
                </Button>
              </Popconfirm>
            ),
          },
        ];
        return currentRole === "ADMIN" ? (
          <Dropdown placement="topRight" menu={{ items }}>
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </Dropdown>
        ) : currentRole === "CUSTOMER" ? (
          <Button
            type="default"
            className="font-color-slate-blue btn-text"
            onClick={() => showDetailModal(record)}
          >
            Detail
          </Button>
        ) : null;
      },
    },
  ];

  return (
    <>
      {currentRole === "CUSTOMER" && (
        <Row style={{ marginBottom: "24px" }}>
          <Col span={24}>
            <StarterCard />
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Card loading={loading} className="bg-cloud border-radius-md">
            <Row className="mb-24" align={"middle"} justify={"space-between"}>
              <Col span={8}>
                <Input
                  className="amz-input"
                  placeholder="Keyword"
                  prefix={<SearchOutlined className="fs-24 mx-8" />}
                  onChange={handleChange}
                />
              </Col>
              <Col span={5}>
                <CreateWarehouse onHandleWarehouseList={getWarehouses} />
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Table
                  loading={loading}
                  rowKey={(record) => record?.id || ""}
                  columns={columns}
                  dataSource={warehouseList}
                  bordered
                  scroll={{ x: 1000 }}
                  pagination={false}
                  onChange={handleTableChange}
                />
                <Row style={{ marginTop: "30px" }} justify={"center"}>
                  <Pagination
                    showSizeChanger={true}
                    total={totalWarehouses}
                    defaultPageSize={pageSize}
                    defaultCurrent={0}
                    current={currentPage + 1}
                    showQuickJumper
                    onChange={paginationOnChange}
                  />
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {isModalVisible && (
        <WarehouseDetail
          warehouse={selectedWarehouse}
          getWarehouses={getWarehouses}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};

export default AmazonFBAWarehouses;
