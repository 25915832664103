import { Card, Col, Divider, Row, Skeleton } from "antd";
import { useState, useEffect } from "react";
import { formatDateTime } from "../../../../utils";
import { IncomingInventoryUpdateResponse } from "../../../../types/IncomingInventory";

interface OrderDetailCardProps {
  orderItem: any;
  loading?: boolean;
}

const StorageItemUpdateCard: React.FC<OrderDetailCardProps> = ({
  orderItem,
  loading,
}) => {
  const [updates, setUpdates] = useState<IncomingInventoryUpdateResponse[]>([]);
  const currentRole = localStorage.getItem("userRole");

  useEffect(() => {
    if (orderItem) {
      setUpdates(orderItem.updates as IncomingInventoryUpdateResponse[]);
    }
  }, [orderItem]);

  if (loading || !orderItem) {
    return (
      <Skeleton active>
        <Skeleton.Input style={{ width: 200 }} active={true} size="large" />
        <Skeleton paragraph={{ rows: 4 }} active />
      </Skeleton>
    );
  }

  return (
    <>
      <Card
        className="border-radius-md w-full p-24 mt-24"
        style={{ overflowX: "auto" }}
      >
        <Row
          style={{
            backgroundColor: "#f5f5f5",
            padding: "4px 16px",
            borderRadius: "8px 8px 0 0",
            minWidth: "450px",
          }}
        >
          <Col flex={currentRole === "CUSTOMER" ? "1 1 200px" : "1 1 150px"}>
            <p className="text-sm fs-12">
              <b>DATE</b>
            </p>
          </Col>
          <Col
            flex={currentRole === "CUSTOMER" ? "1 1 125px" : "1 1 100px"}
            style={{ textAlign: "center" }}
          >
            <p className="text-sm fs-12">
              <b>RECEIVED</b>
            </p>
          </Col>
          <Col
            flex={currentRole === "CUSTOMER" ? "1 1 125px" : "1 1 100px"}
            style={{ textAlign: "center" }}
          >
            <p className="text-sm fs-12">
              <b>DAMAGED</b>
            </p>
          </Col>
          {currentRole !== "CUSTOMER" && (
            <Col flex="1 1 150px" style={{ textAlign: "end" }}>
              <p className="text-sm fs-12">
                <b>CONFIRMED BY</b>
              </p>
            </Col>
          )}
        </Row>
        <Divider className="bg-slate-blue-01 m-0" />
        {updates?.map((update: any, index: number) => (
          <Row
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#f5f5f5",
              padding: "8px 16px",
              minWidth: "450px",
            }}
          >
            <Col flex={currentRole === "CUSTOMER" ? "1 1 200px" : "1 1 150px"}>
              <p className="text-sm fs-12">
                <b>{formatDateTime(update?.createdAt)}</b>
              </p>
            </Col>
            <Col flex={currentRole === "CUSTOMER" ? "1 1 125px" : "1 1 100px"}>
              <p
                className="text-sm fs-12"
                style={{ color: "green", textAlign: "center" }}
              >
                <b>{update?.receivedQuantity}</b>
              </p>
            </Col>
            <Col flex={currentRole === "CUSTOMER" ? "1 1 125px" : "1 1 100px"}>
              <p
                className="text-sm fs-12"
                style={{ color: "red", textAlign: "center" }}
              >
                <b>{update?.damagedQuantity}</b>
              </p>
            </Col>
            {currentRole !== "CUSTOMER" && (
              <Col flex="1 1 150px">
                <p className="text-sm fs-12" style={{ textAlign: "end" }}>
                  <b>{update?.user?.fullName}</b>
                </p>
              </Col>
            )}
          </Row>
        ))}
      </Card>
    </>
  );
};

export default StorageItemUpdateCard;
