import http from "../http-common";
import {
  IAdditionalServicesRequest,
  IAdditionalServiceResponse,
  IPaginatedAdditionalServicesResponse,
  IAdditionalServiceCreateRequest,
  IAdditionalServiceUpdateRequest,
} from "../types/AdditionalServices";

const getAdditionalServices = (filterCriteria: IAdditionalServicesRequest) => {
  return http.get<IPaginatedAdditionalServicesResponse>(
    `/api/v2/additional-services${filterCriteria}`
  );
};

const getAdditionalServicesById = (id: number) => {
  return http.get<IAdditionalServiceResponse>(
    `/api/v2/additional-services/${id}`
  );
};

const createAdditionalService = (requestBody: IAdditionalServiceCreateRequest) => {
  return http.post<IAdditionalServiceResponse>(`/api/v2/additional-services`, requestBody);
};

const updateAdditionalService = (id: number, requestBody: IAdditionalServiceUpdateRequest) => {
  return http.put<IAdditionalServiceResponse>(`/api/v2/additional-services/${id}`, requestBody);
}

const deleteAdditionalService = (id: number) => {
  return http.delete<IAdditionalServiceResponse>(`/api/v2/additional-services/${id}`);
}

const AdditionalServiceService = {
  getAdditionalServices,
  getAdditionalServicesById,
  createAdditionalService,
  updateAdditionalService,
  deleteAdditionalService
};

export default AdditionalServiceService;
