import { useEffect, useState } from "react";
import {
  Card,
  Dropdown,
  Image,
  Row,
  Table,
  Tag,
  Tooltip,
  Pagination,
  Button,
  Popconfirm,
  Col,
  Tabs,
} from "antd";
import type { MenuProps, PaginationProps, TabsProps } from "antd";
import { ColumnProps } from "antd/es/table";
import { EllipsisOutlined } from "@ant-design/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { formatDateTime, openNotification } from "../../../utils";
import { CopyClipboard } from "../../Common/CopyClipboard/CopyClipboard";
import FbaOrderService from "../../../services/FbaOrderService";
import FbmOrderService from "../../../services/FbmOrderService";
import { IFbaOrderResponse } from "../../../types/FbaOrder";
import { IFbaOrderItemResponse } from "../../../types/FbaOrderItem";
import { IFbmOrderResponse } from "../../../types/FbmOrder";
import { IFbmOrderItemResponse } from "../../../types/FbmOrderItem";
import { OrderStatus, OrderStatusDetails } from "../../../enums/OrderStatus";
import IncomingInventoryService from "../../../services/IncomingInventoryService";
import { IIncomingInventoryResponse } from "../../../types/IncomingInventory";
import AmazonIcon from "../../../assets/amazon.svg";

const ShipmentList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [fbaOrderList, setFbaOrderList] = useState<IFbaOrderResponse[]>([]);
  const [fbmOrderList, setFbmOrderList] = useState<IFbmOrderResponse[]>([]);
  const [storageOrderList, setStorageOrderList] = useState<
    IIncomingInventoryResponse[]
  >([]);
  // eslint-disable-next-line
  const [orderStatus, setOrderStatus] = useState<OrderStatus>(
    "WAITING" as OrderStatus
  );
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [sortField, setSortField] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const currentRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("id");
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 10;
  const lastFilterText = () => {
    if (currentRole === "CUSTOMER") {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&statuses=${orderStatus}&userId=${userId}`;
    } else {
      return `?page=${lastCurrentPage}&size=${lastPageSize}&statuses=${orderStatus}`;
    }
  };
  const [filterText, setFilterText] = useState<any>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);

  const defaultOption = { value: "", label: "Select", color: "default" };

  const statusColors = {
    "": defaultOption.color,
    ...Object.keys(OrderStatusDetails).reduce((acc, key) => {
      acc[key as OrderStatus] = OrderStatusDetails[key as OrderStatus].color;
      return acc;
    }, {} as Record<OrderStatus, string>),
  };

  const statusLabels = {
    "": defaultOption.label,
    ...Object.keys(OrderStatusDetails).reduce((acc, key) => {
      acc[key as OrderStatus] = OrderStatusDetails[key as OrderStatus].label;
      return acc;
    }, {} as Record<OrderStatus, string>),
  };

  const items: TabsProps["items"] = [
    { key: "1", label: "FBA" },
    { key: "2", label: "FBM" },
    { key: "3", label: "STORAGE" },
  ];

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (activeKey === "3") {
      setOrderStatus("WAITING,IN_WAREHOUSE" as OrderStatus);
    } else {
      setOrderStatus("WAITING" as OrderStatus);
    }
    // eslint-disable-next-line
  }, [activeKey]);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    newFilterText = newFilterText.replace(
      /(statuses=)[^&]*/,
      `$1${orderStatus}`
    );
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [activeKey, currentPage, pageSize, orderStatus, sortField, sortOrder]);

  const getOrderList = () => {
    setLoading(true);
    if (activeKey === "1") {
      FbaOrderService.getFbaOrders(filterText)
        .then((response) => {
          const orderList = (response.data?.data ?? []).map(
            (order) => new IFbaOrderResponse(order)
          );
          setFbaOrderList(orderList);
          setTotalOrders(response.data?.totalElements ?? 0);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (activeKey === "2") {
      FbmOrderService.getFbmOrders(filterText)
        .then((response) => {
          const orderList = (response.data?.data ?? []).map(
            (order) => new IFbmOrderResponse(order)
          );
          setFbmOrderList(orderList);
          setTotalOrders(response.data?.totalElements ?? 0);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (activeKey === "3") {
      IncomingInventoryService.getIncomingInventories(filterText)
        .then((response) => {
          const orderList = (response.data?.data ?? []).map(
            (order) => new IIncomingInventoryResponse(order)
          );
          setStorageOrderList(orderList);
          setTotalOrders(response.data?.totalElements ?? 0);
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "ASC" : order === "descend" ? "DESC" : undefined;
    const sortParamRegex = /(&sort=[^&]*)|(&direction=[^&]*)/g;
    let newFilterText = filterText.replace(sortParamRegex, "");
    if (field && direction) {
      newFilterText += `&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    getOrderList();
    // eslint-disable-next-line
  }, [activeKey, filterText, sortField, sortOrder]);

  const handleCancel = (id: number) => {
    setLoading(true);
    if (activeKey === "1") {
      FbaOrderService.updateFbaOrderStatus(id, {
        status: OrderStatus.CANCELLED,
      })
        .then((response) => {
          getOrderList();
          openNotification("success", "Success", "Order has been cancelled");
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (activeKey === "2") {
      FbmOrderService.updateFbmOrderStatus(id, {
        status: OrderStatus.CANCELLED,
      })
        .then((response) => {
          getOrderList();
          openNotification("success", "Success", "Order has been cancelled");
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    } else if (activeKey === "3") {
      IncomingInventoryService.updateIncomingInventoryStatus(id, {
        status: OrderStatus.CANCELLED,
      })
        .then((response) => {
          getOrderList();
          openNotification("success", "Success", "Order has been cancelled");
        })
        .catch((e: Error) => {
          console.log(e);
          openNotification("error", "Error", "Something went wrong");
        })
        .finally(() => setLoading(false));
    }
  };

  const columnsFbaFbm: ColumnProps<IFbaOrderResponse | IFbmOrderResponse>[] = [
    {
      title: "Order Code",
      dataIndex: "orderCode",
      key: "orderCode",
      width: 200,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{text}</span>
        </NavLink>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 110,
      align: "center",
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{formatDateTime(text)}</span>
        </NavLink>
      ),
    },
    ...(currentRole !== "CUSTOMER"
      ? [
          {
            title: "Customer",
            dataIndex: "user",
            key: "user",
            ellipsis: { showTitle: false },
            width: 150,
            sorter: true,
            render: (text: any, record: any) => (
              <NavLink to={record?.detailUrl} style={{ color: "black" }}>
                <Tooltip
                  placement="topLeft"
                  title={`${record?.user?.firstName} ${record?.user?.lastName} #${record?.user?.id}`}
                >
                  <p style={{ margin: 0 }}>
                    {record?.user?.firstName} {record?.user?.lastName}
                  </p>
                  <b>#{record?.user?.id}</b>
                </Tooltip>
              </NavLink>
            ),
          },
        ]
      : []),
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 220,
      render: (text, record) => (
        <Tooltip
          title={record?.items?.map((item) => item?.product?.title).join(", ")}
        >
          <span>
            {record?.items?.map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                <NavLink
                  to={`https://www.amazon.com/dp/${item?.product?.asin}`}
                  target="_blank"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `https://www.amazon.com/dp/${item?.product?.asin}`,
                      "_blank"
                    );
                  }}
                >
                  <Image
                    style={{
                      borderRadius: "50%",
                      border: "3px solid rgba(51,62,72,0.5)",
                    }}
                    height={"40px"}
                    width={"40px"}
                    preview={false}
                    src={
                      process.env.REACT_APP_API_BASE_URL ===
                      "https://oneship-test-api.oneamz.com"
                        ? AmazonIcon
                        : `https://api.oneamz.com/product-photo/${
                            item?.product?.asin
                          }?Authorization=${localStorage.getItem(
                            "externalAccessToken"
                          )}`
                    }
                  />
                </NavLink>
                <CopyClipboard text={item?.product?.asin} />
              </div>
            ))}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "items",
      key: "quantity",
      align: "center",
      width: 90,
      render: (text, record) => {
        const totalQty = Array.isArray(record?.items)
          ? (
              record.items as (IFbaOrderItemResponse | IFbmOrderItemResponse)[]
            ).reduce((a: number, b) => {
              return a + (b?.quantity || 0);
            }, 0)
          : 0;
        return (
          <NavLink to={record?.detailUrl} style={{ color: "black" }}>
            <span>{totalQty}</span>
          </NavLink>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <Tag
            color={statusColors[record?.status] || "inherit"}
            style={{
              fontWeight: "500",
              whiteSpace: "wrap",
              textAlign: "center",
            }}
          >
            {statusLabels[record?.status] || record?.status}
          </Tag>
        </NavLink>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (text, record) => {
        const items: MenuProps["items"] = [
          {
            key: "view",
            label: <NavLink to={record?.detailUrl}>View Details</NavLink>,
          },
          {
            key: "cancel",
            label: (
              <Popconfirm
                title="Are you sure you want to cancel this order?"
                onConfirm={() => handleCancel(record.id)}
                okText="Yes"
                cancelText="No"
              >
                Cancel
              </Popconfirm>
            ),
          },
        ];
        return (record.status === "WAITING" && currentRole === "CUSTOMER") ||
          (record.status !== "CANCELLED" && currentRole !== "CUSTOMER") ? (
          <Dropdown placement="topRight" menu={{ items }}>
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </Dropdown>
        ) : (
          <Button
            type="link"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => {
              navigate(`${record?.detailUrl}`);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const columnsStorage: ColumnProps<IIncomingInventoryResponse>[] = [
    {
      title: "Storage Code",
      dataIndex: "orderCode",
      key: "orderCode",
      width: 200,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{text}</span>
        </NavLink>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 110,
      align: "center",
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <span>{formatDateTime(text)}</span>
        </NavLink>
      ),
    },
    ...(currentRole !== "CUSTOMER"
      ? [
          {
            title: "Customer",
            dataIndex: "user",
            key: "user",
            ellipsis: { showTitle: false },
            width: 150,
            sorter: true,
            render: (text: any, record: any) => (
              <NavLink to={record?.detailUrl} style={{ color: "black" }}>
                <Tooltip
                  placement="topLeft"
                  title={`${record?.user?.firstName} ${record?.user?.lastName} #${record?.user?.id}`}
                >
                  <p style={{ margin: 0 }}>
                    {record?.user?.firstName} {record?.user?.lastName}
                  </p>
                  <b>#{record?.user?.id}</b>
                </Tooltip>
              </NavLink>
            ),
          },
        ]
      : []),
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 220,
      render: (text, record: IIncomingInventoryResponse) => (
        <Tooltip title={record?.product?.title}>
          <span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <NavLink
                to={`https://www.amazon.com/dp/${record?.product?.asin}`}
                target="_blank"
                style={{ marginRight: "10px" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `https://www.amazon.com/dp/${record?.product?.asin}`,
                    "_blank"
                  );
                }}
              >
                <Image
                  style={{
                    borderRadius: "50%",
                    border: "3px solid rgba(51,62,72,0.5)",
                  }}
                  height={"40px"}
                  width={"40px"}
                  preview={false}
                  src={
                    process.env.REACT_APP_API_BASE_URL ===
                    "https://oneship-test-api.oneamz.com"
                      ? AmazonIcon
                      : `https://api.oneamz.com/product-photo/${
                          record?.product?.asin
                        }?Authorization=${localStorage.getItem(
                          "externalAccessToken"
                        )}`
                  }
                />
              </NavLink>
              <CopyClipboard text={record?.product?.asin} />
            </div>
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: 90,
      render: (text, record) => {
        return (
          <NavLink to={record?.detailUrl} style={{ color: "black" }}>
            <span>{text}</span>
          </NavLink>
        );
      },
    },
    {
      title: "Received Quantity",
      dataIndex: "receivedQuantity",
      key: "receivedQuantity",
      align: "center",
      width: 90,
      render: (text, record) => {
        const receivedQuantity =
          record?.receivedQuantity + record?.damagedQuantity;
        return (
          <NavLink to={record?.detailUrl} style={{ color: "black" }}>
            <span>{receivedQuantity}</span>
          </NavLink>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      sorter: true,
      render: (text, record) => (
        <NavLink to={record?.detailUrl} style={{ color: "black" }}>
          <Tag
            color={statusColors[record?.status] || "inherit"}
            style={{
              fontWeight: "500",
              whiteSpace: "wrap",
              textAlign: "center",
            }}
          >
            {statusLabels[record?.status] || record?.status}
          </Tag>
        </NavLink>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 100,
      render: (text, record) => {
        const items: MenuProps["items"] = [
          {
            key: "view",
            label: <NavLink to={record?.detailUrl}>View Details</NavLink>,
          },
          {
            key: "cancel",
            label: (
              <Popconfirm
                title="Are you sure you want to cancel this order?"
                onConfirm={() => handleCancel(record.id)}
                okText="Yes"
                cancelText="No"
              >
                Cancel
              </Popconfirm>
            ),
          },
        ];
        return (record.status === "WAITING" &&
          currentRole === "CUSTOMER" &&
          record.receivedQuantity === 0 &&
          record.damagedQuantity === 0) ||
          (record.status !== "CANCELLED" && currentRole !== "CUSTOMER") ? (
          <Dropdown placement="topRight" menu={{ items }}>
            <EllipsisOutlined
              rotate={90}
              style={{ fontSize: "24px", cursor: "pointer" }}
            />
          </Dropdown>
        ) : (
          <Button
            type="link"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => {
              navigate(`${record?.detailUrl}`);
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const getColumns = (): ColumnProps<any>[] => {
    if (activeKey === "1" || activeKey === "2") {
      return columnsFbaFbm as ColumnProps<any>[];
    } else if (activeKey === "3") {
      return columnsStorage as ColumnProps<any>[];
    }
    return [];
  };

  const getDataSource = () => {
    if (activeKey === "1") {
      return fbaOrderList;
    } else if (activeKey === "2") {
      return fbmOrderList;
    } else if (activeKey === "3") {
      return storageOrderList;
    }
    return [];
  };

  const renderCard = () => (
    <Card
      className="antd-card-body-p-0 bg-transparent w-full"
      loading={loading}
    >
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={getColumns()}
        dataSource={getDataSource()}
        bordered
        scroll={{ x: 1000 }}
        pagination={false}
        onChange={handleTableChange}
      />
      <Row style={{ marginTop: "30px" }} justify={"center"}>
        <Pagination
          showSizeChanger={true}
          total={totalOrders}
          defaultPageSize={pageSize}
          defaultCurrent={0}
          pageSize={pageSize}
          current={currentPage + 1}
          showQuickJumper
          onChange={paginationOnChange}
          // locale={{ items_per_page: "/ page", page: "Page" }}
        />
      </Row>
    </Card>
  );

  return (
    <>
      <Card className="bg-cloud border-radius-md">
        <Row justify={"space-between"}>
          <Col xs={24} md={12}>
            <h3 className="h-3 m-0">Shipment List</h3>
          </Col>
          <Col xs={24} md={12}>
            <Tabs
              defaultActiveKey="1"
              className="w-full antd-tab-right antd-tab-text"
              items={items}
              onChange={onChange}
            />
          </Col>
        </Row>
        <Row>{renderCard()}</Row>
      </Card>
    </>
  );
};

export default ShipmentList;
