import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import Education from "../../components/Education/Education";

const EducationPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard />
        </Col>
      </Row>
      <Row gutter={[24, 48]} className="mt-24 education-container">
        <Education />
      </Row>
    </>
  );
};

export default EducationPage;
