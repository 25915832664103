import http from "../http-common";
import {
  IBoxesRequest,
  IBoxResponse,
  IPaginatedBoxesResponse,
  IBoxCreateRequest,
  IBoxUpdateRequest,
} from "../types/Box";

const getBoxes = (filterCriteria: IBoxesRequest) => {
  return http.get<IPaginatedBoxesResponse>(`/api/v2/boxes${filterCriteria}`);
};

const getBoxesById = (id: number) => {
  return http.get<IBoxResponse>(`/api/v2/boxes/${id}`);
};

const createBox = (requestBody: IBoxCreateRequest) => {
  return http.post<IBoxResponse>(`/api/v2/boxes`, requestBody);
};

const updateBox = (id: number, requestBody: IBoxUpdateRequest) => {
  return http.put<IBoxResponse>(`/api/v2/boxes/${id}`, requestBody);
};

const deleteBox = (id: number) => {
  return http.delete<void>(`/api/v2/boxes/${id}`);
};

const BoxService = {
  getBoxes,
  getBoxesById,
  createBox,
  updateBox,
  deleteBox,
};

export default BoxService;
