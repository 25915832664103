import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import WalletCard from "../../components/Wallet/WalletCard/WalletCard";
import Graph from "../../components/Common/Graph/Graph";
import TransactionsList from "../../components/Wallet/TransactionsList";

const WalletPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard />
        </Col>
      </Row>
      <Row className="mt-24" gutter={[24, 24]}>
        <Col md={16} sm={24}>
          <WalletCard />
        </Col>
        <Col md={8} sm={24}>
          <Graph />
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <TransactionsList />
        </Col>
      </Row>
    </>
  );
};

export default WalletPage;
