import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import ProfileCard from "../../components/Profile/ProfileCard";

const ProfilePage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard/>
        </Col>
      </Row>
      <Row className="mt-24">
        <Col span={24}>
          <ProfileCard/>
        </Col>
      </Row>
    </>
  );
};

export default ProfilePage;
