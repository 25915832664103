import { Col } from "antd";

function Education() {
  return (
    <>
      <Col md={12} sm={24}>
        <h3>1. OneShip Introduction</h3>
        {/* <iframe
            src="https://embed.voomly.com/embed/assets/embed.html?videoId=kRFbHJoKTwiPwiIIzpFOpceRIQjbFlSjntwg8s_OTQIfTRiG6&amp;videoRatio=1.548387&amp;type=v&amp;skinColor=%23008EFF"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            height="100%"
            width="100%"
            allowFullScreen
          /> */}
      </Col>
      <Col md={12} sm={24}>
        <h3>2. FBM - Withdraw Product to Warehouse</h3>
        {/* <iframe
            src="https://embed.voomly.com/embed/assets/embed.html?videoId=HFbZYtpNXd3MAM_ch4gws1vj0RmH4Dccyo4BCEAjXMayfAw1N&amp;videoRatio=1.548387&amp;type=v&amp;skinColor=%23008EFF"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            height="100%"
            width="100%"
            allowFullScreen
          /> */}
      </Col>
      <Col md={12} sm={24}>
        <h3>3. Create FBA Shipment</h3>
        {/* <iframe
            src="https://embed.voomly.com/embed/assets/embed.html?videoId=HEOIJvKMlU6Om4kIxUUnIWEzJpAbHySFUEwq6XAjCJEicy6SZ&amp;videoRatio=1.548387&amp;type=v&amp;skinColor=%23008EFF"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            height="100%"
            width="100%"
            allowFullScreen
          /> */}
      </Col>
    </>
  );
}

export default Education;
