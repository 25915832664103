import { Avatar, Button, Card, Col, Divider, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { IUserRequest, IUserResponse } from "../../types/User";
import { openNotification } from "../../utils";
import AuthService from "../../services/AuthService";

const ProfileCard = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState<IUserResponse>();
  const [firstLetter, setFirstLetter] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    const { email, id, ...rest } = values;
    const postObj: IUserRequest = rest;
    updateMe(postObj);
  };

  useEffect(() => {
    getMe();
    // eslint-disable-next-line
  }, []);

  const updateMe = (postObj: IUserRequest) => {
    setLoading(true);
    AuthService.updateMe(postObj)
      .then((response: any) => {
        setLoading(false);
        openNotification("success", "Success", "Profile information updated");
        getMe();
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const getMe = () => {
    setLoading(true);
    AuthService.getMe()
      .then((response: any) => {
        let data: IUserResponse = response.data;
        form.setFieldsValue({
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          id: data?.id,
        });
        setFirstLetter(data.firstName.charAt(0));
        setUser(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Card loading={loading} className="bg-cloud border-radius-md">
        <Row gutter={24} align={"middle"}>
          <Col>
            <Avatar size={88}>{firstLetter}</Avatar>
          </Col>
          <Col>
            <p className="h-3 font-color-slate-blue m-0">
              {user?.firstName} {user?.lastName}
            </p>
            <p className="text font-color-slate-blue-02 m-0">{user?.email}</p>
          </Col>
        </Row>
        <Row className="mt-24">
          <Col span={24}>
            <p className="text m-0">
              <b>Profile Details</b>
            </p>
          </Col>
          <Col span={24}>
            <p className="text">
              The information you provide here will be used for the management
              of your user account and to improve our support processes. Your
              security and privacy is our top priority.
            </p>
          </Col>
        </Row>
        <Divider className="bg-slate-blue-02"/>
        <Form form={form} className="mb-64" onFinish={onFinish}>
          <Row gutter={24}>
            <Col>
              <p className="table-title mt-0 mb-4">NAME</p>
              <Form.Item
                name="firstName"
                rules={[
                  { required: true, message: "Please enter your name!" },
                  {
                    pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                    message: "The name must not contain special characters!",
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                  size="large"
                  style={{ padding: "0.7em" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <p className="table-title mt-0 mb-4">SURNAME</p>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: "Please enter your surname!" },
                  {
                    pattern: /^[a-zA-ZğüşöçİĞÜŞÖÇ\s]+$/,
                    message: "Last name must not contain special characters!",
                  },
                ]}
              >
                <Input
                  placeholder="Surname"
                  size="large"
                  style={{ padding: "0.7em" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="table-title mt-0 mb-4">ID</p>
              <Form.Item
                name="id"
                rules={[
                  {
                    required: true,
                    message: "Please enter your surname!",
                  },
                ]}
              >
                <Input
                  disabled
                  placeholder="ID"
                  size="large"
                  style={{ padding: "0.7em" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Card loading={loading} className="bg-pure-white border-radius-md">
              <p className="text font-color-slate-blue">
                <b>Contact Information</b>
              </p>
              <p className="text-small">
                Your contact information in this section will be used to manage
                important updates, notifications and support requests related to
                your account. Providing accurate and up-to-date contact
                information allows us to communicate with you quickly and
                effectively so that we can best assist you in the event of any
                problems or needs.
              </p>
              <Row>
                <Col>
                  <p className="table-title mt-0 mb-4">EMAIL</p>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      readOnly
                      placeholder="Email"
                      size="large"
                      style={{ padding: "0.7em" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Col>
                    <p className="table-title mt-0 mb-4">PHONE</p>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Phone"
                        size="large"
                        style={{ padding: "0.7em" }}
                      />
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
              <Divider className="bg-slate-blue-02"/>
            </Card>
          </Row>
          <Row className="mt-24" justify={"end"}>
            <Col>
              <Button
                htmlType="submit"
                className="btn btn-hover-white btn-text bg-slate-blue border-radius-lg font-color-pure-white"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default ProfileCard;
