import http from "../http-common";

const authToken = (data: any) => {
  return http.post<any>("/api/v1/auth/token", data);
};

const verificationCallback = (data: any) => {
  return http.post("/api/v1/auth/email-verification", data);
};

const login = (data: any) => {
  return http.post("/api/v1/auth/login", data);
};

const register = (data: any) => {
  return http.post("/api/v1/auth/register", data);
};

const forgotPassword = (data: any) => {
  return http.post("/api/v1/auth/reset-password-requests", data);
};

const verifyAgain = (data: any) => {
  return http.post("/api/v1/auth/verification-send", data);
};

const resetPassword = (data: any) => {
  return http.post("/api/v1/auth/reset-passwords", data);
};

const getMe = () => {
  return http.get<any>(`/api/v1/auth/me`);
};

const updateMe = (data: any) => {
  return http.patch<any>(`/api/v1/auth/me`, data);
};

const AuthService = {
  verificationCallback,
  login,
  register,
  forgotPassword,
  resetPassword,
  verifyAgain,
  authToken,
  getMe,
  updateMe,
};

export default AuthService;
