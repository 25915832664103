import { Col, Row } from "antd";
import StarterCard from "../../../components/Common/StarterCard/StarterCard";
import FbaShipmentList from "../../../components/Outbound/FBA/FbaShipmentList";

const FBAPage = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <StarterCard />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FbaShipmentList />
        </Col>
      </Row>
    </>
  );
};

export default FBAPage;
